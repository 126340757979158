// File: src/pages/GameActivation.tsx

import React, { useRef, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography, Stack } from '@mui/material';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyIcon from '@mui/icons-material/Key';
import ProductKeys from './Components/ProductKeys';
import { useTranslation } from 'react-i18next';

import { clearCart } from 'redux/reducers/cartReducer';
import { clearPaymentData } from 'redux/payment/paymentReducer';
import { verifyGameActivation } from 'redux/payment/paymentThunks';
import { useFetchInvoiceDetailQuery } from 'redux/RTK/userApi';

import LoadingAnimation from 'components/Animations/LoadingAnimation';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator';
/**
 * Page to handle game activation after user returns from payment gateway.
 */
const GameActivation: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

    // Parse query params
    const queryParams = new URLSearchParams(location.search);
    const paymentIntent = queryParams.get('payment_intent');
    const paymentIntentClientSecret = queryParams.get('payment_intent_client_secret');

    // Step 1 states: verifying the payment
    const [verifyLoading, setVerifyLoading] = useState(true);
    const [finalPaymentId, setFinalPaymentId] = useState<string | null>(null);
    const [retryCount, setRetryCount] = useState(0);

    // Product keys ref for smooth scrolling
    const productKeysRef = useRef<HTMLDivElement>(null);

    // Step 2: fetch invoice detail if we have finalPaymentId
    const {
        data: invoice,
        isLoading: invoiceLoading,
      } = useFetchInvoiceDetailQuery(
        { paymentId: finalPaymentId ?? '' },
        { skip: !finalPaymentId }
      );
    

    // On mount: verify game activation
    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined;

        // Clear local cart/payment data
        dispatch(clearCart());
        dispatch(clearPaymentData());

        // verify function
        const verifyPayment = async () => {
            if (!paymentIntent || !paymentIntentClientSecret) {
                setVerifyLoading(false);
                return;
            }

            try {
                    const response = await dispatch<any>(verifyGameActivation({
                        paymentIntent,
                        clientSecret: paymentIntentClientSecret,
                    }));

                if (response.meta.requestStatus === 'rejected') {
                    // Possibly the error message is 'Payment is still processing.'
                    if (response.payload === 'Payment is still processing.') {
                        if (retryCount < 5) {
                            setRetryCount((prev) => prev + 1);
                        } else {
                            setVerifyLoading(false);
                        }
                    } else {
                        // Some other error
                        setVerifyLoading(false);
                    }
                } else {
                    // Success => server returned a paymentId
                    const newPaymentId = response.payload; // e.g. "pi_3Qt..."
                    setFinalPaymentId(newPaymentId);
                    setVerifyLoading(false);
                }
            } catch (error) {
                // Some unexpected error
                setVerifyLoading(false);
            }
        };

        verifyPayment();

        // If the payment is stuck in "processing", we do limited retries
        if (retryCount > 0 && retryCount < 5) {
            intervalId = setInterval(() => {
                verifyPayment();
            }, 6000); // Retry every 6 seconds
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [
        dispatch,
        paymentIntent,
        paymentIntentClientSecret,
        retryCount
    ]);

    // Smooth scroll to product keys
    const handleViewKeysClick = () => {
        if (productKeysRef.current) {
            const offsetTop = productKeysRef.current.getBoundingClientRect().top + window.scrollY - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    // Step 1: If verifying payment is loading/failing
    if (verifyLoading) {
        return <LoadingAnimation />;
    }

    // Step 2: If invoice is loading or error
    if (finalPaymentId && (invoiceLoading || !invoice)) {
        // invoiceLoading => RTK Query is busy
        // or invoice not present yet
        return <LoadingAnimation />;
    }

    // By now, we have a valid invoice from RTK Query
    // Sort items if you want
    const sortedItems = invoice?.items.slice().sort((a: any, b: any) => {
        // If a.isBundle is true => push it down
        if (a.isBundle && !b.isBundle) return 1;
        if (!a.isBundle && b.isBundle) return -1;
        return 0;
    });

    return (
        <Box sx={{ display: 'flex', paddingLeft: '0 !important', paddingRight: '0 !important', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img src="/img/payment_success.webp" height={250} alt="Payment Success" />
            <Typography variant="h2" sx={{ mb: 3 }}>{t('thankYouForPurchase')}</Typography>
            <Typography variant="h6">{t('findYourKeysBellow')}</Typography>

            <Stack direction="row" spacing={4} sx={{ my: 5 }}>
                <Button sx={{ mt: 5 }} startIcon={<ArrowBackRoundedIcon />} variant="contained" color="secondary" component={RouterLink} to="/">
                    {t('goToStore')}
                </Button>
                {invoice && (
                    <InvoicePdfGenerator invoice={invoice} title={t('downloadInvoice')} />
                )}
                <Button sx={{ mt: 5 }}
                    startIcon={<KeyIcon />} variant="contained" color="primary" onClick={handleViewKeysClick}>
                    {t('viewKeys')}
                </Button>
            </Stack>

            <Typography ref={productKeysRef} variant="h2" sx={{ my: 5 }}>
                {t('productKey')}
            </Typography>



            {invoice && sortedItems?.map((item: any, index: number) => (
                item.keys && item.keys.length > 0 ? (
                    item.keys.map((keyObj: any, keyIndex: number) => (
                        <ProductKeys
                            key={`${index}-${keyIndex}`}
                            invoiceId={invoice._id}
                            productId={item.productId}
                            isBundle={item.isBundle}
                            platformGroupId={item.platformGroupId}
                            keyCode={keyObj.key}
                            keyIsRevealed={keyObj.isRevealed}
                            isGifted={keyObj.isGifted}
                            productKeysRef={productKeysRef}
                        />
                    ))
                ) : (
                    Array.from({ length: item.quantity }).map((_, i) => (
                        <ProductKeys
                            key={`${index}-${i}`}
                            invoiceId={invoice._id}
                            productId={item.productId}
                            isBundle={item.isBundle}
                            platformGroupId={item.platformGroupId}
                            keyCode={undefined}
                            productKeysRef={productKeysRef}
                        />
                    ))
                )
            ))}

        </Box>
    );
};

export default GameActivation;