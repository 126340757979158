export const itResources = {
    translation: {
        languageChanged: "Lingua cambiata",
        selectedLanguage: "L'italiano è ora selezionato",
        dark: "Scuro",
        light: "Chiaro",
        systemPreferences: "Preferenze di sistema",
        changeApplicationTheme: "Seleziona il tema dell'applicazione",
        applicationTheme: "Tema dell'applicazione",
        darkDescription: "Benvenuto nel lato oscuro, abbiamo i biscotti!",
        lightDescription: "Abbraccia la luce, nobile giocatore!",
        autoDescription: "Lascia che il tuo sistema decida il tuo destino!",
        welcome: "Benvenuto",
        welcomeToBigswiss: "Benvenuto in Bigswiss",
        completeProfileMessage: "Completa il tuo profilo",
        selectLanguage: "Seleziona la lingua predefinita",
        selectLanguageLabel: "Seleziona lingua",
        gdprFadpDescription: "Utilizziamo i cookie per garantire il corretto funzionamento del nostro sito Web e migliorare la tua esperienza. Selezionando 'Accetta solo i cookie necessari', acconsenti all'uso di cookie essenziali necessari per le funzionalità di base, inclusi i cookie di terze parti di Stripe per l'elaborazione sicura dei pagamenti. Selezionando 'Accetta tutti i cookie', ci consenti anche di utilizzare cookie opzionali, come quelli per l'analisi (Google Analytics) e la pubblicità, per migliorare ulteriormente i nostri servizi.",
        acceptTerms: "Accetto i termini e le condizioni.",
        acceptNecessary: "Solo Essenziali",
        acceptAll: "Accetta Tutto",
        termsWarning: "Si prega di accettare i termini e le condizioni per procedere.",

        selectCurrency: "Seleziona la valuta",
        newsletter: "Notiziario",
        subscribeMessage: "Iscriviti alla nostra newsletter per aggiornamenti settimanali e promozioni.",
        subscribe: "Iscriviti",
        products: "Prodotti",
        position: "Posizione",
        message: "Messaggio",
        invalidEmail: "E-mail non valida",
        allFieldsRequired: "Si prega di compilare tutti i campi obbligatori",
        messageSent: "Messaggio inviato",
        contacted: "Richiesta inviata",
        jobThankYou: "Grazie per il vostro interesse. Vi contatteremo presto.",
        duplicateApplication: "Hai già fatto domanda, ti preghiamo di pazientare fino a quando non ti contatteremo.",
        duplicateSubscription: "Sei già iscritto.",
        succesSubcribed: "Ti sei iscritto con successo.",
        company: "Azienda",
        getInTouch: "Mettersi in contatto",
        privacyPolicy: "Politica sulla riservatezza",
        termsOfService: "Termini di servizio",
        pcGames: "Giochi per PC",
        xboxGames: "Giochi XBOX",
        playstationGames: "Giochi PlayStation",
        nintendoGames: "Giochi Nintendo",
        giftCards: "Carte regalo",
        subscriptionCards: "Carte di abbonamento",
        aboutUs: "Chi siamo",
        testimonials: "Testimonianze",
        testimonialsDescription:
            "Scopri cosa amano i nostri clienti delle nostre funzionalità. Scopri come eccelliamo in efficienza e soddisfazione. Unisciti a noi per costi ridotti, innovazione nel retail e supporto affidabile.",

        highlights: "Punti salienti",
        pricing: "Prezzi",

        faqs: "FAQ",
        giftAndSubscriptionCards: "Carte regalo & di abbonamento",
        checkTopSellers: "Controlla i più venduti",
        platforms: "Piattaforme",
        category: "Categoria",
        platform: "Piattaforma",
        contactSupportText: "Se desideri cambiare la regione del tuo account, contatta il nostro supporto",
        region: "Regione",
        productType: "Tipo di prodotto",
        none: "Nessuno",
        sort: "Ordina",
        priceIncreasing: "Aumento del prezzo",
        priceDecreasing: "Diminuzione del prezzo",

        consoles: "Console",
        aboutUsDescription:
            "Dal 2002, Bigswiss.ch è la tua destinazione di riferimento per giochi digitali e carte regalo, operando orgogliosamente dal cuore della Svizzera. Con oltre due decenni di esperienza, ci impegniamo a offrirti il meglio del mondo dell'intrattenimento digitale.\n\nLa nostra vasta selezione include una grande varietà di giochi digitali su tutte le piattaforme popolari, oltre a una gamma di carte regalo per i tuoi servizi preferiti. Che tu sia un giocatore esperto in cerca della prossima grande uscita o alla ricerca del regalo perfetto per una persona cara, Bigswiss.ch ha tutto ciò di cui hai bisogno.\n\nSu Bigswiss.ch, siamo orgogliosi di offrire un servizio clienti eccezionale e un'esperienza di acquisto senza soluzione di continuità. Il nostro team dedicato è sempre a tua disposizione per assisterti, assicurandoti che il tuo viaggio con noi sia piacevole e senza intoppi.",
        onlineStoreTitle: "Negozio online",
        onlineStoreDescription:
            "Acquista oltre 10.000 videogiochi e prodotti digitali diversi",
        posTerminalsTitle: "Terminali POS",
        posTerminalsDescription:
            "Integra i nostri terminali POS nel tuo negozio e accedi a tutti i nostri giochi",
        wholesaleApiTitle: "API all'ingrosso",
        wholesaleApiDescription:
            "Integra la nostra API all'ingrosso nel tuo negozio online",
        learnMore: "Scopri di più",
        goToStore: "Vai al negozio",
        highlightsDescription:
            "Scopri perché il nostro prodotto si distingue: adattabilità, durata, design intuitivo e innovazione. Goditi un supporto clienti affidabile e una precisione in ogni dettaglio.",
        instantDeliveryTitle: "Consegna Istantanea",
        instantDeliveryDescription:
            "Ricevi i tuoi codici digitali immediatamente dopo l'acquisto.",
        digitalDownloadTitle: "Download Digitale",
        digitalDownloadDescription:
            "Accedi ai tuoi giochi e servizi preferiti con codici facili da riscattare.",
        customerSupportTitle: "Supporto Clienti 24/7",
        customerSupportDescription:
            "Siamo qui per te in qualsiasi momento, pronti a risolvere qualsiasi problema.",
        adaptablePerformanceTitle: "Prestazioni Adattabili",
        adaptablePerformanceDescription:
            "Il nostro prodotto si adatta facilmente alle tue esigenze, aumentando l'efficienza e semplificando i tuoi compiti.",
        innovativeFunctionalityTitle: "Funzionalità Innovativa",
        innovativeFunctionalityDescription:
            "Rimani all'avanguardia con funzionalità che stabiliscono nuovi standard, rispondendo meglio alle tue esigenze in evoluzione.",
        precisionDetailTitle: "Precisione in ogni dettaglio",
        precisionDetailDescription:
            "Goditi un prodotto meticolosamente realizzato in cui i piccoli dettagli hanno un impatto significativo sulla tua esperienza complessiva.",
        ourTopSellingProducts: "I nostri prodotti più venduti",

        faqTitle: "Domande frequenti",
        faq1Question:
            "Come posso contattare l'assistenza clienti se ho una domanda o un problema?",
        faq1Answer:
            "Siamo qui per assisterti prontamente. Puoi contattare il nostro team di assistenza clienti inviando un'email a ",
        faq2Question:
            "Posso restituire il prodotto se non soddisfa le mie aspettative?",
        faq2Answer:
            "Assolutamente! Offriamo una politica di restituzione senza problemi. Se non sei completamente soddisfatto, puoi restituire il prodotto entro 7 giorni per un rimborso completo o uno scambio.",
        faq3Question:
            "Cosa rende il vostro prodotto diverso dagli altri sul mercato?",
        faq3Answer:
            "Il nostro prodotto si distingue per la sua adattabilità, durata e caratteristiche innovative. Prioritizziamo la soddisfazione dell'utente e ci sforziamo continuamente di superare le aspettative in ogni aspetto.",
        faq4Question: "C'è una garanzia sul prodotto e cosa copre?",
        faq4Answer:
            "Sì, il nostro prodotto viene fornito con una garanzia di 1 anno. Copre i difetti di materiali e di lavorazione. Se riscontri problemi coperti dalla garanzia, contatta il nostro servizio clienti per assistenza.",

        available: "Disponibile",
        now: "Ora",
        games: "Giochi",
        about: "Informazioni su",
        game: "Gioco",
        website: "Sito web",
        description: "Descrizione",
        rating: "Valutazione",
        developer: "Sviluppatore",
        publisher: "Editore",
        released: "Data di rilascio",
        genre: "Genere",
        gallery: "Galleria",
        maxPrice: "Prezzo massimo",

        availableEditions: "Edizioni Disponibili",
        availablePlatforms: "Piattaforme Disponibili",
        addToCart: "Aggiungi al carrello",
        outOfStock: "Esaurito",
        emptyCartHeading: "Il carrello è vuoto...",
        emptyCartSubheading:
            "Il tuo carrello desidera compagnia. Inizia ora la tua avventura di shopping!",
        exploreCatalog: "Esplora il nostro catalogo",
        yourCart: "Il tuo Carrello",
        quantity: "Quantità",
        maxProductAllowed: "Massimo di {{max}} prodotti consentiti",
        total: "Totale",
        goToCart: "Vai al Carrello",
        payNow: "Paga ora",
        goToPayment: "Vai al Pagamento",
        discount: "Sconto",
        originalPrice: "Prezzo originale",
        checkMoreProducts: "Vedi più prodotti",
        cartSummary: "Riepilogo del carrello",
        cartItems: "Articoli nel carrello",
        payment: "Pagamento",
        gameActivation: "Attivazione del gioco",
        login: "Accesso",
        remove: "Rimuovere",

        Notification: "Notifica",
        notificationsEnabled: "Le notifiche del browser sono abilitate",
        notificationsEnableFail: "Impossibile abilitare le notifiche",
        notificationsDisabled: "Le notifiche del browser sono disabilitate",
        notificationsDisableFail: "Impossibile disabilitare le notifiche",
        notificationsDenied: "Permesso di notifica negato",
        notificationsNoPermission: "Permesso di notifica non concesso",
        notificationsPermissionFaild: "Richiesta di permesso di notifica non riuscita",
        notificationsNotSupported: "La messaggistica push non è supportata",
        notificationsFailToSubscribe: "Impossibile iscrivere l'utente",
        notificationsFailToUnsubscribe: "Impossibile annullare l'iscrizione dell'utente",
        serviceWorkerNotReady: "Si prega di ricaricare la pagina e riprovare",
        notificationsSubscriptionErro: "Errore durante l'ottenimento dell'abbonamento",
        browserNotifications: "Notifiche del browser",

        noProductsFound: "Nessun prodotto trovato.",
        tryDifferentQuery:
            "Si prega di provare una ricerca diversa o reimpostare i filtri.",
        resetFilters: "Reimposta filtri",
        achievements: "Successi",
        noFavorites: "Ops! Nessun bottino trovato!",
        noFavoritesText: "È ora di cacciare qualche bottino epico!",
        favorites: "Preferiti",
        purchases: "Acquisti",
        points: "Punti",
        dashboard: "Pannello di controllo",
        settings: "Impostazioni",
        updateSettings: "Aggiorna impostazioni",
        security: "Sicurezza",
        invoiceNotFound: "Fattura non trovata",
        action: "Azione",
        date: "Data",
        rowsPerPage: "Righe per pagina",
        aboutOurApi: "Informazioni sulla nostra API",
        aboutOurApiDescription:
            "Benvenuto nella nostra pagina del prodotto API! La nostra API offre un accesso senza soluzione di continuità a oltre 10.000 prodotti, garantendo che tu possa integrare e offrire una vasta gamma di articoli ai tuoi clienti in ogni momento. Che tu stia costruendo una piattaforma di e-commerce, un'app mobile o qualsiasi altro servizio, la nostra API ti copre.",
        whyChooseOurApi: "Perché scegliere la nostra API?",
        whyChooseOurApiDescription:
            "La nostra API è progettata per essere robusta, affidabile e facile da usare. Ecco alcune delle caratteristiche chiave:",
        featureApi1: "Accesso a oltre 10.000 prodotti",
        featureApi2: "Disponibilità e prezzi dei prodotti in tempo reale",
        featureApi3: "Alta disponibilità con il 99,9% di uptime",
        featureApi4: "Documentazione completa e supporto",
        featureApi5: "Facile integrazione con endpoint RESTful",
        featureApi6: "Architettura sicura e scalabile",
        WithOurApi:
            "Con la nostra API, puoi garantire ai tuoi clienti l'accesso a un vasto inventario di prodotti in ogni momento. La nostra alta disponibilità e gli aggiornamenti in tempo reale significano che non perderai mai una vendita.",

        aboutOurPosTerminals: "Informazioni sui nostri terminali POS",
        welcomeMessage:
            "Benvenuti nella nostra pagina prodotti! Siamo orgogliosi di offrire terminali POS all'avanguardia progettati specificamente per negozi che vendono giochi e carte di abbonamento. I nostri terminali sono facili da usare, sicuri ed efficienti, aiutandoti a gestire le tue vendite e il tuo inventario con facilità",
        whyChooseOurPosTerminals: "Perché scegliere i nostri terminali POS?",
        posTerminalFeatures:
            "I nostri terminali POS sono dotati di una serie di funzionalità per semplificare le operazioni del tuo negozio:",
        feature1:
            "Integrazione senza soluzione di continuità con i tuoi sistemi esistenti",
        feature2: "Elaborazione dei pagamenti rapida e sicura",
        feature3: "Gestione dell'inventario in tempo reale",
        feature4: "Interfaccia user-friendly per una formazione rapida",
        feature5:
            "Impostazioni personalizzabili per adattarsi alle esigenze del tuo negozio",
        posTerminalDescription:
            "Che tu stia vendendo giochi fisici o carte di abbonamento digitali, i nostri terminali POS sono equipaggiati per gestire tutte le tue transazioni in modo efficiente. Migliora l'esperienza dei tuoi clienti e aumenta le tue vendite con le nostre soluzioni POS affidabili e versatili",
        invoiceNo: "Numero fattura",
        howTo: "Come",
        activate: "Attivare",
        step: "Passo",
        giftToFriend: "Regala a un amico",
        enterEmailTwice: "Inserisci due volte l'indirizzo e-mail del tuo amico per conferma.",
        friendEmail: "E-mail dell'amico",
        confirmFriendsEmail: "Conferma l'e-mail dell'amico",
        emailsDoNotMatch: "Gli indirizzi e-mail non corrispondono. Riprova.",
        sendGift: "Invia regalo",
        giftSent: "Regalo inviato",
        giftSentMessage: "Abbiamo inviato con successo la chiave come regalo al tuo amico all'indirizzo email seguente:",
        close: "Chiudi",

        buyers: "Acquirenti",
        reviews: "Recensioni",
        user: "Utente",
        comment: "Commento",

        requirements: "Requisiti",
        minimumRequirements: "Requisiti minimi",
        recommendedRequirements: "Requisiti consigliati",
        OS: "Sistema operativo",
        Processor: "Processore",
        Memory: "Memoria",
        Graphics: "Grafica",
        DirectX: "DirectX",
        Storage: "Memorizzazione",
        AdditionalNotes: "Note aggiuntive",

        careers: "Carriere",
        contactUs: "Contattaci",
        updatePersonalData: "Aggiorna i dati di fatturazione",
        updateApplicationSettings: "Aggiorna impostazioni dell'applicazione",
        birthday: "Data di nascita",
        yourName: "Il tuo nome",
        yourLastName: "Il tuo cognome",
        enterEmail: "Inserisci il tuo indirizzo email",
        yourEmail: "Il tuo indirizzo email",
        changePassword: "Cambia password",
        currentPassword: "Password attuale",
        newPassword: "Nuova password",
        confirmPassword: "Conferma password",
        showLess: "Mostra meno",
        showMore: "Mostra di più",
        PageNotFound: "Pagina non trovata",
        DontGiveUp:
            "Non arrenderti, forse troverai qui quello che stai cercando.",
        Jobs: "Lavori",
        ElevateYourCareer:
            "Elevate la tua carriera con Bigswiss GmbH, dove innovazione e passione si incontrano. Immergiti in un mondo in cui il tuo lavoro modella direttamente il futuro del gioco e della tecnologia.",
        exploreOpportunities:
            "Esplora opportunità, affronta sfide e fai parte di un team dinamico dedicato all'eccellenza. Il tuo viaggio per fare la differenza inizia qui.",
        enterYourEmailAddress: "Inserisci il tuo indirizzo email",
        yourEmailAddress: "Il tuo indirizzo email",
        contactUsAgree: 'Cliccando su "Contattaci" accetti i nostri',
        termsAndConditions: "Termini e condizioni",
        bigswissSupport: "Contatta Bigswiss ",
        support: "Supporto",
        contactDescription:
            "Dalle richieste sul nostro negozio online innovativo e il negozio all'ingrosso all'assistenza per le nostre applicazioni per chioschi per gli acquisti di videogiochi e il negozio API all'ingrosso, siamo qui per migliorare la tua esperienza.",
        connectTeam:
            "Connettiti con il nostro team dedicato per funzionalità e servizi di alto livello progettati appositamente per te.",

        pricingDescription: "Scegli il piano che soddisfa le tue esigenze",
        basic: "Base",
        pro: "Pro",
        enterprise: "Impresa",
        getStarted: "Inizia",
        startNow: "Inizia ora",
        mostPopular: "Più popolare",
        perMonth: "al mese",
        basicDesc1: "Accesso API per fino a 100 richieste al mese",
        basicDesc2: "Analisi e report di base",
        basicDesc3: "Supporto standard",
        proDesc1: "Accesso API per fino a 10.000 richieste al mese",
        proDesc2: "Analisi e report avanzati",
        proDesc3: "Supporto prioritario",
        proDesc4:
            "Accesso a offerte esclusive di giochi e carte di abbonamento",
        enterpriseDesc1: "Richieste API illimitate",
        enterpriseDesc2: "Analisi e report personalizzati",
        enterpriseDesc3: "Supporto dedicato 24/7",
        enterpriseDesc4: "Soluzioni terminali POS personalizzate",
        enterpriseDesc5: "Migliori prezzi per acquisti all'ingrosso",

        chooseActivation: "Scegli dove desideri attivare il tuo prodotto.",
        thankYouForPurchase: "Grazie per il tuo acquisto!",
        findYourKeysBellow: "Di seguito troverai le tue chiavi e la tua fattura.",
        productKey: "Chiave del prodotto",
        productKeys: "Chiavi del prodotto",
        productActivation: "Attivazione del prodotto",
        productActivationDescription: "Segui le istruzioni di seguito per attivare il tuo prodotto.",
        sendAsGift: "Regalo",
        hide: "Nascondi",
        viewKeys: "Visualizza le chiavi",
        purchase_review_pending: "Il tuo acquisto è in fase di revisione. Riceverai una e-mail una volta approvato.",
        // getting_key_please_wait: "Getting your key. Please wait...",
        getting_key_please_wait: "Stiamo recuperando la tua chiave. Attendere prego...",
        downloadInvoice: "Scarica la fattura",
        download: "Scarica",
        back: "Indietro",
        next: "Avanti",
        activationMethod: "Metodo di attivazione",
        keylessActivation: "Attivazione senza chiave",
        activationLink: "Link di attivazione",
        enterTheKey: "Inserisci la chiave",
        confirmActivation: "Conferma attivazione",
        battlenetStep1: "Fai clic sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Battle.net.",
        goToBattlenetActivation: "Vai alla pagina di attivazione di Battle.net",
        battlenetStep2: "Accedi con le tue credenziali Battle.net. Se non hai un account, creane uno gratuitamente.",
        battlenetStep3: "Inserisci la chiave ricevuta nel campo apposito, quindi fai clic su 'Riscatta'.",
        battlenetStep4: "Congratulazioni! Hai attivato il tuo prodotto con successo ed è pronto per essere scaricato e giocato sul tuo account Battle.net.",
        goToActivation: "Vai all'attivazione",
        battlenetStep2app: "Apri l'app Blizzard Battle.net. E accedi con le tue credenziali Battle.net. Se non hai un account, creane uno gratuitamente.",
        battlenetStep3app: "Fai clic sul tuo profilo per aprire un menu, quindi fai clic su 'Riscatta codice'.",

        goToSteamActivation: "Vai alla pagina di attivazione di Steam",
        steamStep1: "Fai clic sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Steam.",
        steamStep2: "Accedi con le tue credenziali Steam. Se non hai un account, creane uno gratuitamente.",
        steamStep3: "Leggi e accetta l'accordo di abbonamento Steam, quindi fai clic su 'Continua'.",
        steamStep4: "Congratulazioni! Hai attivato il tuo prodotto con successo ed è pronto per essere scaricato e giocato sul tuo account Steam.",
        steamStep2app: "Apri l'app Steam e accedi con le tue credenziali Steam. Se non hai un account, creane uno gratuitamente.",
        steamStep3app: "Fai clic su 'Giochi' nel menu in alto, quindi fai clic su 'Attiva un prodotto su Steam'.",
        steamStep4app: "Inserisci la chiave ricevuta nel campo apposito, quindi fai clic su 'Convalida'.",

        goToMicrosoftActivation: "Vai alla pagina di attivazione del Microsoft Store",
        microsoftStep1: "Fai clic sul pulsante in basso per accedere alla pagina di attivazione del codice Microsoft Store.",
        microsoftStep2: "Accedi con le tue credenziali Microsoft. Se non hai un account, creane uno gratuitamente.",
        microsoftStep3: "Inserisci il codice ricevuto nel campo fornito e fai clic su 'Avanti'.",
        microsoftStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Microsoft.",
        microsoftStep2app: "Sul tuo sistema Windows, apri l'app Microsoft Store e accedi con le tue credenziali Microsoft. Se non hai un account, creane uno gratuitamente.",
        microsoftStep3app: "Fai clic sull'icona del profilo in alto a destra, quindi fai clic su 'Riscatta codice o carte regalo'.",

        goToGOGActivation: "Vai alla pagina di attivazione GOG",
        activateKey: "Attiva la chiave",
        linkToAccount: "Collega all'account",
        gogStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave GOG.",
        gogStep2: "Clicca su 'Continua' per confermare l'attivazione della chiave.",
        gogStep3: "Clicca su 'Accedi' per collegare la chiave al tuo account GOG.",
        gogStep4: "Accedi con le tue credenziali GOG. Se non hai un account, creane uno gratuitamente.",
        gogStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account GOG.",

        gogStep2app: "Apri l'app GOG Galaxy e accedi con le tue credenziali GOG. Se non hai un account, creane uno gratuitamente.",
        gogStep3app: "Fai clic su 'Aggiungi giochi e amici' nel menu in alto, quindi fai clic su 'Riscatta codice GOG'.",
        gogStep4app: "Inserisci la chiave ricevuta nel campo fornito, quindi fai clic su 'Continua'.",

        goToEpicActivation: "Vai alla pagina di attivazione di Epic Games",
        epicStep1: "Fai clic sul pulsante qui sotto per andare alla pagina di attivazione della chiave Epic Games.",
        epicStep2: "Fai clic su 'Riscatta' per confermare l'attivazione della chiave.",
        epicStep3: "Fai clic su 'Accedi' per collegare la chiave al tuo account Epic Games.",
        epicStep4: "Accedi con le tue credenziali Epic Games. Se non hai un account, creane uno gratuitamente.",
        epicStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Epic Games.",
        epicStep2app: "Apri l'app Epic Games e accedi con le tue credenziali Epic Games. Se non hai un account, creane uno gratuitamente.",
        epicStep3app: "Fai clic sul tuo profilo nell'angolo in alto a destra, quindi fai clic su 'Riscatta codice'.",
        epicStep4app: "Inserisci la chiave ricevuta nel campo fornito, quindi fai clic su 'Riscatta'.",

        goToRockstarActivation: "Vai alla pagina di attivazione Rockstar",
        rockstarStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave Rockstar.",
        rockstarStep2: "Accedi con le tue credenziali Rockstar. Se non hai un account, creane uno gratuitamente.",
        rockstarStep3: "Attendere il caricamento della pagina e il completamento della validazione Rockstar. Questo potrebbe richiedere alcuni minuti. Una volta che la pagina è stata caricata, vedrai un messaggio di conferma. Clicca su 'Conferma' per attivare la chiave sul tuo account Rockstar.",
        rockstarStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account Rockstar.",
        rockstarStep2app: "Apri l'app Rockstar Games Launcher e accedi con le tue credenziali Rockstar. Se non hai un account, creane uno gratuitamente.",
        rockstarStep3app: "Clicca sul menu nell'angolo in alto a destra, poi su 'RISCUOTI CODICE'.",
        rockstarStep4app: "Inserisci la chiave ricevuta nel campo fornito, poi clicca su 'VERIFICA'.",
        rockstarStep5app: "Leggi e accetta i termini e le condizioni, poi clicca su 'RISCUOTI'.",

        goToEAPlayActivation: "Vai alla pagina di attivazione di EA Play",
        eaStep1: "Clicca sul pulsante qui sotto per accedere alla pagina di attivazione della chiave EA Play.",
        eaStep2: "Accedi con le tue credenziali EA Play. Se non hai un account, creane uno gratuitamente.",
        eaStep3: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'.",
        eaStep4: "Clicca su 'Avanti' per collegare la chiave al tuo account EA Play.",
        eaStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account EA Play.",

        eaStep2app: "Apri l'app EA Play e accedi con le tue credenziali EA Play. Se non hai un account, creane uno gratuitamente.",
        eaStep3app: "Clicca sul menu in alto a destra, quindi clicca su 'Riscatta Codice'.",
        eaStep4app: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'.",

        ubisoftStep2app: "Apri l'app Ubisoft e accedi con le tue credenziali Ubisoft. Se non hai un account, creane uno gratuitamente.",
        ubisoftStep3app: "Clicca sul menu del profilo in alto a sinistra, quindi clicca su 'Attivazione chiave'.",
        ubisoftStep4app: "Inserisci il codice ricevuto nel campo previsto, quindi clicca su 'Attiva'.",

        goToSwitchActivation: "Vai alla pagina di attivazione Nintendo Switch",
        openeshop: "Apri eShop",
        selectUser: "Seleziona Utente",
        redeem: "Riscatta Chiave",
        switchStep1: "Clicca sul pulsante qui sotto per accedere alla pagina di attivazione della chiave Nintendo Switch.",
        switchStep2: "Accedi con le tue credenziali Nintendo Switch. Se non hai un account, creane uno gratuitamente.",
        switchStep3: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Continua'.",
        switchStep4: "Congratulazioni! Hai attivato con successo il tuo prodotto, ed è pronto per essere scaricato e giocato sul tuo account Nintendo Switch.",
        switchStep1app: "Apri l'app Nintendo eShop.",
        switchStep2app: "Seleziona l'account su cui desideri attivare il prodotto.",
        switchStep3app: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Conferma'.",
        switchstep4app: "Seleziona 'Riscatta' e clicca su 'Conferma' per attivare il prodotto sul tuo account Nintendo Switch.",

        goToPlayStationActivation: "Vai alla pagina di attivazione di PlayStation",
        openLogin: "Apri il login",
        psStep1: "Clicca qui sotto per andare alla pagina di attivazione PlayStation.",
        psStep2: "In alto a destra, clicca su 'Accedi'",
        psStep3: "Accedi con le tue credenziali PlayStation. Se non hai un account, creane uno gratuitamente.",
        psStep4: "In alto a destra, clicca sul tuo profilo, quindi su 'Riscatta codice'.",
        psStep5: "Inserisci la chiave che hai ricevuto nel campo previsto, quindi clicca su 'Continua'.",
        psStep6: "Congratulazioni! Hai attivato con successo il tuo prodotto. È pronto per essere scaricato e giocato sul tuo account PlayStation.",
        openStore: "Apri il Negozio",
        psStep2console: "Seleziona il PlayStation Store a sinistra e premi 'X'.",
        psStep3console: "Premi '△' per accedere al menu in alto a destra, seleziona '...' e seleziona 'Riscatta codice'.",
        psStep4console: "Inserisci il codice ricevuto nel campo fornito, quindi fai clic su 'Riscatta'.",
        psStep2app: "Apri l'app PlayStation e apri il PlayStation Store.",
        openMenu: "Apri il menu",
        psStep3app: "Seleziona il menu in alto a destra per visualizzare ulteriori opzioni.",
        psStep4app: "Seleziona 'Riscatta codice'.",

        goToXboxActivation: "Vai alla pagina di attivazione Xbox",
        xboxStep1: "Clicca sul pulsante qui sotto per andare alla pagina di attivazione della chiave Xbox.",
        xboxStep2: "Clicca su 'Accedi per riscattare' per accedere con le tue credenziali Xbox. Se non hai un account, creane uno gratuitamente.",
        xboxStep3: "Clicca su 'Riscatta ora' per aprire la finestra di attivazione della chiave.",
        xboxStep4: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Avanti'. Continua a seguire le istruzioni.",
        xboxStep5: "Congratulazioni! Hai attivato con successo il tuo prodotto ed è pronto per essere scaricato e giocato sul tuo account Xbox.",
        xboxStep2console: "Premi il pulsante Xbox sul tuo controller per aprire la guida. Mentre sei connesso al tuo account, seleziona 'Store'.",
        xboxStep3console: "Una volta aperto, premi il pulsante Visualizza sul tuo controller per aprire il menu, quindi seleziona 'Riscatta'.",
        xboxStep4console: "Inserisci la chiave che hai ricevuto nel campo fornito, quindi clicca su 'Controlla codice'.",

        warningModalTitle: "Avviso Importante",
        warningModalMessage1: "Una volta rivelata la chiave, perdi il diritto a un rimborso immediato.",
        warningModalMessage2: "Sarai idoneo per un rimborso solo se la chiave è invalida.",
        doYouWantToProceed: "Vuoi procedere?",
        revealKey: "Rivela la chiave",
        // CHAT
        helpCenter: "Centro assistenza",
        talkWithourSupport: "Parla con il supporto",
        haveAquestion: "Hai una domanda?",
        typeAmessage: "Digita un messaggio...",
        hello: "Ciao",
        howCanIHelpYou: "Come posso aiutarti?",
        home: "Casa",
        history: "Storia",
        help: "Aiuto",
        noChatMessages: "Non hai ancora alcuna cronologia della chat.",
        allHelpTopics: "Tutti gli argomenti di aiuto",
        searchTopics: "Cerca argomenti...",
        noTopicsFound: "Nessun argomento trovato.",

        redeemingCodesCategory: "Riscatto dei codici",
        findingCodesCategory: "Ricerca di codici",
        invoicesCategory: "Fatture",
        codeActivationIssueCategory: "Problemi di attivazione del codice",
        identityVerificationCategory: "Verifica dell'identità",

        howToRedeem: "Come riscattare il codice?",
        whereToFindCodes: "Dove posso trovare i miei codici?",
        whereToGetInvoice: "Dove posso ottenere la fattura del mio acquisto?",
        issuesActivatingCode: "Ho problemi ad attivare il mio codice",
        issuesIdentityVerification: "Ho problemi con la verifica della mia identità",

        howToRedeemDesc: "Segui i passaggi seguenti per riscattare correttamente il tuo codice:",
        findingCodesDesc: "Segui i passaggi seguenti per trovare il tuo codice:",
        invoicesDesc: "Per accedere a una fattura del tuo acquisto, segui i passaggi seguenti:",
        activationIssueDesc: "Se riscontri problemi nell'attivare il tuo codice, verifica quanto segue:",
        identityVerificationDesc: "Se stai affrontando difficoltà con la verifica dell'identità, assicurati di:",

        accessAccountTitle: "Accedi al tuo account",
        accessAccountDesc: "Fai clic sull'icona utente situata nell'angolo in alto a destra della pagina.",

        navigatePurchasesTitle: "Vai agli acquisti",
        navigatePurchasesDesc: "Nel menu a discesa, seleziona 'Acquisti' per visualizzare le tue transazioni recenti.",

        selectRelevantPurchaseTitle: "Seleziona l'acquisto pertinente",
        selectRelevantPurchaseDesc: "Nella tabella degli acquisti, seleziona la transazione associata al codice che desideri attivare.",

        viewKeysTitle: "Visualizza i codici disponibili",
        viewKeysDesc: "Fai clic su 'Visualizza Codici' per mostrare un elenco di codici prodotto disponibili per l'attivazione.",

        revealKeyTitle: "Mostra il codice",
        revealKeyDesc: "Fai clic sull'icona a forma di occhio per visualizzare il codice che desideri attivare.",

        activateKeyTitle: "Attiva il codice",
        activateKeyDesc: "Fai clic sul pulsante 'Attiva' e segui le istruzioni sullo schermo.",

        downloadInvoiceTitle: "Scarica la fattura",
        downloadInvoiceDesc: "Fai clic sul pulsante 'Scarica Fattura' per accedere a una copia PDF della tua fattura.",

        checkTyposTitle: "Controlla errori di battitura",
        checkTyposDesc: "Assicurati di aver inserito correttamente il codice, senza errori di battitura.",

        verifyUsageTitle: "Verifica l'uso del codice",
        verifyUsageDesc: "Assicurati che il codice non sia già stato utilizzato.",

        correctPlatformTitle: "Piattaforma corretta",
        correctPlatformDesc: "Assicurati di utilizzare il codice sulla piattaforma o applicazione corretta.",

        clearDocumentsTitle: "Documenti chiari",
        clearDocumentsDesc: "I tuoi documenti d'identità devono essere chiari e leggibili.",

        matchPersonalInfoTitle: "Corrispondenza dei dati personali",
        matchPersonalInfoDesc: "I tuoi dati personali devono corrispondere ai registri.",

        supportedVerificationMethodTitle: "Metodo di verifica supportato",
        supportedVerificationMethodDesc: "Stai utilizzando un metodo di verifica supportato.",

        notesContactSupport: "Se il problema persiste, contatta il nostro team di supporto per ulteriore assistenza.",
        stillHavingIssue: "Continua ad avere problemi con",
        pleaseLoginToSeeTicketHistory: "Accedi per visualizzare la cronologia dei ticket.",
    },
};
