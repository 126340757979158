import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Product } from 'redux/types/ProductsTypes';
import { FavoriteItem as Favorite } from 'redux/auth/authTypes'; // or wherever the shape of "favorite" is
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Pagination from '@mui/material/Pagination';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// The new RTK Query hook
import { useFetchProductByIdAndPlatformQuery } from 'redux/RTK/productsApi';

// Components
import ProductCardComponent from 'components/ProductCard';
import LoadingCard from './LoadingCard';

/**
 * Subcomponent to fetch & render one favorite item.
 * This uses the RTK Query hook `useFetchProductByIdAndPlatformQuery`.
 */
const FavoriteItem: React.FC<{
  favorite: Favorite;
  lang: string;
}> = ({ favorite, lang }) => {
  // Call the RTK Query endpoint for a single (productId, platformId)
  const { data, isLoading, isError } = useFetchProductByIdAndPlatformQuery({
    productId: favorite.productId,
    platformId: favorite.platformGroupId,
    lang,
  });

  // Show a loading placeholder
  if (isLoading) {
    return (
      <Grid size={{ xs: 12, md: 6 }}>
        <LoadingCard />
      </Grid>
    );
  }

  // Show an error text (you could style this differently or skip the item)
  if (isError || !data) {
    return (
      <Grid size={{ xs: 12, md: 6 }}>
        <Typography color="error">Failed to load product.</Typography>
      </Grid>
    );
  }

  // `data` is the shape returned by `fetchProductByIdAndPlatform`
  // Typically this might look like: { _id, title, price, category, platformId, region, type, ... }
  const productData = data as Product;

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <ProductCardComponent
        withFav
        title={productData.title}
        price={productData.price}
        category={productData.category}
        platform={productData.platformId ?? ''}
        productId={productData.productId ?? ''}
        productPlatformId={productData._id ?? ''}
        type={productData.type}
      />
    </Grid>
  );
};

const FavoritePage: React.FC = () => {
  const { t } = useTranslation();

  // The favorites array from Redux state
  const favorites = useSelector((state: RootState) => state.auth.user?.favorites);
  const language = useSelector((state: RootState) => state.auth.language);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;

  // If there are no favorites
  if (!favorites || favorites.length === 0) {
    return (
      <Grid
        size={{ xs: 12 }}
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="82vh"
      >
        <Typography variant="h4" align="center">
          {t('noFavorites')}
        </Typography>
        <Typography variant="h6" align="center">
          {t('noFavoritesText')}
        </Typography>
        <Button
          sx={{ mt: 5 }}
          startIcon={<ArrowBackRoundedIcon />}
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/products"
        >
          {t('exploreCatalog')}
        </Button>
      </Grid>
    );
  }

  // Compute pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentFavorites = favorites.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handler for pagination
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <Grid container spacing={4} height="100%">
      {/* Render each favorite in the current page */}
      {currentFavorites.map((favorite) => (
        <FavoriteItem
          key={`${favorite.productId}-${favorite.platformGroupId}`}
          favorite={favorite}
          lang={language}
        />
      ))}

      {/* If total favorites exceed page size, show a pagination control */}
      {favorites.length > productsPerPage && (
        <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(favorites.length / productsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FavoritePage;