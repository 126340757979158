import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchProductScreenshotsQuery } from 'redux/RTK/productsApi';

import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Grid2 as Grid, Box, Typography, Dialog, IconButton, AppBar, Toolbar, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ProductGalleryProps {
  productId: string;
  title: string;
  video?: string;
  /**
   * If you still have something called `productDetails` you can keep it,
   * but here it’s unused so we omit it.
   */
  productDetails?: any;
}

const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|e\/u\/\w+\/|embed\/|v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

const SlickSlider = Slider as unknown as React.FC<Settings>;

export const ProductGallery: React.FC<ProductGalleryProps> = ({ productId, video, title }) => {
  const { t } = useTranslation();

  // 1) Use RTK Query to fetch screenshots
  //    This returns { images: string[] } or an error/loading state
  const {
    data: screenshotData,
    isLoading: screenshotsLoading,
    isError: screenshotsError,
  } = useFetchProductScreenshotsQuery(productId);

  // 2) We'll store the small & large images in local state
  const [smallImages, setSmallImages] = useState<string[]>([]);
  const [largeImages, setLargeImages] = useState<string[]>([]);

  // 3) Whenever the query data changes, filter small/large images
  useEffect(() => {
    if (screenshotData?.images) {
      const smImages = screenshotData.images.filter((url: string) => url.endsWith('_sm.webp'));
      const lgImages = screenshotData.images.filter((url: string) => url.endsWith('_lg.webp'));
      setSmallImages(smImages);
      setLargeImages(lgImages);
    }
  }, [screenshotData]);

  // 4) Lightbox-related state
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  // 5) Extract a possible YouTube video ID from the user-provided `video` URL
  const youtubeVideoId = video ? getYoutubeVideoId(video) : null;

  const handleClickOpen = (index: number) => {
    setSelectedImage(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 6) Slider settings
  const settings: Settings = {
    initialSlide: selectedImage,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // 7) If screenshots are loading or error, you can handle it gracefully (optional)
  if (screenshotsLoading) {
    // You could show a spinner, or partial UI
    // For now, we’ll just continue and rely on empty arrays if not loaded
  }
  if (screenshotsError) {
    console.error('Failed to load product screenshots');
  }

  return (
    <Grid container sx={{ mb: 5 }}>
      <Typography variant="h3" sx={{ display: 'flex', flexDirection: 'row', mb: 4 }}>
        {t('game')}&nbsp;
        <Typography
          component="span"
          variant="h3"
          sx={{ color: (theme) => theme.palette.mode === 'light' ? 'primary.main' : 'primary.light' }}
        >
          {t('gallery')}&nbsp;
        </Typography>
      </Typography>

      {/* 8) If there's a YouTube video, embed it */}
      {youtubeVideoId && (
        <Grid size={{ xs: 12 }}>
          <Box
            sx={{
              position: 'relative',
              paddingBottom: '56.25%',
              marginBottom: '34px',
              height: 0,
              overflow: 'hidden',
              maxWidth: '100%',
              background: '#000',
              borderRadius: '21px',
            }}
          >
            <iframe
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
              src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>
      )}

      {/* 9) Display small images as thumbnails */}
      <Grid container spacing={4}>
        {smallImages.map((image, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '21px',
                cursor: 'pointer',
              }}
              src={`https://bucket.bigswiss.ch/${image}`}
              alt={`Game screenshot ${index + 1}`}
              onClick={() => handleClickOpen(index)}
            />
          </Grid>
        ))}
      </Grid>

      {/* 10) Fullscreen dialog with Slick carousel for large images */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { px: 3 }
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, display: 'flex', alignItems: 'center' }}
              variant="h6"
              component="div"
            >
              <img
                src="/img/avatar.svg"
                height={30}
                width={30}
                style={{ marginRight: '21px' }}
              />{' '}
              {title} Gallery
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ p: 4, overflow: 'hidden' }}>
          <SlickSlider {...settings} className="product-ss-slide">
            {largeImages.map((image, index) => (
              <Box
                key={index}
                component="img"
                sx={{
                  maxHeight: '85vh',
                  width: 'auto',
                  height: 'auto',
                  display: 'block',
                  margin: '0 auto',
                  objectFit: 'contain',
                }}
                src={`https://bucket.bigswiss.ch/${image}`}
                alt={`Game screenshot ${index + 1}`}
              />
            ))}
          </SlickSlider>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};