import { createSlice } from '@reduxjs/toolkit';
import { createPaymentIntent } from './paymentThunks';

interface PaymentState {
  paymentMethod: string | null;
  orderStatus: string | null; 
  clientSecret?: string | null;
  paymentId?: string | null;
  error: string | null;
}

const initialState: PaymentState = {
  paymentMethod: null,
  orderStatus: null, 
  clientSecret: null,
  paymentId: null,
  error: null
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {

    clearPaymentData: (state) => {
      state.clientSecret = null;
      state.orderStatus = null;
      state.error = null;
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload;
    },
    setPaymentId: (state, action) => {
      state.paymentId = action.payload
    },
    setPaymentStatus: (state, action) => {
      state.orderStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.clientSecret = action.payload.clientSecret;
        state.paymentId = action.payload.paymentId;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.error = action.payload as string;
      })
  }
});

export const { clearPaymentData, setClientSecret, setPaymentId } = paymentSlice.actions;

export default paymentSlice.reducer;
