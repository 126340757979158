import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SpanishFlag: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 30" preserveAspectRatio="xMidYMid slice">
    <g clipPath="url(#clip0_959_2)">
      <path d="M60 0H0V8H60V0Z" fill="#C60B1E" />
      <path d="M60 8H0V22H60V8Z" fill="#FFC400" />
      <path d="M60 22H0V30H60V22Z" fill="#C60B1E" />
    </g>
    <defs>
      <clipPath id="clip0_959_2">
        <rect width="60" height="30" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SpanishFlag;
