import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSearchQuery } from 'redux/auth/authSlice';

interface SearchComponentProps {
  searchActive: boolean;
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  searchActive,
  setSearchActive,
}) => {
  const [searchQuery, setSearchQueryLocal] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Focus the input when searchActive changes to true
  useEffect(() => {
    if (searchActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchActive]);

  // On each keystroke, update local+global search, then navigate
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setSearchQueryLocal(newQuery);
    dispatch(setSearchQuery(newQuery));
    navigate(`/products?search=${encodeURIComponent(newQuery)}`);
  };

  // Close search on ESC
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setSearchActive(false);
      // Optionally clear the search:
      setSearchQueryLocal('');
      dispatch(setSearchQuery(''));
      navigate('/products');
    }
  };

  // Track previous pathname to detect a genuine route change
  const [prevPath, setPrevPath] = useState(location.pathname);

  useEffect(() => {
    // Only run the "close search" check if:
    // 1) The search is open
    // 2) The pathname actually changed
    // 3) We *were* on /products and now are not
    if (searchActive && prevPath !== location.pathname) {
      if (prevPath.startsWith('/products') && !location.pathname.startsWith('/products')) {
        setSearchActive(false);
      }
    }
    // Update previous path to the current one
    setPrevPath(location.pathname);
  }, [searchActive, location.pathname, prevPath, setSearchActive]);

  return (
    <>
      {searchActive ? (
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', width: '100%' }}>
          <TextField
            variant="standard"
            size="small"
            placeholder="Search Product…"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-root': { backgroundColor: 'transparent' },
              '& .MuiInput-input': { backgroundColor: 'transparent' },
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          />

          <Button
            variant="text"
            aria-label="close"
            sx={{ minWidth: '30px', p: '4px', color: 'text.primary' }}
            onClick={() => setSearchActive(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      ) : (
        <Button
          variant="text"
          aria-label="search"
          sx={{ minWidth: '30px', p: '4px', color: 'text.primary' }}
          onClick={() => setSearchActive(true)}
        >
          <SearchIcon />
        </Button>
      )}
    </>
  );
};

export default SearchComponent;