import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { RootState, AppDispatch } from 'redux/store';
import { resetFilters, setFilter } from 'redux/auth/authSlice';
import { FilterValues } from 'redux/types/ProductsTypes';

// RTK Query
import { productsApi } from 'redux/RTK/productsApi';
import { Product } from 'redux/types/ProductsTypes';
import { 
  useFetchCategoriesQuery, 
  useFetchPlatformsQuery, 
  useFetchTypesQuery,
  useFetchGenresQuery,
} from 'redux/RTK/optionsApi';


import { Button, Container, Avatar, Typography, Chip, Box } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import SearchIcon from '@mui/icons-material/Search';

import noProductImage from 'assets/noProduct.webp';
import CategoryLogosMap from 'components/icons/categoryLogosMap';
import PlatformLogo from 'components/icons/PlatformLogos';

import ProductFilter from 'pages/ProductsPage/Components/ProductFilter';
import LoadingGrid from 'pages/ProductsPage/Components/LoadingGrid';
import ProductGrid from 'pages/ProductsPage/Components/ProductGrid';

export default function ProductsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  // ────────────────────────────────────────────────────────────────────────────
  // 1) Read all query params (including 'page')
  // ────────────────────────────────────────────────────────────────────────────
  const queryParams = new URLSearchParams(location.search);

  const platformId = queryParams.get('platform') || '';
  const typeId = queryParams.get('type') || '';
  const genreId = queryParams.get('genre') || '';
  const categoryId = queryParams.get('category') || '';
  const maxPrice = queryParams.get('maxPrice') || '';
  const searchQuery = queryParams.get('search') || '';

  // Parse the page from query params (default to 1 if missing/invalid)
  const initialPage = Number(queryParams.get('page')) || 1;
  const [page, setPage] = useState(initialPage);

  // ────────────────────────────────────────────────────────────────────────────
  // 2) Grab local Redux filter state (optional if you keep filter UI in Redux)
  // ────────────────────────────────────────────────────────────────────────────
  const { filter } = useSelector((state: RootState) => state.auth);

  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: categories = [] } = useFetchCategoriesQuery();
  const { data: types = [] } = useFetchTypesQuery();
  const { data: genres = [] } = useFetchGenresQuery();

  // ────────────────────────────────────────────────────────────────────────────
  // 3) Fetch products via RTK Query (this uses your custom hook)
  // ────────────────────────────────────────────────────────────────────────────
  const {
    data: productEntityState,
    error,
    isFetching,
    isError,
  } = productsApi.useFetchProductsQuery({
      searchQuery,
      filters: {
        platform: platformId,
        type: typeId,
        genre: genreId,
        category: categoryId,
        maxPrice: maxPrice,
      },
    });

  // Convert normalized productEntityState into an array of Products
  const products: Product[] = useMemo(() => {
    if (!productEntityState) return [];
    // We stored them in normalized form: { ids: [...], entities: {...} }
    // Convert to an array:
    return productEntityState.ids
      .map((id) => productEntityState.entities[id])
      .filter((p): p is Product => Boolean(p));
  }, [productEntityState]);

  // ────────────────────────────────────────────────────────────────────────────
  // 4) Whenever query params change, we can sync them to our redux filter
  //    (This is optional if you want your store to match the URL)
  // ────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (platformId) {
      dispatch(setFilter({ key: 'platform', value: platformId }));
    }
    if (typeId) {
      dispatch(setFilter({ key: 'type', value: typeId }));
    }
    if (genreId) {
      dispatch(setFilter({ key: 'genre', value: genreId }));
    }
    if (categoryId) {
      dispatch(setFilter({ key: 'category', value: categoryId }));
    }
    if (maxPrice) {
      dispatch(setFilter({ key: 'maxPrice', value: maxPrice }));
    }
    // We do NOT reset the page here automatically, but if you want to reset page
    // whenever filters change, you can do:
    // setPage(1);
    // queryParams.set('page', '1');
  }, [dispatch, platformId, typeId, genreId, categoryId, maxPrice]);

  // ────────────────────────────────────────────────────────────────────────────
  // 5) Handlers to reset or remove filters
  // ────────────────────────────────────────────────────────────────────────────
  const handleResetFilters = () => {
    dispatch(resetFilters());
    queryParams.delete('platform');
    queryParams.delete('type');
    queryParams.delete('genre');
    queryParams.delete('category');
    queryParams.delete('maxPrice');
    queryParams.delete('search');
    queryParams.delete('page');
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleDeleteChip = (key: keyof FilterValues) => {
    dispatch(setFilter({ key, value: '' }));
    queryParams.delete(key);
    queryParams.delete('page'); // Optional: also reset to page 1
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  const handleDeleteSearchQuery = () => {
    // If you have a slice state for search, remove that
    // dispatch({ type: 'SET_SEARCH_QUERY', payload: '' }); // Example
    queryParams.delete('search');
    queryParams.delete('page'); // reset page as well
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 6) We'll show chips for each active filter
  // ────────────────────────────────────────────────────────────────────────────
  const activeFilters = [
    {
      key: 'category',
      value: categoryId,
      label: categories.find((c) => c._id === categoryId)?.name || categoryId,
    },
    {
      key: 'platform',
      value: platformId,
      label: platforms.find((p) => p._id === platformId)?.name || platformId,
    },
    {
      key: 'type',
      value: typeId,
      label: types.find((t) => t._id === typeId)?.name || typeId,
    },
    {
      key: 'genre',
      value: genreId,
      label: genres.find((g) => g._id === genreId)?.name || genreId,
    },
    {
      key: 'maxPrice',
      value: maxPrice,
      label: `${t('maxPrice')}: ${maxPrice}`,
    },
  ].filter((f) => f.value);

  const renderAvatar = (filterKey: string, filterValue: string) => {
    if (filterKey === 'platform') {
      return <PlatformLogo platformId={filterValue} />;
    } else if (filterKey === 'category') {
      const IconComponent = CategoryLogosMap[filterValue];
      if (IconComponent) {
        return (
          <Avatar>
            <IconComponent />
          </Avatar>
        );
      }
    } else if (filterKey === 'genre') {
      return <TagIcon fontSize="small" />;
    }
    return null;
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 7) Callback passed to ProductGrid to handle changing pages
  // ────────────────────────────────────────────────────────────────────────────
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    queryParams.set('page', newPage.toString());
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container sx={{ py: { xs: 8, sm: 14 } }}>
      {/* Filter UI (component that sets local or redux states) */}
      <ProductFilter
        filter={{ ...filter, platform: platformId, maxPrice }}
        categories={categories}
        platforms={platforms}
        maxPrice={maxPrice}
        types={types}
        genres={genres}
      />

      {/* Active Filters */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
        {activeFilters.length > 0 && (
          <Chip
            label={t('resetFilters')}
            onClick={handleResetFilters}
            sx={{ m: 0.5 }}
            color="primary"
          />
        )}
        {activeFilters.map((af) => {
          const avatar = renderAvatar(af.key, af.value);
          return (
            <Chip
              key={af.key}
              avatar={avatar ?? undefined}
              label={af.label}
              onDelete={() => handleDeleteChip(af.key as keyof FilterValues)}
              sx={{ m: 0.5 }}
            />
          );
        })}
        {searchQuery && (
          <Chip
            avatar={<SearchIcon />}
            label={`${t('search')}: ${searchQuery}`}
            onDelete={handleDeleteSearchQuery}
            sx={{ m: 0.5 }}
          />
        )}
      </Box>

      {/* Content: If fetching => Loading, If error => show error, else => show products */}
      {isFetching ? (
        <LoadingGrid />
      ) : isError ? (
        <Typography color="error">Error: {String(error)}</Typography>
      ) : (
        <>
          {products.length > 0 ? (
            <ProductGrid
              products={products}
              filter={filter}
              searchQuery={searchQuery}
              page={page}
              onPageChange={handlePageChange}
              itemsPerPage={24}
            />
          ) : (
            // No products found
            <Box textAlign="center">
              <img src={noProductImage} alt="No products" />
              <Typography variant="h3">{t('noProductsFound')}</Typography>
              <Typography variant="h6">{t('tryDifferentQuery')}</Typography>
              <Button variant="contained" color="primary" onClick={handleResetFilters}>
                {t('resetFilters')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}