import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Stack,
  CardMedia,
  ListItemButton,
} from '@mui/material';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CloseIcon from '@mui/icons-material/Close';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import { RootState, AppDispatch } from 'redux/store';
// Removed the old 'fetchProductById' import

import { useFetchProductByIdQuery } from 'redux/RTK/productsApi';

import {
  addItem,
  maxProductAllowed,
  removeItem,
  deleteItem,
  updateItemQuantity,
} from 'redux/reducers/cartReducer';

import { getProductImgSm } from 'utils/getNames';
import { Product } from 'redux/types/ProductsTypes';
import ProductPriceTag from 'components/ProductPriceTag';
import QuantityControl from './QuantityControl';
import AvailablePayments from 'components/AvailablePayments';

/**
 * A subcomponent that fetches the product data for a single cart item,
 * finds the correct platform, and then renders the row in the drawer.
 */
const CartItemRow: React.FC<{
  cartItemId: string;
  productId: string;
  platformGroupId: string;
  quantity: number;
  price: number;
  handleAddMore: (id: string) => void;
  handleRemove: (id: string) => void;
  handleDelete: (id: string) => void;
  handleQuantityChange: (id: string, newQuantity: number) => void;
}> = ({
  cartItemId,
  productId,
  platformGroupId,
  quantity,
  price,
  handleAddMore,
  handleRemove,
  handleDelete,
  handleQuantityChange,
}) => {
  // 1) Use RTK Query to fetch the product
  const {
    data: fetchedProduct,
    isLoading,
    isError,
  } = useFetchProductByIdQuery(productId);

  // 2) If loading or error, show placeholders
  if (isLoading) {
    return (
      <ListItem>
        <ListItemText primary="Loading product..." />
      </ListItem>
    );
  }
  if (isError || !fetchedProduct) {
    return (
      <ListItem>
        <ListItemText primary="Error or product not found" />
      </ListItem>
    );
  }

  // 3) Find the matching platform
  const platformDetail = fetchedProduct.platforms.find(
    (p) => p._id === platformGroupId
  );

  // If we can't find the platform, we show a fallback
  if (!platformDetail) {
    return (
      <ListItem>
        <ListItemText primary="Platform not found in product data" />
      </ListItem>
    );
  }

  // 4) Render the existing UI for each cart item row
  return (
    <ListItem disablePadding>
      <ListItemButton>
        <CardMedia
          component="img"
          image={getProductImgSm(productId)}
          alt={fetchedProduct.title}
          sx={{
            width: 120,
            height: 55,
            margin: 'auto',
            objectFit: 'cover',
            marginRight: 2,
            borderRadius: '8px',
          }}
        />

        <ListItemText primary={fetchedProduct.title} />

        <Stack direction="column" spacing={1} alignItems="center">
          <ProductPriceTag
            productTagPrice={price.toFixed(2)}
            variant="body2"
          />
          <QuantityControl
            size="small"
            maxProductAllowed={maxProductAllowed}
            quantity={quantity}
            onAdd={() => handleAddMore(cartItemId)}
            onRemove={() => handleRemove(cartItemId)}
            onDelete={() => handleDelete(cartItemId)}
            onQuantityChange={(newQty) => handleQuantityChange(cartItemId, newQty)}
          />
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

function CartButton() {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);

  // 1) Basic cart data
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const itemCount = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const cartTotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // 2) We keep track of whether the drawer is open
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // 3) We track any forcibly removed items so we can hide them instantly
  const [removedItems, setRemovedItems] = useState<string[]>([]);

  // We store the previous itemCount to auto-open drawer if new items were added
  const prevItemCountRef = React.useRef(itemCount);

  // 4) If cart size grew & not on /cart page => open drawer
  useEffect(() => {
    if (
      itemCount > prevItemCountRef.current &&
      location.pathname !== '/cart'
    ) {
      setDrawerOpen(true);
    }
    prevItemCountRef.current = itemCount;
  }, [itemCount, location.pathname]);

  // 5) Handlers to mutate cart
  const handleAddMore = (id: string) => {
    // We'll find the item in state
    const foundItem = cartItems.find((ci) => ci.id === id);
    if (!foundItem) return;

    // Just dispatch addItem with +1 quantity
    dispatch(
      addItem({
        id,
        productId: foundItem.productId,
        platformGroupId: foundItem.platformGroupId,
        price: foundItem.price,
        quantity: 1,
      })
    );
  };

  const handleQuantityChange = (id: string, newQuantity: number) => {
    dispatch(
      updateItemQuantity({
        id,
        quantity: newQuantity,
      })
    );
  };

  const handleRemove = (id: string) => {
    dispatch(removeItem({ id }));
  };

  const handleDelete = (id: string) => {
    dispatch(deleteItem({ id }));
    setRemovedItems((prev) => [...prev, id]);
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      {/* The cart button (with item count badge) linking to /cart */}
      <Button
        variant="text"
        size="small"
        sx={{ minWidth: '32px', height: '32px', p: '4px', color: 'text.primary' }}
        component={RouterLink}
        to="/cart"
      >
        <Badge badgeContent={itemCount} color="secondary" invisible={itemCount === 0}>
          <ShoppingCartIcon />
        </Badge>
      </Button>

      {/* The cart drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            margin: '25px',
            height: 'calc(100vh - 50px)',
            borderRadius: '21px',
          },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: 450 },
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ p: 2 }}>
            {t('yourCart')}
          </Typography>

          <List>
            {/* If empty cart, show a message */}
            {cartItems.length === 0 && (
              <ListItem>
                <ListItemText primary={t('emptyCartSubheading')} />
              </ListItem>
            )}

            {/* Otherwise, render each item via our CartItemRow subcomponent */}
            {cartItems.map((item, index) => {
              if (removedItems.includes(item.id)) {
                return null; // skip forcibly deleted items
              }

              return (
                <CartItemRow
                  key={item.id}
                  cartItemId={item.id}
                  productId={item.productId}
                  platformGroupId={item.platformGroupId}
                  quantity={item.quantity}
                  price={item.price}
                  handleAddMore={handleAddMore}
                  handleRemove={handleRemove}
                  handleDelete={handleDelete}
                  handleQuantityChange={handleQuantityChange}
                />
              );
            })}
          </List>

          {/* Cart total & actions */}
          <Stack sx={{ p: 2, marginTop: 'auto' }} spacing={3}>
            <Stack direction="column" spacing={1} sx={{ mb: 5 }}>
              <Typography
                variant="h5"
                textAlign="right"
                display="flex"
                justifyContent="space-between"
              >
                {t('total')}:
                <ProductPriceTag productTagPrice={cartTotal.toFixed(2)} variant="h5" />
              </Typography>
              <Typography
                variant="h5"
                textAlign="right"
                display="flex"
                color="text.secondary"
                justifyContent="space-between"
              >
                {t('discount')}:
                <ProductPriceTag productTagPrice="0" variant="h5" />
              </Typography>
              <Typography
                variant="h5"
                textAlign="right"
                display="flex"
                justifyContent="space-between"
              >
                {t('total')}:
                <ProductPriceTag productTagPrice={cartTotal.toFixed(2)} variant="h5" />
              </Typography>
            </Stack>

            <Button
              variant="outlined"
              color="primary"
              component={RouterLink}
              startIcon={<ShoppingCartIcon />}
              to="/cart"
              fullWidth
              onClick={toggleDrawer(false)}
            >
              {t('goToCart')}
            </Button>

            {user && (
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                startIcon={<CreditCardIcon />}
                to="/cart/payment"
                fullWidth
                onClick={toggleDrawer(false)}
              >
                {t('goToPayment')}
              </Button>
            )}
          </Stack>

          <AvailablePayments />
        </Box>
      </Drawer>
    </>
  );
}

export default CartButton;