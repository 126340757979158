// File: src/components/Chat.tsx

import React, { useState, useEffect } from 'react';
import { Box, Stack, TextField, Button } from '@mui/material';
import { Send } from '@mui/icons-material';
import { getChatSocket } from 'sockets/ChatSocket';
import MessageBubble from './MessageBubble';
import { useTranslation } from 'react-i18next';
import { useFetchUserTicketDetailQuery } from 'redux/RTK/userApi';
import { useNavigate } from 'react-router-dom';

type Sender = 'user' | 'AI' | 'support';

interface Message {
  text: string;
  sender: Sender;
  createdAt: string;
}

interface ChatProps {
  onClose: () => void;
  topic?: string | null;
  ticketId?: string | null;
  setCurrentTicketId: (ticketId: string) => void;
}

const Chat: React.FC<ChatProps> = ({
  onClose,
  topic,
  ticketId,
  setCurrentTicketId,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const chatSocket = getChatSocket();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // 1) Optionally fetch an existing ticket’s messages
  const { data: ticketData } = useFetchUserTicketDetailQuery(ticketId!, {
    skip: !ticketId,
  });

  // 2) Whenever we get ticketData, transform & store in local messages
  useEffect(() => {
    if (ticketData?.messages) {
      const transformed = ticketData.messages.map((m) => ({
        text: m.message,
        sender: m.sender as Sender,
        createdAt: m.sentAt
          ? new Date(m.sentAt).toISOString()
          : new Date().toISOString(),
      }));
      setMessages(transformed);
    }
  }, [ticketData]);

  // 3) Handle sending message
  const handleSendMessage = () => {
    if (inputValue.trim() && chatSocket && chatSocket.connected) {
      // a) Add user message locally
      const userMessage: Message = {
        text: inputValue,
        sender: 'user',
        createdAt: new Date().toISOString(),
      };
      // b) Also add a "typing..." bubble from AI
      const typingBubble: Message = {
        text: 'typing...',
        sender: 'AI',
        createdAt: new Date().toISOString(),
      };

      setMessages((prev) => [...prev, userMessage, typingBubble]);

      // c) Emit to server
      chatSocket.emit('message', inputValue, ticketId);

      // d) Clear input
      setInputValue('');
    } else {
      console.log('chatSocket is not connected or inputValue is empty');
    }
  };

  // 4) If a “topic” is provided, send it as an initial message
  useEffect(() => {
    if (!topic || !chatSocket) return;
    if (!chatSocket.connected) return;
    console.log('Sending topic as initial message:', topic);
    chatSocket.emit('message', topic);
  }, [topic, chatSocket]);

  // 5) Listen for incoming messages from the server
  useEffect(() => {
    if (!chatSocket) return;

    // Actual AI response
    const handleReceiveMessage = (data: {
      sender: string;
      message: string;
      createdAt?: string;
      ticketId: string;
      redirectLink: string;
    }) => {
      // a) Update the current ticketId (if provided)
      setCurrentTicketId(data.ticketId);
      if (data.redirectLink) {
        navigate(data.redirectLink);
      }
      // b) Remove the "typing..." bubble if it exists, then add the final AI message
      setMessages((prev) => {
        const idx = prev.findIndex(
          (msg) => msg.sender === 'AI' && msg.text === 'typing...'
        );
        const newArr = [...prev];

        // If we found a typing bubble, remove it
        if (idx > -1) {
          newArr.splice(idx, 1);
        }

        // Add the real AI message
        newArr.push({
          text: data.message,
          sender: data.sender as Sender,
          createdAt: data.createdAt || new Date().toISOString(),
        });
        return newArr;
      });
    };

    // Error messages
    const handleErrorMessage = (data: {
      message: string;
      createdAt?: string;
    }) => {
      setMessages((prev) => [
        ...prev,
        {
          text: data.message,
          sender: 'AI',
          createdAt: data.createdAt || new Date().toISOString(),
        },
      ]);
    };

    chatSocket.on('receiveMessage', handleReceiveMessage);
    chatSocket.on('errorMessage', handleErrorMessage);

    return () => {
      chatSocket.off('receiveMessage', handleReceiveMessage);
      chatSocket.off('errorMessage', handleErrorMessage);
    };
  }, [chatSocket, setCurrentTicketId]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Scrollable Messages Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: '16px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <Box sx={{ zIndex: 10 }}>
          {messages.map((msg, idx) => (
            <MessageBubble key={idx} message={msg} />
          ))}
        </Box>
      </Box>

      {/* Send Message Input */}
      <Stack
        direction="row"
        sx={{
          padding: '16px',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          placeholder={t('typeAmessage') || 'Type a message...'}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          sx={{ width: '30px', ml: 2 }}
          endIcon={<Send />}
          onClick={handleSendMessage}
        />
      </Stack>
    </Box>
  );
};

export default Chat;