// File: src/topics/useTopicsData.ts

import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI Icons
import {
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  TableChart as TableChartIcon,
  ViewList as ViewListIcon,
  Visibility as VisibilityIcon,
  PlayArrow as CheckCircleIcon,
  Spellcheck as SpellcheckIcon,
  History as HistoryIcon,
  Apps as AppsIcon,
  AssignmentInd as AssignmentIndIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material';

interface Step {
  icon?: React.ReactElement;
  title?: string;
  description: string;
}

export interface TopicData {
  category: string;
  topic: string;
  content: string;
  steps?: Step[];
  notes?: string;
  faq?: boolean;
}

/**
 * This hook returns the localized 'topicsData' array
 * using react-i18next for translations.
 */
export function useTopicsData(): TopicData[] {
  const { t } = useTranslation();

  // Build your array below, using t() for each string:
  const topicsData: TopicData[] = [
    // 1) How to redeem the code?
    {
      category: t('redeemingCodesCategory'),
      topic: t('howToRedeem'),
      content: t('howToRedeemDesc'),
      steps: [
        {
          icon: React.createElement(PersonIcon, { color: 'primary' }),
          title: t('accessAccountTitle'),
          description: t('accessAccountDesc'),
        },
        {
          icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
          title: t('navigatePurchasesTitle'),
          description: t('navigatePurchasesDesc'),
        },
        {
          icon: React.createElement(TableChartIcon, { color: 'primary' }),
          title: t('selectRelevantPurchaseTitle'),
          description: t('selectRelevantPurchaseDesc'),
        },
        {
          icon: React.createElement(ViewListIcon, { color: 'primary' }),
          title: t('viewKeysTitle'),
          description: t('viewKeysDesc'),
        },
        {
          icon: React.createElement(VisibilityIcon, { color: 'primary' }),
          title: t('revealKeyTitle'),
          description: t('revealKeyDesc'),
        },
        {
          icon: React.createElement(CheckCircleIcon, { color: 'primary' }),
          title: t('activateKeyTitle'),
          description: t('activateKeyDesc'),
        },
      ],
      faq: true,
    },

    // 2) Where can I find my codes?
    {
      category: t('findingCodesCategory'),
      topic: t('whereToFindCodes'),
      content: t('findingCodesDesc'),
      steps: [
        {
          icon: React.createElement(PersonIcon, { color: 'primary' }),
          title: t('accessAccountTitle'),
          description: t('accessAccountDesc'),
        },
        {
          icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
          title: t('navigatePurchasesTitle'),
          description: t('navigatePurchasesDesc'),
        },
        {
          icon: React.createElement(TableChartIcon, { color: 'primary' }),
          title: t('selectRelevantPurchaseTitle'),
          description: t('selectRelevantPurchaseDesc'),
        },
        {
          icon: React.createElement(ViewListIcon, { color: 'primary' }),
          title: t('viewKeysTitle'),
          description: t('viewKeysDesc'),
        },
      ],
      faq: true,
    },

    // 3) Where can I get an invoice?
    {
      category: t('invoicesCategory'),
      topic: t('whereToGetInvoice'),
      content: t('invoicesDesc'),
      steps: [
        {
          icon: React.createElement(PersonIcon, { color: 'primary' }),
          title: t('accessAccountTitle'),
          description: t('accessAccountDesc'),
        },
        {
          icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
          title: t('navigatePurchasesTitle'),
          description: t('navigatePurchasesDesc'),
        },
        {
          icon: React.createElement(TableChartIcon, { color: 'primary' }),
          title: t('selectRelevantPurchaseTitle'),
          description: t('selectRelevantPurchaseDesc'),
        },
        {
          icon: React.createElement(ViewListIcon, { color: 'primary' }),
          title: t('downloadInvoiceTitle'),
          description: t('downloadInvoiceDesc'),
        },
      ],
      faq: true,
    },

    // 4) I have issues activating my code
    {
      category: t('codeActivationIssueCategory'),
      topic: t('issuesActivatingCode'),
      content: t('activationIssueDesc'),
      steps: [
        {
          icon: React.createElement(SpellcheckIcon, { color: 'primary' }),
          title: t('checkTyposTitle'),
          description: t('checkTyposDesc'),
        },
        {
          icon: React.createElement(HistoryIcon, { color: 'primary' }),
          title: t('verifyUsageTitle'),
          description: t('verifyUsageDesc'),
        },
        {
          icon: React.createElement(AppsIcon, { color: 'primary' }),
          title: t('correctPlatformTitle'),
          description: t('correctPlatformDesc'),
        },
      ],
      notes: t('notesContactSupport'),
      faq: true,
    },

    // 5) I have issues with my identity verification
    {
      category: t('identityVerificationCategory'),
      topic: t('issuesIdentityVerification'),
      content: t('identityVerificationDesc'),
      steps: [
        {
          icon: React.createElement(AssignmentIndIcon, { color: 'primary' }),
          title: t('clearDocumentsTitle'),
          description: t('clearDocumentsDesc'),
        },
        {
          icon: React.createElement(PersonIcon, { color: 'primary' }),
          title: t('matchPersonalInfoTitle'),
          description: t('matchPersonalInfoDesc'),
        },
        {
          icon: React.createElement(VerifiedUserIcon, { color: 'primary' }),
          title: t('supportedVerificationMethodTitle'),
          description: t('supportedVerificationMethodDesc'),
        },
      ],
      faq: true,
    },
  ];

  return topicsData;
}