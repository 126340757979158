import React from 'react';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { RootState } from 'redux/store';
import { useAddFavoriteMutation, useRemoveFavoriteMutation } from 'redux/RTK/userApi';

import { useNavigate } from 'react-router-dom';

interface FavoritesButtonProps {
  productId: string;
  platformGroupId: string;
  icon?: boolean;
}

const FavoritesButton: React.FC<FavoritesButtonProps> = ({ productId, platformGroupId, icon }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const isFavorited = Array.isArray(user?.favorites) && user.favorites.some((favorite: { productId: string; platformGroupId: string }) =>
    favorite.productId === productId &&
    favorite.platformGroupId === platformGroupId
  );

  const handleFavoriteToggle = () => {
    if (!user) {
      const searchParams = new URLSearchParams({
        redirect: 'product',
        productId: productId,
        platformGroupId: platformGroupId
      });
      navigate(`/login?${searchParams.toString()}`);
    } else if (isFavorited) {
      removeFavorite(platformGroupId);
    } else {
      addFavorite({ productId, platformGroupId });
    }
  };

  return icon ? (
    <IconButton color="secondary" onClick={handleFavoriteToggle}>
      {isFavorited ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon color="error" />}
    </IconButton>
  ) : (
    <Button
      variant="outlined"
      color="secondary"
      size="large"
      onClick={handleFavoriteToggle}
    >
      {isFavorited ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon color="error" />}
    </Button>
  );
};

export default FavoritesButton;
