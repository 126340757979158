import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {  Invoice } from 'redux/RTK/userApi';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { productsApi } from 'redux/RTK/productsApi';
import { useFetchPlatformsQuery, useFetchRegionsQuery } from 'redux/RTK/optionsApi';

import vatRates from './vatRates';
import { getPlatformName, getRegionName } from 'utils/getNames';

interface InvoicePdfGeneratorProps {
  invoice: Invoice;
  title: string;
}

const InvoicePdfGenerator: React.FC<InvoicePdfGeneratorProps> = ({
  invoice,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  // We get platform/region data from RTK Query to translate IDs to names
  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: regions = [] } = useFetchRegionsQuery();

  /**
   * productDetailsMap:
   *   Key = combination of productId + platformGroupId (or just productId if unique)
   *   Value = { title, platform, region }
   */
  const [productDetailsMap, setProductDetailsMap] = useState<{
    [key: string]: { title: string; platform: string; region: string };
  }>({});

  /**
   * 1) In a useEffect, fetch product+platform data for each invoice item
   *    by calling the RTK Query endpoint imperatively with `.initiate(...)`.
   */
  useEffect(() => {
    const fetchDetails = async () => {
      const newDetailsMap: {
        [key: string]: { title: string; platform: string; region: string };
      } = {};

      // We'll do a "Promise.all" so we can fetch data in parallel
      await Promise.all(
        invoice.items.map(async (item) => {
          // We'll build a unique key (productId + platformGroupId) to store in newDetailsMap
          const key = `${item.productId}_${item.platformGroupId}`;

          try {
            // 2) Dispatch the endpoint imperatively
            const result = await dispatch(
              productsApi.endpoints.fetchProductByIdAndPlatform.initiate({
                productId: item.productId,
                platformId: item.platformGroupId,
                lang: 'en', // or any other language if you have that info
              })
            ).unwrap();

            // `result` is the returned product data with the correct platform's price, etc.
            // Since your endpoint returns a shape like { _id, platforms, title, etc. },
            // we can get "region" or "platform" from that or from the single platform if it's merged.
            // But your real endpoint might differ. We'll assume result has "platformId", "region", etc.

            // For safety, let's find the platform from result.platforms if needed:
            const platform = result.platformId || ''; // If your data structure merges it
            const region = result.region || '';       // If your data structure merges it

            // If your endpoint merges them into top-level fields, just use them
            // If you still get an array, you'd find the matching platform
            // For example:
            // const matchedPlatform = result.platforms?.find((p) => p._id === item.platformGroupId);
            // then extract your platformId, region, etc. from matchedPlatform

            // We'll do a simplified approach:
            const platformName = getPlatformName(platform, platforms);
            const regionName = getRegionName(region, regions);

            newDetailsMap[key] = {
              title: result.title || '',  // fallback if not found
              platform: platformName,
              region: regionName,
            };
          } catch (err) {
            console.error('Failed to fetch product+platform for item:', item, err);
            newDetailsMap[key] = { title: '', platform: '', region: '' };
          }
        })
      );

      setProductDetailsMap(newDetailsMap);
    };

    fetchDetails();
  }, [invoice, dispatch, platforms, regions]);

  /**
   * 2) Generate the PDF with data from productDetailsMap
   */
  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    const vatPercentage = vatRates[invoice.userCountry] || 0;
    const logoUrl = '/img/LogoDark.png';
    const logoWidth = 70;
    const logoHeight = (64.92 / 297.54) * logoWidth;

    doc.addImage(logoUrl, 'PNG', 14, 12, logoWidth, logoHeight);

    doc.setFontSize(11);
    doc.setTextColor('#282828');
    doc.text('Bigswiss GmbH', 195, 15, { align: 'right' });
    doc.text('Lercherstrasse 30', 195, 20, { align: 'right' });
    doc.text('9195 Gossau, St.Gallen', 195, 25, { align: 'right' });
    doc.text('Switzerland', 195, 30, { align: 'right' });
    doc.text('Reg No:', 140, 40);
    doc.text('CH-320.4.078.777-3', 195, 40, { align: 'right' });
    doc.text('VAT No:', 140, 45);
    doc.text('CHE-361.327.609', 195, 45, { align: 'right' });

    doc.setFontSize(16);
    doc.setTextColor('#282828');
    doc.text('Invoice Number', 14, 60);
    doc.text(`${invoice.invoiceNumber}`, 195, 60, { align: 'right' });
    doc.setDrawColor(0, 0, 0);
    doc.line(14, 63, 196, 63);

    doc.setFontSize(11);
    doc.setTextColor('#282828');
    doc.text('Invoice To:', 14, 70);
    doc.text(`${invoice.userName}`, 14, 80);
    doc.text(`${invoice.userAddress}`, 14, 85);
    doc.text(`${invoice.userCity}, ${invoice.userZip}, ${invoice.userCountry}`, 14, 90);

    doc.text(`Email: ${invoice.userEmail}`, 14, 100);

    doc.setFontSize(11);
    doc.setTextColor('#282828');
    doc.text('Payment details:', 195, 70, { align: 'right' });
    doc.text(`Date Paid`, 195, 80, { align: 'right' });
    doc.text(`${new Date(invoice.createdAt).toLocaleString()}`, 195, 85, { align: 'right' });
    doc.text(`Payment Method`, 195, 95, { align: 'right' });
    doc.text(`${invoice.paymentMethod}`, 195, 100, { align: 'right' });

    const vatAmount = (invoice.total * vatPercentage) / (100 + vatPercentage);
    const netAmount = invoice.total - vatAmount;

    // 3) Build the table rows
    const tableBody = invoice.items.map((item) => {
      const key = `${item.productId}_${item.platformGroupId}`;
      const details = productDetailsMap[key] || {
        title: '',
        platform: '',
        region: '',
      };

      const itemName = `${details.title}\n${details.platform} - ${details.region}`;
      const itemPriceExclVAT = (item.price / (1 + vatPercentage / 100)).toFixed(2);
      const itemVAT = ((item.price * vatPercentage) / (100 + vatPercentage)).toFixed(2);
      const itemTotal = (item.price * item.quantity).toFixed(2);

      return [
        {
          content: item.quantity,
          styles: { halign: 'center' as const, valign: 'middle' as const },
        },
        {
          content: itemName,
          styles: { halign: 'left' as const, valign: 'middle' as const },
        },
        {
          content: `${itemPriceExclVAT} ${invoice.currency}`,
          styles: { halign: 'right' as const, valign: 'middle' as const },
        },
        {
          content: `${itemVAT} ${invoice.currency}`,
          styles: { halign: 'right' as const, valign: 'middle' as const },
        },
        {
          content: `${itemTotal} ${invoice.currency}`,
          styles: { halign: 'right' as const, valign: 'middle' as const },
        },
      ];
    });

    // 4) Render table with autoTable
    autoTable(doc, {
      head: [
        [
          { content: 'Qty', styles: { halign: 'center', valign: 'middle' } },
          { content: 'Product', styles: { halign: 'left' } },
          { content: 'Price (Excl. VAT)', styles: { halign: 'right', valign: 'middle' } },
          { content: 'VAT', styles: { halign: 'right', valign: 'middle' } },
          { content: 'Total Price', styles: { halign: 'right', valign: 'middle' } },
        ],
      ],
      body: tableBody,
      startY: 110,
    });

    // 5) Summaries below the table
    const finalY = (doc as any).lastAutoTable.finalY;
    doc.setFontSize(11);
    doc.setTextColor('#282828');
    doc.text(`Net Amount (Excl. VAT):`, 115, finalY + 10);
    doc.text(`${netAmount.toFixed(2)} ${invoice.currency}`, 195, finalY + 10, {
      align: 'right',
    });
    doc.text(`VAT (${vatPercentage}%):`, 115, finalY + 20);
    doc.text(`${vatAmount.toFixed(2)} ${invoice.currency}`, 195, finalY + 20, {
      align: 'right',
    });
    doc.text(`Total Amount (Incl. VAT):`, 115, finalY + 30);
    doc.text(`${invoice.total.toFixed(2)} ${invoice.currency}`, 195, finalY + 30, {
      align: 'right',
    });

    // 6) Footer
    doc.setFontSize(10);
    doc.setTextColor('#505050');
    doc.text('For any questions, please contact support@bigswiss.ch', 14, 280);
    doc.text(
      'Bigswiss GmbH | Lercherstrasse 30 | 9195 Gossau | Switzerland',
      14,
      285
    );

    // 7) Finally, save the PDF
    doc.save(`invoice_${invoice.invoiceNumber}.pdf`);
  };

  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="contained"
      color="primary"
      onClick={generatePdf}
    >
      {title}
    </Button>
  );
};

export default InvoicePdfGenerator;