// File: src/axiosGetToken.ts
import axios from 'axios';
import Cookies from 'js-cookie';
import { store } from '../store';
import { clearAuthState } from '../auth/authSlice';
import { handleRegionHeadersIfChanged } from './locationActions';

let isLoggingOut = false;

/**
 * This axios instance will handle:
 * 1) CSRF tokens
 * 2) 401/403 => log out
 * 3) region/currency updates from response headers
 */
const axiosGetToken = axios.create({
  baseURL: '/api',
  withCredentials: true, // still allows cookie-based sessions
});

// ─────────────────────────────────────────────────────────────────────────────
// 1) REQUEST INTERCEPTOR => attach CSRF token if present
// ─────────────────────────────────────────────────────────────────────────────
axiosGetToken.interceptors.request.use(
  (config) => {
    const csrfToken = Cookies.get('XSRF-TOKEN');
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ─────────────────────────────────────────────────────────────────────────────
// 2) RESPONSE INTERCEPTOR => check status codes & region headers
// ─────────────────────────────────────────────────────────────────────────────
axiosGetToken.interceptors.response.use(
  (response) => {
    /**
     *  A) If the server includes x-currency, x-region, x-user-ip in the headers,
     *     we call handleRegionHeadersIfChanged to update Redux if needed.
     */
    const xCurrency = response.headers['x-currency'];
    const xRegion = response.headers['x-region'];
    const xUserIp = response.headers['x-user-ip'];

    handleRegionHeadersIfChanged(store.dispatch, store.getState, {
      'x-currency': xCurrency,
      'x-region': xRegion,
      'x-user-ip': xUserIp,
    });

    // B) Return the response as usual
    return response;
  },
  (error) => {
    /**
     *  A) If error.response exists, check for 401/403 logic:
     *     If so, dispatch clearAuthState() once => log user out.
     */
    if (error.response) {
      const { status, data } = error.response;

      if (
        (status === 401 ||
          (status === 403 && data?.code === 'EBADCSRFTOKEN')) &&
        !isLoggingOut
      ) {
        isLoggingOut = true;
        store.dispatch(clearAuthState());
        isLoggingOut = false;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosGetToken;