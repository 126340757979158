// src/topics/TopicDetail.tsx

import React, { useContext } from 'react';
import { Typography, Card, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { ChatContext } from '../index';
import NewChat from '../NewChat';
import { TopicData } from './topicsData';
import { useTranslation } from 'react-i18next';

interface TopicDetailProps {
    topicData: TopicData;
}

const TopicDetail: React.FC<TopicDetailProps> = ({ topicData }) => {
    const { openChat } = useContext(ChatContext);
    const { topic, content, steps, notes, faq } = topicData;
    const { t } = useTranslation();
    return (
        <>
            <Card sx={{ mb: 3 }}>
                <Typography variant="body1" gutterBottom sx={{ px: 3, pt: 3 }}>
                    {content}
                </Typography>
                {steps && (
                    <List>
                        {steps.map((step, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <ListItemIcon>{step.icon}</ListItemIcon>
                                    <ListItemText primary={step.title} secondary={step.description} />
                                </ListItem>
                                {index < steps.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Card>

            {notes && (
                <Card sx={{ mb: 3, p: 3 }}>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {notes}
                    </Typography>
                </Card>
            )}

            <NewChat
                title={`${t('stillHavingIssue')} ${topic.toLowerCase()}?`}
                subheader={t('talkWithourSupport')}
                topic={topic}
                onClick={(topic) => openChat(topic)}
            />
        </>
    );
};

export default TopicDetail;
