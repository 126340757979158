import React, { useEffect, useState, useRef } from 'react';
import {
    Grid2 as Grid,
    Card,
    CardContent,
    Container,
    useTheme,
    CardHeader,
    Link,
    Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { logout } from 'redux/auth/authThunks';
import { setWelcomePageLoading } from 'redux/auth/authSlice';

import LogoutIcon from '@mui/icons-material/Logout';
import CoverComponent from './CoverComponent';
import VerifyEmail from './VerifyEmail';
import BillingInformation from './BillingInformation';
import VerifyIdentity from './VerifyIdentity';
import { useIdentitySocket } from 'hooks/useIdentitySocket';

const WelcomePage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { paymentStatus } = useIdentitySocket();

    const [isVisible, setIsVisible] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [currentStep, setCurrentStep] = useState<'email' | 'billing' | 'identity' | 'completed'>('email');
    const [verificationVisible, setVerificationVisible] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const isEmailVerified = useSelector((state: RootState) => state.auth.isEmailVerified);
    const hasMissingInfo = useSelector((state: RootState) => state.auth.hasMissingInfo);
    const identityRequireCheck = useSelector((state: RootState) => state.auth.identityRequireCheck);
    const { paymentId, clientSecret } = useSelector((state: RootState) => state.payment);
    // Refs to store previous state values
    const prevIsEmailVerifiedRef = useRef(isEmailVerified);
    const prevHasMissingInfoRef = useRef(hasMissingInfo);
    const prevIdentityRequireCheckRef = useRef(identityRequireCheck);
    const isFirstRender = useRef(true);

    useEffect(() => {
        navigate('/welcome', { replace: true });
        setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // Initialize currentStep when the component mounts
        const initialStep = getNextStep();
        setCurrentStep(initialStep);
    }, [navigate]);

    // Function to determine the next step
    const getNextStep = () => {
        if (!isEmailVerified) {
            return 'email';
        } else if (hasMissingInfo) {
            return 'billing';
        } else if (identityRequireCheck) {
            return 'identity';
        } else {
            return 'completed';
        }
    };

    const handleLogout = () => {
        navigate('/');
        dispatch(logout())
            .unwrap()
            .catch((error: unknown) => {
                console.error('Logout failed:', error);
            });
    };

    const handleAnimationComplete = () => {
        setShowVerification(true);
        setTimeout(() => {
            setVerificationVisible(true);
        }, 100);
    };

    const handleStepComplete = () => {
        setIsTransitioning(true); // Indicate that a transition is starting
        setVerificationVisible(false); // Start fade-out animation
    };

    // Use useEffect to monitor changes in the state variables
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            // Update previous state values
            prevIsEmailVerifiedRef.current = isEmailVerified;
            prevHasMissingInfoRef.current = hasMissingInfo;
            prevIdentityRequireCheckRef.current = identityRequireCheck;
            return;
        }

        // Check if any of the state variables have changed
        if (
            prevIsEmailVerifiedRef.current !== isEmailVerified ||
            prevHasMissingInfoRef.current !== hasMissingInfo ||
            prevIdentityRequireCheckRef.current !== identityRequireCheck
        ) {
            handleStepComplete();
        }

        // Update previous state values
        prevIsEmailVerifiedRef.current = isEmailVerified;
        prevHasMissingInfoRef.current = hasMissingInfo;
        prevIdentityRequireCheckRef.current = identityRequireCheck;
    }, [isEmailVerified, hasMissingInfo, identityRequireCheck]);

    useEffect(() => {
        if (isTransitioning) {
            const transitionDuration = 1500;
            const timeout = setTimeout(() => {
                const nextStep = getNextStep();
                setCurrentStep(nextStep);
                if (nextStep !== 'completed') {
                    setVerificationVisible(true); // Start fade-in animation
                } else {
                    // Handle completion
                    setIsVisible(false);
                    dispatch(setWelcomePageLoading(false));
                    console.log(`Payment status: ${paymentStatus}`);
                    if (paymentStatus === 'paid') {
                        navigate(`/cart/game-activation?payment_intent=${paymentId}&payment_intent_client_secret=${clientSecret}`);
                    } else {
                        navigate('/dashboard');
                    }
                }
                setIsTransitioning(false); // Transition is complete
            }, transitionDuration);

            return () => clearTimeout(timeout);
        }
        // Include state variables in the dependency array
    }, [isTransitioning, isEmailVerified, hasMissingInfo, identityRequireCheck, dispatch, navigate]);

    return (
        <Container sx={{ minHeight: '100vh', justifyContent: 'center' }}>
            <Grid container spacing={2} height={'100%'}>
                <Card
                    sx={{
                        minHeight: '750px',
                        minWidth: '100%',
                        transition: 'background-color 1.5s ease, opacity 1.5s ease',
                        backgroundColor: isVisible ? theme.palette.background.paper : 'transparent',
                        opacity: isVisible ? 1 : 0,
                    }}
                >
                    <CardHeader
                        action={
                            <Link
                                sx={{ display: 'flex', alignItems: 'center', mr: 3, cursor: 'pointer', zIndex: 20 }}
                                onClick={handleLogout}
                            >
                                <LogoutIcon sx={{ mr: 1 }} fontSize="small" /> Logout
                            </Link>
                        }
                    />
                    <CardContent sx={{ height: '100%', p: 0, mt: '-54.5px', position: 'relative' }}>
                        {showVerification && (
                            <Box
                                sx={{
                                    width: '50%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    transition: 'opacity 1.5s ease, transform 1.5s ease',
                                    opacity: verificationVisible ? 1 : 0,
                                    p: 5,
                                    zIndex: 10,
                                    backgroundColor: 'transparent',
                                    borderRadius: 2,
                                }}
                            >
                                {currentStep !== 'completed' && (
                                    <>
                                        {currentStep === 'email' && <VerifyEmail onComplete={handleStepComplete} />}
                                        {currentStep === 'billing' && <BillingInformation onComplete={handleStepComplete} />}
                                        {currentStep === 'identity' && <VerifyIdentity onComplete={handleStepComplete} />}
                                    </>
                                )}
                            </Box>
                        )}
                        <CoverComponent onAnimationComplete={handleAnimationComplete} />
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    );
};

export default WelcomePage;