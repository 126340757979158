import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid as MuiGrid, Typography, Button, Pagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProductCardComponent from 'components/ProductCard';
import noProductImage from 'assets/noProduct.webp';
import { resetFilters } from 'redux/auth/authSlice';
import { ProductGridProps, Product } from 'redux/types/ProductsTypes';

const ProductGrid: React.FC<
  ProductGridProps & {
    page: number;                 // Current page
    onPageChange: (page: number) => void; // Callback to set a new page
    itemsPerPage: number;
  }
> = ({ products, filter, searchQuery, page, onPageChange, itemsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ────────────────────────────────────────────────────────────────────────────
  // 1) Filter your products (if you still want a local filter in this component)
  // ────────────────────────────────────────────────────────────────────────────
  const filteredProducts = React.useMemo(() => {
    return products.filter((product: Product) => {
      if (filter.type && product.type !== filter.type) return false;
      if (filter.platform && product.platformId !== filter.platform) return false;
      if (filter.category && product.category !== filter.category) return false;
      if (!product.title.toLowerCase().includes(searchQuery.toLowerCase())) return false;
      return true;
    });
  }, [products, filter, searchQuery]);

  // ────────────────────────────────────────────────────────────────────────────
  // 2) Calculate how many pages we have total
  // ────────────────────────────────────────────────────────────────────────────
  const totalItems = filteredProducts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // ────────────────────────────────────────────────────────────────────────────
  // 3) Slice the array for the current page
  // ────────────────────────────────────────────────────────────────────────────
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  // ────────────────────────────────────────────────────────────────────────────
  // 4) Reset filters button logic
  // ────────────────────────────────────────────────────────────────────────────
  const handleResetFilters = () => {
    dispatch(resetFilters());
    navigate('/products');
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 5) Render
  // ────────────────────────────────────────────────────────────────────────────
  return (
    <>
      <MuiGrid container spacing={4}>
        {paginatedProducts.length > 0 ? (
          paginatedProducts.map((product, idx) => (
            <MuiGrid
              item
              xs={12}
              sm={6}
              md={4}
              key={`${product.productId}-${product._id}-${idx}`}
            >
              <ProductCardComponent
                title={product.title}
                type={product.type}
                price={product.price}
                category={product.category}
                platform={product.platformId}
                productId={product.productId}
                platformImageUrl={product.platformId}
                region={product.region}
                regionId={product.region}
                {...(product.editionName && { editionName: product.editionName })}
                {...(product.regionCurrency && { regionCurrency: product.regionCurrency })}
              />
            </MuiGrid>
          ))
        ) : (
          // No products after filtering
          <MuiGrid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="23px"
          >
            <img src={noProductImage} alt="No products" />
            <Typography variant="h3">{t('noProductsFound')}</Typography>
            <Typography variant="h6">{t('tryDifferentQuery')}</Typography>
            <Button variant="contained" color="primary" onClick={handleResetFilters}>
              {t('resetFilters')}
            </Button>
          </MuiGrid>
        )}
      </MuiGrid>

      {/* Pagination Component */}
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={page}
          boundaryCount={2}
          showFirstButton
          showLastButton
          onChange={(_, newPage) => onPageChange(newPage)}
          sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}
        />
      )}

      {/* Show total product count */}
      <Typography variant="body1" mt={3} textAlign="center">
        {t('total')} {filteredProducts.length} {t('products')}
      </Typography>
    </>
  );
};

export default ProductGrid;