import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchCategoriesQuery, useFetchPlatformsQuery, useFetchRegionsQuery, } from 'redux/RTK/optionsApi';
import { Box, Grid2 as Grid, Card, alpha, Typography, Button } from '@mui/material';
import ProductPriceTag from 'components/ProductPriceTag';
import FavoritesButton from 'components/FavoritesButton';
import AddToCartButton from 'components/Cart/AddToCartButton';
import PlatformLogo from 'components/icons/PlatformLogos';
import { getCategoryName, getProductImgLg, getCurrencySymbol, makeUrlSafe } from 'utils/getNames';

interface Platform {
    _id: string;
    editionName: string;
    category: string;
    platformId: string;
    region: string;
    price: number;
}

interface CreditCartProps {
    product: any;
    platformDetail: any;
    platformImageUrl: string;
    categoryId: string;
    platformName: string;
    pageEdition?: string;
    platforms: any[];
}

export const CreditCart = ({
    product,
    platformDetail,
    pageEdition,
    platformImageUrl,
    categoryId,
    platformName,
    platforms,
    ...rest
}: CreditCartProps) => {
    const { t } = useTranslation();
    const { data: allPlatforms = [] } = useFetchPlatformsQuery();
    const { data: regions = [] } = useFetchRegionsQuery();
    const { data: categories = [] } = useFetchCategoriesQuery();

    const [selectedPlatformDetails, setSelectedPlatformDetails] = useState<Platform | null>(null);

    useEffect(() => {
        const initialPlatform = product.platforms.find((p: Platform) => makeUrlSafe(p.editionName) === pageEdition);
        if (initialPlatform) {
            setSelectedPlatformDetails(initialPlatform);
        }
    }, [platformDetail._id, product.platforms]);

    const handlePlatformChange = (platformId: string) => {
        const platform = product.platforms.find((p: Platform) => p._id === platformId);
        if (platform) {
          setSelectedPlatformDetails(platform);
          const currentUrl = window.location.href;
          const lastSlashIndex = currentUrl.lastIndexOf('/');
          const newUrl = `${currentUrl.substring(0, lastSlashIndex + 1)}${makeUrlSafe(platform.editionName)}`;
          window.history.replaceState(null, '', newUrl);
        }
      };

    return (
        <Grid container spacing={6} sx={{ mb: { xs: 8, sm: 10 } }} justifyContent="center" {...rest}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Box
                    id="image"
                    sx={(theme) => ({
                        alignSelf: 'center',
                        height: { xs: 200, sm: 340 },
                        width: '100%',
                        backgroundImage: `url(${getProductImgLg(product._id)})`,
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        borderRadius: '21px',
                        boxShadow: '#000',
                        ...theme.applyStyles('light', {
                            boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`,
                        }),
                        ...theme.applyStyles('dark', {
                            boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        }),
                    })}
                />

            </Grid>

            <Grid size={{ xs: 12, md: 6 }} >
                <Card sx={{ display: 'flex', height: { xs: 'fit-content', sm: 340 }, overflow: 'visible', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
                    <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                        {product.title}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {getCategoryName(categoryId, categories)} - {platformName}
                    </Typography>

                    <Typography variant='subtitle2' my={1}>
                        {t('availableEditions')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                        {product.platforms
                            .filter((platform: Platform) => platform.region === platformDetail.region)
                            .map((platform: Platform, index: number) => {
                                const isSelected = selectedPlatformDetails?._id === platform._id;
                                const platformDetails = allPlatforms.find(p => p._id === platform.platformId);
                                return (
                                    <Button
                                        key={`${platform.platformId}-${index}`}
                                        variant={isSelected ? 'contained' : 'outlined'}
                                        disabled={isSelected}
                                        onClick={() => handlePlatformChange(platform._id)}
                                        sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                        startIcon={<PlatformLogo platformId={platform.platformId} sx={{ width: '21px', height: '21px' }} />}
                                    >
                                        {platform.editionName} {product.type === '3' ? getCurrencySymbol(platform.region, regions) : null}
                                    </Button>
                                );
                            })}
                    </Box>

                    {selectedPlatformDetails && (
                        <>
                            <ProductPriceTag
                                productTagPrice={selectedPlatformDetails.price.toString()}
                                variant='h4'
                            />
                            <Box sx={{ position: 'absolute', bottom: '-20px', zIndex: '100', gap: 2, display: 'flex' }}>
                                <FavoritesButton
                                    productId={product._id}
                                    platformGroupId={selectedPlatformDetails._id}
                                />
                                <AddToCartButton
                                    productId={product._id}
                                    platformGroupId={selectedPlatformDetails._id}
                                    quantity={1}
                                    price={selectedPlatformDetails.price}
                                />
                            </Box>
                        </>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};
