import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { enResources } from "./lang/en";
import { esResources } from "./lang/es";
import { deResources } from "./lang/de";
import { frResources } from "./lang/fr";
import { itResources } from "./lang/it";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next)
  .init({
    resources: {
      en: enResources,
      es: esResources,
      de: deResources,
      it: itResources,
      fr: frResources,
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;