// File: src/redux/services/regionHelpers.ts

import { AppDispatch, RootState } from '../store';
import { optionsApi } from 'redux/RTK/optionsApi';
import { productsApi } from 'redux/RTK/productsApi';
import { clearCart } from 'redux/reducers/cartReducer';
import { setCurrencyAndRegion, setUserIp } from './authSlice';

/**
 * Checks the response headers (x-currency, x-region, x-user-ip)
 * and compares them to your Redux application slice state.
 * If anything changed, we update the store and do an app-level cleanup.
 *
 * NOTE: It's now an async function so we can dispatch
 * the 'fetchCountryByCode' query and await its result.
 */
export async function handleRegionHeadersIfChanged(
  dispatch: AppDispatch,
  getState: () => RootState,
  headers: Record<string, string | undefined>
) {
  // 1) Extract from headers
  const newCurrency = headers['x-currency'];
  const newRegion = headers['x-region'];
  const newUserIp = headers['x-user-ip'];

  // 2) Compare with existing Redux state
  const { selectedCurrency, selectedRegion, userIp } = getState().auth;

  let changed = false;

  // We'll keep track of final updated currency/region/ip
  let finalCurrency = selectedCurrency;
  let finalRegion = selectedRegion;
  let finalIp = userIp;

  // Compare each:
  if (newCurrency && newCurrency !== selectedCurrency) {
    finalCurrency = newCurrency;
    changed = true;
  }
  if (newRegion && newRegion !== selectedRegion) {
    finalRegion = newRegion;
    changed = true;
  }
  if (newUserIp && newUserIp !== userIp) {
    finalIp = newUserIp;
    changed = true;
  }

  // 3) If changed, update application slice and optionally refresh
  if (changed) {
    // Update Redux with the new currency and region
    dispatch(setCurrencyAndRegion({ currency: finalCurrency, region: finalRegion }));

    if (finalIp) {
      dispatch(setUserIp(finalIp));
    }

    // Invalidate queries => triggers re-fetch for data that depends on region/currency
    dispatch(optionsApi.util.invalidateTags(['Settings']));
    dispatch(productsApi.util.invalidateTags(['Products']));

    // Clear the cart if you want to avoid cross-region items
    dispatch(clearCart());
  }
}