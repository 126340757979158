// File: NotificationsSocket.ts

import { io, Socket } from 'socket.io-client';

let notificationsSocket: Socket | null = null;

/**
 * Create and connect the /notifications socket.
 * @param onAuthError       Called if there's an auth error.
 * @param onOrderCompleted  Called when we receive "orderCompleted".
 */
export const initializeNotificationsSocket = (
  onAuthError: () => void,
  onOrderCompleted: (data: any) => void
) => {
  if (!notificationsSocket) {
    notificationsSocket = io('/notifications', {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: true,
    });

    notificationsSocket.on('connect', () => {
      console.log(`✅ Connected to /notifications (ID: ${notificationsSocket?.id})`);
    });

    notificationsSocket.on('authError', (data: any) => {
      console.error(`🔐 Notifications Auth Error: ${data.message}`);
      onAuthError();
    });

    notificationsSocket.on('disconnect', (reason: string) => {
      console.log(`🔌 Notifications Socket disconnected: ${reason}`);
    });

    notificationsSocket.on('error', (error: any) => {
      console.error('❌ Notifications Socket error:', error);
    });

    // Listen for orderCompleted and forward to the callback
    notificationsSocket.on('orderCompleted', (data: any) => {
      console.log('[notifications] orderCompleted =>', data);
      onOrderCompleted(data);
    });

    // Example custom event
    notificationsSocket.on('customEvent', (data: any) => {
      console.log('📩 Received customEvent on /notifications:', data);
    });
  }
};

export const connectNotificationsSocket = () => {
  notificationsSocket?.connect();
};

export const disconnectNotificationsSocket = () => {
  notificationsSocket?.disconnect();
  notificationsSocket = null;
};

export const getNotificationsSocket = (): Socket | null => {
  return notificationsSocket;
};