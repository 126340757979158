// File: src/SocketContext.tsx

import React, { createContext, useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';

import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';

import {
  initializeChatSocket,
  connectChatSocket,
  disconnectChatSocket,
  getChatSocket,
} from './ChatSocket';

import {
  initializeNotificationsSocket,
  connectNotificationsSocket,
  disconnectNotificationsSocket,
  getNotificationsSocket,
} from './NotificationsSocket';

import { logout } from 'redux/auth/authThunks';
import { userApi } from 'redux/RTK/userApi';

import CustomSnackbarContent from '../components/Snack';
import { getProductImgSm } from 'utils/getNames';

interface SocketContextProps {
  chatSocket: Socket | null;
  notificationsSocket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({
  chatSocket: null,
  notificationsSocket: null,
});

interface SocketProviderProps {
  children: React.ReactNode;
}

/**
 * Single provider for both /chat and /notifications sockets.
 * - /chat: Connect always, regardless of authentication (so guests can talk to AI).
 * - /notifications: Connect only if user is logged in.
 */
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    /**
     * Called if the server sends an "authError" event.
     * e.g. the token is invalid or user is unauthorized for that namespace.
     */
    const handleAuthError = () => {
      dispatch(logout()).catch((error: unknown) => {
        console.error('Logout failed:', error);
      });
    };

    /**
     * Called whenever "orderCompleted" arrives from the /notifications socket.
     */
    const handleOrderCompleted = (data: {
      reservationId: string;
      userId: string;
      productId: string;
      paymentId: string;
      details?: any;
    }) => {
      console.log('[NotificationListener] orderCompleted =>', data);

      // 1) Force a re-check of the invoice via RTK Query
      dispatch(userApi.util.invalidateTags(['Invoices']));

      // 2) Show a snack to the user
      enqueueSnackbar(
        <CustomSnackbarContent
          title={'Your key is ready!'}
          message={'You can now activate this product.'}
          icon={<img height="80" src={getProductImgSm(data.productId)} alt="product" />}
        />
      );
    };

    // ─────────────────────────────────────────────────────────────────────────
    //  CONNECT /chat ALWAYS (for both guests and logged-in users)
    // ─────────────────────────────────────────────────────────────────────────
    initializeChatSocket(handleAuthError);
    connectChatSocket();

    // ─────────────────────────────────────────────────────────────────────────
    //  CONNECT /notifications ONLY IF LOGGED IN
    // ─────────────────────────────────────────────────────────────────────────
    if (isLoggedIn) {
      initializeNotificationsSocket(handleAuthError, handleOrderCompleted);
      connectNotificationsSocket();
    } else {
      disconnectNotificationsSocket();
    }

    // Cleanup when unmounting or when isLoggedIn changes
    return () => {
      disconnectChatSocket();
      disconnectNotificationsSocket();
    };
  }, [isLoggedIn, dispatch, enqueueSnackbar]);

  const socketContextValue: SocketContextProps = {
    chatSocket: getChatSocket(),
    notificationsSocket: getNotificationsSocket(),
  };

  return (
    <SocketContext.Provider value={socketContextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext);
};