import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Card, Autocomplete, Link, Stack } from '@mui/material';
import { register, login } from 'redux/auth/authThunks';
import { useAddFavoriteMutation } from 'redux/RTK/userApi';

import { RootState, AppDispatch } from 'redux/store';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { useFetchProductByIdQuery } from 'redux/RTK/productsApi';

import { makeUrlSafe, getPlatformName, getTypeName } from 'utils/getNames';
import { useFetchTypesQuery, useFetchPlatformsQuery, useFetchCountryByCodeQuery } from 'redux/RTK/optionsApi';

const RegisterForm: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [addFavorite] = useAddFavoriteMutation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, error } = useSelector((state: RootState) => state.auth);
  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: types = [] } = useFetchTypesQuery();
  const selectedRegion = useSelector((state: RootState) => state.auth.selectedRegion);
  // 1) Grab the query params from the URL
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');
  const productIdParam = params.get('productId');
  const platformGroupIdParam = params.get('platformGroupId');
  const { data: countryData } = useFetchCountryByCodeQuery(selectedRegion, {
    skip: !selectedRegion, // skip if empty, optional
  });
  // 2) If a productId is present, we can fetch it with RTK Query
  //    We skip if there is no productId or user hasn't registered/logged in yet
  //    This snippet assumes we only want to fetch after we know the user has credentials.
  //    Alternatively, you can skip only if productId is null.
  const userIsLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const {
    data: fetchedProduct,
    isLoading: isProductLoading,
    isError: isProductError,
  } = useFetchProductByIdQuery(productIdParam ?? '', {
    skip: !productIdParam || !userIsLoggedIn,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      console.error('Passwords do not match');
      return;
    }

    const trimmedEmail = email.trim();

    // 3) Dispatch the register thunk
    dispatch(
      register({
        firstName,
        lastName,
        birthday,
        address,
        city,
        zip,
        country: selectedRegion,
        email: trimmedEmail,
        password,
        confirmPassword,
      })
    ).then(async (regAction: any) => {
      if (register.fulfilled.match(regAction)) {
        // 4) On success, immediately login
        dispatch(login({ email: trimmedEmail, password })).then(async (loginAction: any) => {
          if (login.fulfilled.match(loginAction)) {
            // User is now logged in, so userIsLoggedIn is true
            // => the RTK Query for "fetchedProduct" will start if productIdParam is present

            if (redirect === 'product' && productIdParam && platformGroupIdParam) {
              // 5) We want to add a favorite & navigate to product. Wait for `fetchedProduct`.
              //    If it's still loading or error, handle that gracefully:
              if (isProductLoading) {
                return;
              }
              if (isProductError || !fetchedProduct) {
                console.error('Error or no product found for productId:', productIdParam);
                navigate('/dashboard', { replace: true });
                return;
              }

              // 6) We have the product => find the platform
              const platform = fetchedProduct.platforms.find(
                (p: any) => p._id === platformGroupIdParam
              );
              if (!platform) {
                console.error('Platform not found in product data');
                navigate('/dashboard', { replace: true });
                return;
              }

              // 7) Build the final route or do the favorite
              const category = platform.category;
              const typeName = makeUrlSafe(getTypeName(fetchedProduct.type, types));
              const productName = makeUrlSafe(fetchedProduct.title);
              const productPlatform = getPlatformName(platform.platformId, platforms);

              // 8) Add to favorites via updateUserThunks
              await addFavorite({ productId: productIdParam, platformGroupId: platformGroupIdParam });

              // 9) Navigate to the product page
              navigate(`/${typeName}/${category}/${productPlatform}/${productName}`, {
                replace: true,
              });
            }
            // If the user was on /cart/login, go to payment
            else if (location.pathname === '/cart/login') {
              navigate('/cart/payment', { replace: true });
            }
            // otherwise go to /dashboard
            else {
              navigate('/dashboard');
            }
          } else {
            console.error(loginAction.payload);
          }
        });
      } else {
        console.error(regAction.payload);
      }
    });
  };

  return (
    <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">Sign Up</Typography>
      <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          label="First Name"
          name="firstName"
          autoComplete="given-name"
          autoFocus
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Last Name"
          name="lastName"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Birthday"
          name="birthday"
          type="date"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          label="Address"
          name="address"
          autoComplete="address-line1"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Stack direction="row" spacing={2} sx={{ width: '100%', mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            label="City"
            name="city"
            autoComplete="address-level2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            label="Zip Code"
            name="zip"
            autoComplete="postal-code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </Stack>
        <TextField
          margin="normal"
          disabled
          fullWidth
          size="small"
          label="Country"
          value={countryData?.name ?? selectedRegion}
        />
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          size="small"
          fullWidth
          name="password"
          label="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
          Sign Up
        </Button>
      </form>
      <Link component={RouterLink} to={{ pathname: "/login", search: location.search }}>Already have an account? Sign in</Link>
    </Card>
  );
};

export default RegisterForm;
