import { useState, useRef, useEffect } from 'react';
import { Grid2 as Grid, Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface ProdcutDescriptionProps {
    ProductDescription: string;
}

export const ProdcutDescription = ({ ProductDescription }: ProdcutDescriptionProps) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [ProductDescription]);

    const maxLinesHeight = 150;

    return (
        <Grid container mb={4}>
            <Grid size={{ xs: 12 }} mb={3}>
                <Typography variant="h3" sx={{ display: 'flex', flexDirection: 'row' }}>
                    {t('game')}&nbsp;
                    <Typography
                        component="span"
                        variant="h3"
                        sx={{ color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light') }}
                    >
                        {t('description')}&nbsp;
                    </Typography>
                </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
                <Box
                    ref={contentRef}
                    sx={{
                        overflow: 'hidden',
                        maxHeight: expanded ? `${contentHeight}px` : `${maxLinesHeight}px`,
                        transition: 'max-height 0.5s ease-in-out'
                    }}
                    dangerouslySetInnerHTML={{ __html: ProductDescription }}
                />
                <Box textAlign={'center'}>
                    {contentHeight > maxLinesHeight && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: 'auto', mt: 2 }}
                            onClick={() => setExpanded(!expanded)}
                            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                            {expanded ? t('showLess') : t('showMore')}
                        </Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
