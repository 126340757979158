// File: src/pages/ProductPage/Credit.tsx
// File: src/pages/ProductPage/ProductPage.tsx

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/store';
import { setLanguage } from 'redux/auth/authSlice';

import { Container } from '@mui/material';

import NoDataFound from 'components/NoDataFound';
import { ProductLoading } from './Components/ProductLoading';
import { ProductCover } from './Components/ProductCover';
import { CreditCart } from './Components/CreditCart';
import { CreditAbout } from './Components/CreditAbout';
import { ProductReviews } from './Components/ProductReviews';
import SEO from 'utils/SEO';
import { getProductImgLg } from 'utils/getNames';

// RTK Query hooks

import { useFetchProductWithTitleQuery, useFetchCreditDetailByIdQuery, } from 'redux/RTK/productsApi';
import { useFetchPlatformsQuery } from 'redux/RTK/optionsApi';

export default function CreditPage() {
  const { categoryId, platformName, productName, editionName } = useParams<{
    categoryId?: string;
    platformName?: string;
    productName?: string;
    editionName?: string;
  }>();

  const dispatch = useDispatch();

  // We'll read the language from store, or default to something
  const language = useSelector((state: RootState) => state.auth.language);

  // We'll load local userLanguage from localStorage if available
  useEffect(() => {
    const storedLanguage = localStorage.getItem('userLanguage');
    if (storedLanguage && storedLanguage !== language) {
      dispatch(setLanguage(storedLanguage));
    }
  }, [dispatch, language]);

  // 1) RTK Query: load the main product
  const {
    data: product,
    isLoading: isProductLoading,
    isError: isProductError,
  } = useFetchProductWithTitleQuery(productName ?? '', {
    skip: !productName, // Only fetch if productId is defined
  });

  // 2) RTK Query: load the credit detail
  const {
    data: creditDetail,
    isLoading: isCreditLoading,
    isError: isCreditError,
  } = useFetchCreditDetailByIdQuery(
    { productId: product?._id!, lang: language },
    { skip: !product?._id }
  );

  // 3) Other store slices for platform/type info
  const { data: platforms = [] } = useFetchPlatformsQuery();

  // Combine loading/error states
  const isLoading = isProductLoading || isCreditLoading;
  const isErrorState = isProductError || isCreditError;

  // 4) If either is loading, show a loading spinner:
  if (isLoading) {
    return <ProductLoading />;
  }

  // 5) If either query failed, or there's no product/credit data => NoDataFound
  if (isErrorState || !product || !creditDetail) { return <NoDataFound text="Product not found or details are missing." />; }

  // -- Platform logic (like matchedPlatform, categories, etc.)
  const decodedPlatformName = platformName?.replace(/-/g, ' ').toLowerCase();
  const matchedPlatform = platforms.find((p) => p.name.toLowerCase() === decodedPlatformName);
  if (!matchedPlatform) { return <NoDataFound text="Platform information not found." />; }

  const platformDetail = product.platforms.find((pf) => pf.platformId === matchedPlatform._id);
  if (!platformDetail) { return <NoDataFound text="Platform details not found." />; }

  return (
    <>
      {/* SEO Meta */}
      <SEO
        title={product.title}
        description={creditDetail.description}
        keywords="Bigswiss, video games, digital codes, gift cards, etc."
        image={getProductImgLg(product._id)}
      />

      {/* Cover Image */}
      <ProductCover productCoverImg={product._id} />

      <Container sx={{ mt: { xs: '-130px', sm: '-340px' }, gap: 6, pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 }, }}>
        {/* 1) The Cart/Buying Section */}
        <CreditCart
          product={product}
          pageEdition={editionName}
          platformDetail={platformDetail}
          categoryId={categoryId || ''}
          platformName={matchedPlatform.name || ''}
          platformImageUrl={matchedPlatform.imageUrl || '/img/defaultPlatform.svg'}
          platforms={platforms} // or filtered if needed
        />

        {/* 2) Description / Info */}
        <CreditAbout
          ProductType={product.type}
          ProductDescription={creditDetail.description || 'Description not available'}
        />

        {/* 3) If the credit detail has reviews, show them */}
        {creditDetail.reviews && creditDetail.reviews.length > 0 && (
          <ProductReviews reviews={creditDetail.reviews} />
        )}
      </Container>
    </>
  );
}