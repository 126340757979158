import React from 'react';
import { Box, Typography, ListItemAvatar, ListItemText, Stack, List, ListItem } from '@mui/material';
import { useFetchPlatformsQuery, useFetchRegionsQuery } from 'redux/RTK/optionsApi';
import { useFetchProductByIdQuery } from 'redux/RTK/productsApi';

import { Invoice } from 'redux/RTK/userApi';
import { getPlatformName, getRegionName } from 'utils/getNames';

interface InvoicePreviewProps {
    invoice: Invoice | null;
}



/**
 * Subcomponent that fetches a single product + platform details
 * for one invoice line item using RTK Query.
 */
const InvoiceItemLine: React.FC<{
    item: Invoice['items'][number];
    invoiceCurrency: string;
    divider?: boolean;
}> = ({ item, invoiceCurrency, divider }) => {
    // 1) RTK Query to fetch the product by ID
    const { data: productData, isLoading, isError } = useFetchProductByIdQuery(item.productId);

    // 2) Also fetch platform + region data for display
    const { data: platforms = [] } = useFetchPlatformsQuery();
    const { data: regions = [] } = useFetchRegionsQuery();

    // 3) If product is still loading or errored out, show fallback
    if (isLoading) {
        return (
            <ListItem divider={divider}>
                <ListItemText primary="Loading product info..." />
            </ListItem>
        );
    }
    if (isError || !productData) {
        return (
            <ListItem divider={divider}>
                <ListItemText primary={`Error or product not found (ID: ${item.productId})`} />
            </ListItem>
        );
    }

    // 4) Find the correct platform (by platformGroupId)
    const platformDetail = productData.platforms?.find(
        (p) => p._id === item.platformGroupId
    );

    // We'll display platform name + region if found
    let platformName = `PlatformGroupID: ${item.platformGroupId}`;
    let regionName = '';
    if (platformDetail) {
        platformName = getPlatformName(platformDetail.platformId, platforms);
        regionName = getRegionName(platformDetail.region, regions);
    }

    // 5) Build the UI for a single invoice line
    return (
        <ListItem divider={divider} secondaryAction={
            <Typography color="black">
                {(item.price * item.quantity).toFixed(2)} {invoiceCurrency}
            </Typography>
        }>
            <ListItemAvatar>
                <Typography color="black">
                    {item.quantity}
                </Typography>
            </ListItemAvatar>
            <ListItemText
                primary={productData.title}
                secondary={
                    platformDetail
                        ? `${platformName} - ${regionName}`
                        : `Platform Group ID: ${item.platformGroupId}`
                }
                primaryTypographyProps={{ style: { color: 'black' } }}
            />
        </ListItem>
    );
};

/**
 * Main InvoicePreview component
 */
const InvoicePreview: React.FC<InvoicePreviewProps> = ({ invoice }) => {
    if (!invoice) {
        return null;
    }

    return (
        <Box sx={{ width: '100%', p: 4, backgroundColor: '#ffffff', borderRadius: 2 }}>
            <Typography variant="h5" color="black" textAlign={'center'} width={'100%'} gutterBottom>
                Invoice from Bigswiss
            </Typography>
            <Typography variant="body2" color="black" textAlign={'center'} width={'100%'} sx={{ mb: 3 }}>
                Invoice #{invoice.invoiceNumber}
            </Typography>

            <Stack spacing={2} direction={'row'} justifyContent={'space-between'} sx={{ mb: 3 }}>
                <Stack direction={'column'}>
                    <Typography variant="button" color="black">
                        Date paid
                    </Typography>
                    <Typography variant="body1" style={{ color: '#32325d' }}>
                        {new Date(invoice.createdAt).toLocaleString()}
                    </Typography>
                </Stack>

                <Stack direction={'column'}>
                    <Typography variant="button" textAlign={'left'} sx={{ color: 'black' }}>
                        Payment method
                    </Typography>
                    <Stack direction={'row'} spacing={0} alignItems={'center'}>
                        <Typography
                            variant="body1"
                            textAlign={'left'}
                            style={{ color: '#32325d' }}
                            sx={{ paddingLeft: 4 }}
                        >
                            {invoice.paymentMethod}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction={'column'}>
                    <Typography variant="button" style={{ color: '#32325d', fontWeight: 1000 }}>
                        Amount paid
                    </Typography>
                    <Typography variant="body1" style={{ color: '#32325d', fontWeight: 1000 }}>
                        {invoice.total.toFixed(2)} {invoice.currency}
                    </Typography>
                </Stack>
            </Stack>

            <Typography variant='button' sx={{ color: 'black' }}>
                Summary
            </Typography>

            <List sx={{ marginTop: 3, backgroundColor: '#f6f9fc', borderRadius: '8px', padding: '16px' }}>
                {invoice.items.map((item, index) => (
                    <InvoiceItemLine
                        key={index}
                        item={item}
                        invoiceCurrency={invoice.currency}
                        divider={index < invoice.items.length - 1}
                    />
                ))}
            </List>

            <Typography variant="caption" color="textSecondary" sx={{ mt: 4, display: 'block' }}>
                If you have any questions, contact us at{' '}
                <a href="mailto:support@bigswiss.ch" style={{ color: '#635bff' }}>
                    support@bigswiss.ch
                </a>
                .
            </Typography>
        </Box>
    );
};

export default InvoicePreview;