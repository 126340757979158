import { useTranslation } from 'react-i18next';
import { Grid2 as Grid, Typography } from '@mui/material';
import SiteTitle from 'components/SiteTitle';
import { useFetchTypesQuery } from 'redux/RTK/optionsApi';
import { getTypeName } from 'utils/getNames';
interface ProductAboutProps {
    ProductDescription: string;
    ProductType: string;
    [key: string]: any;
}

export const CreditAbout = ({
    ProductDescription,
    ProductType
}: ProductAboutProps) => {
    const { t } = useTranslation();
      const { data: types = [] } = useFetchTypesQuery();

    return (
        <Grid container mb={4}>
            <Grid size={12} mb={3}>
                <SiteTitle firstTitle={t('about')} secondTitle={getTypeName(ProductType, types)} variant="h4" color="primary" />
            </Grid>
            <Grid container spacing={6}>
                <Grid size={12}>
                    <Typography color="text.secondary">
                        {ProductDescription}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CreditAbout;
