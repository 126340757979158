import axiosDefault from 'axios';
import axiosGetToken from './axiosGetToken';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { User } from './authTypes';

interface PasswordChangePayload {
  currentPassword: string;
  newPassword: string;
}

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { getState, rejectWithValue }) => {
    const { user } = (getState() as RootState).auth;
    if (!user || !user._id) {
      return rejectWithValue('User not authenticated');
    }

    try {
      const response = await axiosGetToken.get<{ user: any }>(`/users/${user._id}`);
      return response.data.user;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Failed to fetch user data due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred while fetching user data');
    }
  }
);



export const updateUserSettings = createAsyncThunk(
  'auth/updateUserSettings',
  async (updatedFields: Partial<User>, { getState, dispatch, rejectWithValue }) => { // Use Partial<User> for the fields you want to update
    const { user } = (getState() as RootState).auth;

    if (!user || !user._id) {
      return rejectWithValue('User not authenticated');
    }

    try {
      const response = await axiosGetToken.patch<{ success: boolean; user: User }>('/users/settings', {
        userId: user._id,
        ...updatedFields // Only the fields that need to be updated
      });
      if (response.data.success) {
        return response.data.user;
      } else {
        return rejectWithValue('Failed to update user settings');
      }
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Failed to update settings due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred while updating settings');
    }
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async ({ currentPassword, newPassword }: PasswordChangePayload, { rejectWithValue }) => {
    try {
      const response = await axiosGetToken.post('/users/update-password', { currentPassword, newPassword });
      return response.data;
    } catch (error: unknown) {
      if (axiosDefault.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.error || error.response.data.errors?.[0]?.msg || 'Password update failed due to server error';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred during password update');
    }
  }
);
