import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem, CartState } from '../types/cartTypes';

export const maxProductAllowed = 3;

const initialState: CartState = {
    items: [],
    total: 0,
    status: 'idle',
    error: null
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<CartItem>) => {
            const existingItemIndex = state.items.findIndex(item => item.productId === action.payload.productId && item.platformGroupId === action.payload.platformGroupId);
            
            if (existingItemIndex !== -1) {
                const existingItem = state.items[existingItemIndex];
                const newQuantity = existingItem.quantity + action.payload.quantity;

                if (newQuantity > maxProductAllowed) {
                    existingItem.quantity = maxProductAllowed; 
                } else {
                    existingItem.quantity = newQuantity;
                }
            } else {
                const newItem = { ...action.payload, quantity: Math.min(action.payload.quantity, maxProductAllowed) };
                state.items.push(newItem);
            }

            state.total = state.items.reduce((sum, item) => sum + item.price * item.quantity, 0);
        },
        
        updateItemQuantity: (state, action: PayloadAction<{ id: string, quantity: number }>) => {
            const index = state.items.findIndex(item => item.id === action.payload.id);
            
            if (index !== -1) {
                const item = state.items[index];
                const newQuantity = Math.min(action.payload.quantity, maxProductAllowed);
                
                const difference = newQuantity - item.quantity;
                item.quantity = newQuantity;
                state.total += item.price * difference;
            }
        },

        removeItem: (state, action: PayloadAction<{ id: string }>) => {
            const index = state.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                if (state.items[index].quantity > 1) {
                    state.items[index].quantity -= 1;
                    state.total -= state.items[index].price;
                }
            }
        },

        deleteItem: (state, action: PayloadAction<{ id: string }>) => {
            const index = state.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.total -= state.items[index].price * state.items[index].quantity;
                state.items.splice(index, 1);
            }
        },

        clearCart: (state) => {
            state.items = [];
            state.total = 0;
        }
    },

});

export const { addItem, removeItem, deleteItem, updateItemQuantity, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
