// src/components/HelpMenu.tsx
import React, { forwardRef, useState } from 'react';
import { List, ListItemButton, ListItemText, Typography, Box, TextField } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TopicData } from './topics/topicsData';
import { useTranslation } from 'react-i18next';

interface HelpMenuProps {
  topics: TopicData[];
  onTopicSelect: (topicTitle: string) => void;
}

const HelpMenu = forwardRef<HTMLDivElement, HelpMenuProps>(({ topics, onTopicSelect }, ref) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredTopics = topics.filter((topic) => {
    const query = searchQuery.trim().toLowerCase();
    const searchWords = query.split(/\s+/); // Split the query into words

    // Combine all fields to search into a single string
    const fieldsToSearch = [
      topic.topic,
      topic.content,
      topic.category,
      ...(topic.steps ? topic.steps.map((step) => `${step.title} ${step.description}`) : []),
    ]
      .join(' ')
      .toLowerCase();

    // Check if all search words are present in the fields
    return searchWords.every((word) => fieldsToSearch.includes(word));
  });

  return (
    <Box ref={ref} sx={{ width: '100%' }}>
      <Typography variant="h6" textAlign={'center'}>
        {t('allHelpTopics')}
      </Typography>
      <TextField
        fullWidth
        placeholder={t('searchTopics')}
        variant="outlined"
        size="small"
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <List>
        {filteredTopics.length > 0 ? (
          filteredTopics.map((topic) => (
            <ListItemButton key={topic.topic} onClick={() => onTopicSelect(topic.topic)}>
              <ListItemText primary={topic.topic} />
              <ChevronRightIcon />
            </ListItemButton>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary" textAlign="center">
            {t('noTopicsFound')}
          </Typography>
        )}
      </List>
    </Box>
  );
});

export default HelpMenu;
