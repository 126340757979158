import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetchPlatformsQuery, useFetchTypesQuery } from 'redux/RTK/optionsApi';

import { Box, Grid2 as Grid, Card, alpha, Typography, Button, MenuItem, TextField, SelectChangeEvent } from '@mui/material';

import PlatformLogo from 'components/icons/PlatformLogos';
import ProductPriceTag from 'components/ProductPriceTag';
import FavoritesButton from 'components/FavoritesButton';
import AddToCartButton from 'components/Cart/AddToCartButton';
import { makeUrlSafe, getPlatformName, getProductImgLg } from 'utils/getNames';
import { Editions } from 'redux/types/ProductDetailTypes';

interface Platform {
    _id: string;
    category: string;
    platformId: string;
    price: number;
}

interface ProductCartProps {
    product: any;
    productDetail: any;
    platformDetail: any;
    platformGroupId: string;
    categoryId: string;
    selectedPlatform: string;
    platformName: string;
    ProductPrice: number;
    [key: string]: any;
}

export const ProductCart = ({
    product,
    productDetail,
    platformDetail,
    categoryId,
    ProductPrice,
    selectedPlatform,
    platformGroupId,
    platformName,
    ...rest
}: ProductCartProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: platforms = [] } = useFetchPlatformsQuery();
    const { data: types = [] } = useFetchTypesQuery();

    const getTypeName = (typeId: string): string => {
        const foundType = types.find((t) => t._id === typeId);
        return foundType ? foundType.name : 'Unknown Type';
    };
    // ---------------------------
    // Changing entire platform
    // ---------------------------
    const handlePlatformChange = (platformId: string) => {
        const platform = product.platforms.find((p: Platform) => p.platformId === platformId);
        if (!platform) {
            console.error('Platform not found');
            return;
        }
        const platformName = getPlatformName(platformId, platforms);

        // Remove the extra curly brace after `product.title`
        const newPath = `/${makeUrlSafe(getTypeName(product.type))}/` +
            `${platform.category}/${makeUrlSafe(platformName)}/` +
            `${makeUrlSafe(product.title)}`;

        navigate(newPath);
    };

    // ---------------------------
    // Changing edition
    // ---------------------------
    const handleEditionChange = (event: SelectChangeEvent<string>) => {
        const editionId = event.target.value;
        const selectedEdition = productDetail.editions.find(
            (edition: Editions) => edition._id === editionId
        );
        if (!selectedEdition) {
            console.error('Selected edition not found');
            return;
        }

        const platformName = getPlatformName(selectedEdition.platformId, platforms);
        const category = selectedEdition.category;

        const newPath = `/${makeUrlSafe(getTypeName(product.type))}/` +
            `${category}/${makeUrlSafe(platformName)}/` +
            `${makeUrlSafe(selectedEdition.name)}`;

        navigate(newPath);
    };

    const hasEditions = productDetail.editions && productDetail.editions.length > 0;

    return (
        <Grid container spacing={6} sx={{ mb: { xs: 8, sm: 10 } }} justifyContent="center" {...rest}>

            <Grid size={{ xs: 12, md: 6 }}>
                <Box
                    id="image"
                    sx={(theme) => ({
                        alignSelf: 'center',
                        height: { xs: 200, sm: 340 },
                        width: '100%',
                        backgroundImage: `url(${getProductImgLg(product._id)})`,
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        borderRadius: '21px',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`
                    })}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
                <Card
                    sx={{
                        display: 'flex',
                        height: { xs: 'fit-content', sm: 340 },
                        overflow: 'visible',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        p: 4
                    }}
                >
                    <Typography variant="h5" sx={{ textTransform: 'capitalize', textAlign: 'center', mb: 2 }}>
                        {product.title}
                    </Typography>

                    {hasEditions && (
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Select Edition"
                                value={product._id}
                                onChange={(event) => handleEditionChange(event as SelectChangeEvent<string>)}
                                name="edition"
                                slotProps={{
                                    htmlInput: {
                                        'aria-label': 'Select edition'
                                    }
                                }}
                            >
                                {productDetail.editions.map((edition: Editions) => (
                                    <MenuItem key={edition._id} value={edition._id}>
                                        {edition.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    )}

                    {product.platforms.length > 1 && (
                        <Typography variant="subtitle2" my={1}>
                            {t('availablePlatforms')}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            mb: 2,
                        }}
                    >
                        {product.platforms.map((platform: Platform) => {
                            const isSelected = selectedPlatform === platform.platformId;
                            const platformLabel =
                                platforms.find((p) => p._id === platform.platformId)?.name ||
                                'Unknown Platform';

                            return (
                                <Button
                                    key={platform.platformId}
                                    variant={isSelected ? 'contained' : 'outlined'}
                                    disabled={isSelected}
                                    onClick={() => handlePlatformChange(platform.platformId)}
                                    sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                    startIcon={
                                        <PlatformLogo platformId={platform.platformId} sx={{ width: '21px', height: '21px' }} />
                                    }
                                >
                                    {platformLabel}
                                </Button>
                            );
                        })}
                    </Box>

                    {platformDetail.isActive === false ? (
                        <Typography color="error" variant="h5" mt={2}>
                            {t('outOfStock')}
                        </Typography>
                    ) : (<ProductPriceTag productTagPrice={ProductPrice.toString()} variant="h4" />)}

                    <Box sx={{ position: 'absolute', bottom: '-20px', zIndex: '100', gap: 2, display: 'flex' }} >
                        <FavoritesButton productId={product._id} platformGroupId={platformGroupId} />
                        {platformDetail.isActive !== false && (
                            <AddToCartButton productId={product._id} platformGroupId={platformGroupId} quantity={1} price={ProductPrice} />
                        )}
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
};