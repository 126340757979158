// src/hooks/useIdentitySocket.ts
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { setNewVerificationAction, setVerificationStatus } from 'redux/auth/authSlice';
import { setClientSecret, setPaymentId } from 'redux/payment/paymentReducer';

export function useIdentitySocket() {
    const dispatch = useDispatch<AppDispatch>();

    // Pull existing verification data from Redux
    const verificationSecret = useSelector((state: RootState) => state.auth.verificationSecret);
    const verificationStatus = useSelector((state: RootState) => state.auth.verificationStatus);

    const [paymentStatus, setPaymentStatus] = useState<string>('');
    // Track the current identity verification status locally

    // Keep a reference to the socket so we don’t recreate it on every render
    const [identitySocket, setIdentitySocket] = useState<Socket | null>(null);

    // 1) Set up the /identity socket once on mount
    useEffect(() => {
        const socket = io('/identity', {
            withCredentials: true,
            path: '/socket.io',
            transports: ['websocket', 'polling'],
            autoConnect: false
        });

        // On connect, optionally request the initial identity status
        socket.on('connect', () => {
            console.log(`✅ Connected to /identity: ID=${socket.id}`);
            // e.g. socket.emit('requestIdentityStatus'); // if the backend has such an event
        });

        // Example: the server might emit "identityStatusUpdate" with { status, verificationSecret, verificationError }
        const handleIdentityStatusUpdate = (data: {
            status?: string;
            verificationSecret?: string;
            verificationError?: string;
            paymentStatus?: string;
            paymentId?: string;
            clientSecret?: string;
        }) => {
            console.log('Received identityStatusUpdate:', data);


            if (data.paymentStatus) {
                setPaymentStatus(data.paymentStatus);
            }
            if (data.paymentId && data.clientSecret) {
                dispatch(setPaymentId(data.paymentId));
                dispatch(setClientSecret(data.clientSecret));
            }
            // Update local status if provided
            if (data.status) {
                dispatch(setVerificationStatus(data.status));
            }
            // If the backend sends updated secret or error, sync to Redux
            if (data.verificationSecret !== undefined || data.verificationError !== undefined) {
                dispatch(
                    setNewVerificationAction({
                        verificationSecret: data.verificationSecret ?? null,
                        verificationError: data.verificationError ?? null
                    })
                );
            }
        };

        // Example: the server might emit an "identityError" event
        const handleIdentityError = (err: { message: string }) => {
            console.error('Received identityError:', err);
            // We can set local status to "failed" or something similar
            setVerificationStatus('verification_failed');
        };

        // Attach the listeners
        socket.on('verificationStatus', handleIdentityStatusUpdate);
        socket.on('identityError', handleIdentityError);

        // Optionally handle other socket events...
        socket.on('disconnect', (reason) => {
            console.log(`🔌 Identity Socket disconnected: ${reason}`);
        });
        socket.on('error', (error: any) => {
            console.error('❌ Identity Socket error:', error);
        });

        // Connect the socket
        socket.connect();

        // Store in local state so we can reference it later if needed
        setIdentitySocket(socket);

        // Cleanup on unmount
        return () => {
            console.log('Cleaning up /identity socket...');
            socket.off('verificationStatus', handleIdentityStatusUpdate);
            socket.off('identityError', handleIdentityError);
            socket.disconnect();
        };
    }, [dispatch]);

    // 2) If we already have a verificationSecret in Redux, update local status to 'pending_verification' once
    //    But do it in a useEffect so it doesn't trigger re-renders in the body.
    useEffect(() => {
        if (verificationSecret && verificationStatus === 'processing_verification') {
            setVerificationStatus('pending_verification');
        }
    }, [verificationSecret, verificationStatus]);

    // 3) Return whatever your component needs to know
    return {
        paymentStatus
    };
}