// src/pages/PurchaseDetailPage.tsx
import React, { useRef } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyIcon from '@mui/icons-material/Key';

import { useFetchInvoiceDetailQuery } from 'redux/RTK/userApi';
import InvoicePreview from './InvoicePreview';
import ProductKeys from 'pages/CartPage/Components/ProductKeys';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator';

const PurchaseDetailPage: React.FC = () => {
    // We'll treat "invoiceId" in the URL as the invoiceNumber
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const { t } = useTranslation();

    // 1) Fetch the invoice detail from RTK Query
    const { data: invoice, isLoading, error } = useFetchInvoiceDetailQuery(
        { invoiceNumber: invoiceId! }, // we assume invoiceId is not null
        { skip: !invoiceId, }
    );

    // 2) Local states
    const productKeysRef = useRef<HTMLDivElement>(null);

    // 3) Scroll to product keys
    const handleViewKeysClick = () => {
        if (productKeysRef.current) {
            const offsetTop =
                productKeysRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };

    // 6) Loading / error states
    if (isLoading) {
        return (
            <Box>
                <Typography>{t('loadingInvoice') || 'Loading invoice...'}</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box>
                <Typography color="error">
                    {t('failedToLoadInvoice') || 'Failed to load invoice'}
                </Typography>
            </Box>
        );
    }

    if (!invoice) {
        return (
            <Box>
                <Typography variant="h6" color="error">
                    {t('invoiceNotFound') || 'Invoice not found'}
                </Typography>
            </Box>
        );
    }

    // 7) Sort items so that bundles appear at the bottom
    const sortedItems = [...invoice.items].sort((a: any, b: any) => {
        if (a.isBundle && !b.isBundle) return 1;
        if (!a.isBundle && b.isBundle) return -1;
        return 0;
    });

    return (
        <Stack alignItems="center">
            <Typography variant="h2">
                {t('invoice')} {invoice.invoiceNumber}
            </Typography>

            <Stack direction="row" alignItems="center" spacing={4} sx={{ my: 5 }}>
                <Button
                    sx={{ mt: 5 }}
                    startIcon={<ArrowBackRoundedIcon />}
                    variant="contained"
                    color="secondary"
                    component={RouterLink}
                    to="/dashboard/purchases"
                >
                    {t('back')}
                </Button>

                <InvoicePdfGenerator invoice={invoice} title={t('downloadInvoice')} />

                <Button
                    sx={{ mt: 5 }}
                    startIcon={<KeyIcon />}
                    variant="contained"
                    color="primary"
                    onClick={handleViewKeysClick}
                >
                    {t('viewKeys')}
                </Button>
            </Stack>

            <InvoicePreview invoice={invoice} />

            <Typography ref={productKeysRef} variant="h2" sx={{ my: 5 }}>
                {t('productKey')}
            </Typography>

            {/* 8) Render each item’s keys or placeholders */}
            {sortedItems.map((item: any, index: number) => {
                if (item.keys && item.keys.length > 0) {
                    return item.keys.map((keyObj: any, keyIndex: number) => (
                        <ProductKeys
                            key={`${index}-${keyIndex}`}
                            invoiceId={invoice._id}
                            productId={item.productId}
                            isBundle={item.isBundle}
                            platformGroupId={item.platformGroupId}
                            keyCode={keyObj.key}
                            keyIsRevealed={keyObj.isRevealed}
                            isGifted={keyObj.isGifted}
                            hideImage={true}
                            productKeysRef={productKeysRef}
                        />
                    ));
                } else {
                    // If no keys array, we create placeholders for item.quantity
                    return Array.from({ length: item.quantity }).map((_, i) => (
                        <ProductKeys
                            key={`${index}-${i}`}
                            invoiceId={invoice._id}
                            productId={item.productId}
                            isBundle={item.isBundle}
                            platformGroupId={item.platformGroupId}
                            keyCode={undefined}
                            hideImage={true}
                            productKeysRef={productKeysRef}
                        />
                    ));
                }
            })}

        </Stack>
    );
};

export default PurchaseDetailPage;