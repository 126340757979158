import React from 'react';
import { Typography, Button, Container, CircularProgress } from '@mui/material';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

interface VerificationStatusProps {
    paymentStatus?: string;
    fallbackMessage?: string;
}

const stripeKey = (import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '').trim();
const stripePromise: Promise<Stripe | null> = loadStripe(stripeKey);

const VerificationStatus: React.FC<VerificationStatusProps> = ({
    paymentStatus,
    fallbackMessage = "Please verify your identity to proceed with the payment.",  // Default message
}) => {
    const verificationSecret = useSelector((state: RootState) => state.auth.verificationSecret);
    const verificationError = useSelector((state: RootState) => state.auth.verificationError);

    const errorMessages: Record<string, string> = {
        'document_expired': 'The document you uploaded has expired. Please upload a valid document.',
        'document_type_not_supported': 'The document type you provided is not supported. Please upload a valid ID type.',
        'document_unverified_other': 'We were unable to verify the document. Please try again with a clearer document.',
        'selfie_unverified_other': 'Your selfie could not be verified. Please ensure the image is clear and try again.',
        'consent_declined': 'You declined consent for identity verification. Please grant consent to proceed.',
        'id_number_mismatch': 'The ID number does not match. Please try again with the correct information.',
        'document_notmatching': 'The name on the document does not match with your profile data. Please wait while support reviews your profile.',
        'max_retries_exceeded': 'Your profile is under reveiw. Please contact support for further assistance.',
        'manual_review': 'Your profile is under reveiw. Please contact support for further assistance.',
        'verification_document_fraudulent': 'Your profile is under reveiw. Please contact support for further assistance.',
        'document_manipulation': 'Your profile is under reveiw. Please contact support for further assistance.',
        'verification_document_manipulated': 'Your profile is under reveiw. Please contact support for further assistance.',
    };

    // Check if there is an error code and get the corresponding message, otherwise use fallbackMessage
    const errorMessage = verificationError && errorMessages[verificationError] ? errorMessages[verificationError] : fallbackMessage;

    // Function to open the Stripe verification modal
    const openVerificationModal = async () => {
        const stripe = await stripePromise;

        if (!stripe) {
            console.error('Stripe failed to load.');
            return;
        }

        if (!verificationSecret) {
            console.error('Client secret is missing. Attempting to restart verification session...');
            // Optionally, trigger a backend request to start a new verification session and update the state
            // Here you might dispatch an action to your store to get a fresh verificationSecret
            return;
        }

        // Proceed with the modal opening
        stripe.verifyIdentity(verificationSecret)
            .catch((err) => {
                console.error('Verification failed:', err.message);
            });
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant='h4' textAlign={'center'} sx={{ fontWeight: 1000 }}>
                Verify Identity
            </Typography>

            <img src="/img/identity_verification.webp" height={250} alt="Identity Verification" />

            {paymentStatus !== 'processing_verification' ? (
                <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
                    {errorMessage}
                </Typography>
            ) : (
                <>
                    <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
                        Processing your identity verification. Please wait...
                    </Typography>
                </>
            )}

            {paymentStatus === 'processing_verification' ? (
                <CircularProgress />
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={openVerificationModal}
                    disabled={verificationError === 'max_retries_exceeded' || verificationError === 'document_notmatching'}
                >
                    Verify Identity
                </Button>
            )}
        </Container>
    );
};

export default VerificationStatus;
