// File: src/components/MessageBubble.tsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Badge, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

// Define the list of user avatars
const avatars = ['/img/avatar-1.webp', '/img/avatar-2.webp'];

// Function to get a random avatar from the avatars array
const getRandomAvatar = () => {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
};

// Module-level variable to store the user's avatar URL
let userAvatarUrl = getRandomAvatar();

interface MessageBubbleProps {
    message: {
        text: string;
        sender: 'user' | 'AI' | 'support';
        createdAt: string;
    };
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message }) => {
    const isUser = message.sender === 'user';
    const user = useSelector((state: RootState) => state.auth.user);

    // State to manage the avatar URL (only for user messages)
    const [avatarUrl, setAvatarUrl] = useState(isUser ? userAvatarUrl : '');

    // Handler for image load errors to select a new random avatar
    const handleImageError = () => {
        if (isUser) {
            const newAvatar = getRandomAvatar();
            setAvatarUrl(newAvatar);
            userAvatarUrl = newAvatar; // Update the module-level variable for consistency
        }
    };

    // ─────────────────────────────────────────────────────────────────────────
    // Typewriter Animation for AI
    // ─────────────────────────────────────────────────────────────────────────
    const [displayedText, setDisplayedText] = useState(
        isUser || message.text === 'typing...' ? message.text : ''
    );

    useEffect(() => {
        // If it's AI and not "typing...", animate
        if (message.sender === 'AI' && message.text !== 'typing...') {
            let i = 0;
            const interval = setInterval(() => {
                i++;
                setDisplayedText(message.text.slice(0, i));
                if (i >= message.text.length) {
                    clearInterval(interval);
                }
            }, 25); // Speed of typing, in ms
            return () => clearInterval(interval);
        } else {
            // If user message or "typing...", show full text immediately
            setDisplayedText(message.text);
        }
    }, [message.sender, message.text]);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));


    // Decide bubble colors
    const bubbleBgColor = isUser ? 'grey.300' : 'primary.main';
    const bubbleTextColor = isUser ? 'black' : 'primary.contrastText';
    const bubbleRadius = isUser
        ? '15px 0 15px 15px'
        : '0 15px 15px 15px';

    // Whether to show skeleton wave
    const showSkeleton = (message.sender === 'AI' && message.text === 'typing...');

    return (
        <Box
            display="flex"
            justifyContent={isUser ? 'flex-end' : 'flex-start'}
            alignItems="flex-start"
            mb={1}
            mt={3}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: isUser ? 'flex-end' : 'flex-start',
                }}
            >
                {/* AI Avatar on the Left */}
                {!isUser && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            mb: 1,
                        }}
                    >
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                        >
                            <Avatar alt="Biggie" src="/img/72.png" />
                        </StyledBadge>
                        <Box>
                            <Typography variant="body2">Biggie AI Assistant</Typography>
                            <Typography variant="caption" color="textSecondary">
                                {new Date(message.createdAt).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* User Avatar on the Right */}
                {isUser && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            mb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="body2">
                                {user ? `${user.firstName}` : 'Guest User'}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {new Date(message.createdAt).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </Typography>
                        </Box>
                        <Avatar
                            sx={{
                                bgcolor: 'primary.dark',
                                mb: 1,
                                width: 40,
                                height: 40,
                            }}
                            src={avatarUrl}
                            onError={handleImageError}
                        >
                            U
                        </Avatar>
                    </Box>
                )}

                {/* Bubble */}
                <Box
                    sx={{
                        position: 'relative', // needed for absolute skeleton overlay
                        backgroundColor: showSkeleton ? 'transparent' : bubbleBgColor,
                        color: showSkeleton ? 'transparent' : bubbleTextColor,
                        padding: '10px 15px',
                        borderRadius: bubbleRadius,
                        boxShadow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 80, // just to ensure skeleton has some space
                    }}
                >
                    {/* If "typing..." => show skeleton wave overlay */}
                    {showSkeleton && (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: bubbleRadius,
                                zIndex: 0,
                            }}
                        />
                    )}

                    <Typography
                        variant="body1"
                        sx={{
                            whiteSpace: 'pre-line',
                            position: 'relative',
                            zIndex: 2,
                        }}
                    >
                        {displayedText}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MessageBubble;