// File: src/ChatSocket.ts
import { io, Socket } from 'socket.io-client';

let chatSocket: Socket | null = null;

export const initializeChatSocket = (onAuthError: () => void) => {
  if (!chatSocket) {
    chatSocket = io('/chat', {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: true, // connect immediately
    });

    chatSocket.on('connect', () => {
      console.log(`✅ Connected to /chat namespace with socket ID: ${chatSocket?.id}`);
    });

    chatSocket.on('authError', (data: any) => {
      console.error(`🔐 Chat Socket Authentication Error: ${data.message}`);
      onAuthError();
    });

    chatSocket.on('disconnect', (reason: string) => {
      console.log(`🔌 Chat Socket disconnected: ${reason}`);
    });

    chatSocket.on('error', (error: any) => {
      console.error(`❌ Chat Socket error:`, error);
    });
  }
};

export const connectChatSocket = () => {
  chatSocket?.connect();
};

export const disconnectChatSocket = () => {
  chatSocket?.disconnect();
};

export const getChatSocket = (): Socket | null => {
  return chatSocket;
};