import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import ProductCard from 'components/ProductCard';
import SiteTitle from 'components/SiteTitle';
import { useFetchSuggestedProductsQuery } from 'redux/RTK/productsApi';

interface ProductSuggestionsProps {
  product: any;
  platformDetail: any;
}

export const ProductSuggestions: React.FC<ProductSuggestionsProps> = ({
  product,
  platformDetail,
}) => {
  // 1) Filter out the current platform
  const filteredPlatforms = product.platforms?.filter(
    (p: any) => p._id !== platformDetail._id
  ) || [];

  // 2) We'll fetch suggested products from the backend
  //    only if we have fewer than 6 from the "platforms" suggestion
  //    because you said total = 6
  const needCount = 6 - filteredPlatforms.length;
  const shouldFetch = needCount > 0;

  const { data: suggested = [], isLoading } = useFetchSuggestedProductsQuery(
    shouldFetch ? product._id : '', 
    {
      skip: !shouldFetch, // skip if we don't need more
    }
  );

  // 'suggested' is an array of platform-level objects (the final shape).
  // We'll slice up to "needCount" if there's more.
  const usedSuggested = suggested.slice(0, needCount);

  // 3) Combine: 'filteredPlatforms' (some from the current product)
  //    plus 'usedSuggested' from server
  const finalSuggestions = [
    ...filteredPlatforms,
    ...usedSuggested
  ];
  
  if (finalSuggestions.length === 0) {
    return null;
  }

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid size={12} sx={{ mb: 3 }}>
        <SiteTitle
          firstTitle="Suggested"
          secondTitle="Products"
          variant="h3"
          color="primary"
        />
      </Grid>

      <Grid size={12}>
        <Grid container spacing={5}>
          {finalSuggestions.map((plat: any) => (
            <Grid key={plat._id} size={{ xs: 12, sm: 6, md: 4 }}>
              <ProductCard
                withFav={true}
                productId={plat.productId}
                title={plat.title}
                type={plat.type}
                productPlatformId={plat._id}
                regionId={plat.region}
                category={plat.category}
                platform={plat.platformId}
                price={plat.price}
                editionName={plat.editionName}
              />
            </Grid>
          ))}
        </Grid>

        {isLoading && (
          <Typography color="text.secondary">Loading extra suggestions...</Typography>
        )}
      </Grid>
    </Grid>
  );
};