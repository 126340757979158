// src/components/PaymentStatus.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';

import { createPaymentIntent } from 'redux/payment/paymentThunks';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Container } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ManualReview from './ManualReview';
import { usePurchaseSocket } from '../../hooks/usePurchaseSocket';

const PaymentStatus: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const paymentError = useSelector((state: RootState) => state.payment.error);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const cartTotal = useSelector((state: RootState) => state.cart.total);

  // Socket-driven states from our custom hook
  const { orderStatus, errorMessage } = usePurchaseSocket();

  // Local fallback error
  const [localErrorMessage, setLocalErrorMessage] = useState<string>('');

  useEffect(() => {
    if (orderStatus === 'paid') {
      // Navigate + cleanup
      const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
      const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
      navigate(`/cart/game-activation?payment_intent=${paymentIntent}&payment_intent_client_secret=${clientSecret}`);

    } else if (orderStatus === 'failed') {
      // Choose best error
      if (!errorMessage && paymentError) {
        setLocalErrorMessage(paymentError);
      } else if (errorMessage) {
        setLocalErrorMessage(errorMessage);
      } else {
        setLocalErrorMessage('Payment failed, please try again.');
      }
    }
  }, [orderStatus, errorMessage, paymentError, dispatch, navigate]);

  const handleRetry = async () => {
    const newPaymentIntent = await dispatch(createPaymentIntent({ amount: cartTotal, items: cartItems }));
    if (createPaymentIntent.fulfilled.match(newPaymentIntent)) {
      navigate('/cart/payment');
    } else if (createPaymentIntent.rejected.match(newPaymentIntent)) {
      const error = newPaymentIntent.payload as string;
      setLocalErrorMessage(error);
    }
  };

  // Render content
  let content: React.ReactNode;
  switch (orderStatus) {
    case 'processing':
    case 'processing_payment':
      content = (
        <>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Please wait while we confirm your payment...
          </Typography>
          <CircularProgress />
        </>
      );
      break;

    case 'manual_review':
      content = <ManualReview />;
      break;

    case 'failed':
      if (localErrorMessage === 'Too many failed requests') {
        content = (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/img/payment_block.webp" height={250} alt="Too many failed requests" />
            <Typography variant="h4" sx={{ mb: 3 }}>
              Too many failed requests
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Please try again later or contact our support.
            </Typography>
            <Button
              startIcon={<ArrowBackRoundedIcon />}
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
            >
              Return Home
            </Button>
          </Container>
        );
      } else {
        content = (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src="/img/payment_error.webp" height={250} alt="Payment Error" />
            <Typography variant="h4" sx={{ mb: 4 }}>
              Your payment failed.
            </Typography>
            <Typography variant="h6" sx={{ mb: 4 }}>
              {localErrorMessage || 'Please try again, or contact our support.'}
            </Typography>
            <Button
              startIcon={<ArrowBackRoundedIcon />}
              variant="contained"
              color="primary"
              onClick={handleRetry}
            >
              Try Again
            </Button>
          </Container>
        );
      }
      break;

    case 'paid':
      content = (
        <Box padding={2} textAlign="center">
          <Typography variant="h6">Loading your activation...</Typography>
          <CircularProgress />
        </Box>
      );
      break;

    default:
      content = (
        <>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Please wait while we confirm your payment...
          </Typography>
          <CircularProgress />
        </>
      );
  }

  return (
    <Box padding={2} textAlign="center">
      {content}
    </Box>
  );
};

export default PaymentStatus;