export const deResources = {
    translation: {
        pleaseLoginToSeeTicketHistory: "Bitte melden Sie sich an, um Ihren Ticket-Verlauf zu sehen.",
        languageChanged: "Sprache geändert",
        selectedLanguage: "Deutsch ist jetzt ausgewählt",
        dark: "Dunkel",
        light: "Hell",
        systemPreferences: "Systemeinstellungen",
        changeApplicationTheme: "Anwendungsthema auswählen",
        applicationTheme: "Anwendungsthema",
        darkDescription: "Willkommen auf der dunklen Seite, wir haben Kekse!",
        lightDescription: "Umarmen Sie das Licht, edler Spieler!",
        autoDescription: "Lassen Sie Ihr System Ihr Schicksal entscheiden!",
        welcome: "Willkommen",
        welcomeToBigswiss: "Willkommen bei Bigswiss",
        completeProfileMessage: "Bitte vervollständigen Sie Ihr Profil",
        selectLanguage: "Standardsprache auswählen",
        selectLanguageLabel: "Sprache auswählen",
        gdprFadpDescription: "Wir verwenden Cookies, um die ordnungsgemäße Funktion unserer Website sicherzustellen und Ihre Erfahrung zu verbessern. Wenn Sie 'Nur notwendige Cookies akzeptieren' auswählen, stimmen Sie der Verwendung wesentlicher Cookies zu, die für die Kernfunktionen erforderlich sind, einschließlich Drittanbieter-Cookies von Stripe für die sichere Zahlungsabwicklung. Wenn Sie 'Alle Cookies akzeptieren' auswählen, erlauben Sie uns, auch optionale Cookies wie Analyse- (Google Analytics) und Werbecookies zu verwenden, um unsere Dienste weiter zu verbessern.",
        acceptTerms: "Ich stimme den Allgemeinen Geschäftsbedingungen zu.",
        acceptNecessary: "Nur Essenziell",
        acceptAll: "Alle Akzeptieren",
        termsWarning: "Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu, um fortzufahren.",


        selectCurrency: "Währung auswählen",
        newsletter: "Newsletter",
        subscribeMessage: "Abonnieren Sie unseren Newsletter für wöchentliche Updates und Aktionen.",
        subscribe: "Abonnieren",
        products: "Produkte",
        position: "Position",
        message: "Nachricht",
        invalidEmail: "Ungültige E-Mail",
        allFieldsRequired: "Bitte füllen Sie alle erforderlichen Felder aus",
        messageSent: "Nachricht gesendet",
        contacted: "Anfrage gesendet",
        jobThankYou: "Vielen Dank für Ihr Interesse. Wir werden Sie bald kontaktieren.",
        duplicateApplication: "Sie haben sich bereits beworben, bitte haben Sie Geduld, bis wir uns bei Ihnen melden.",
        duplicateSubscription: "Sie sind bereits abonniert.",
        succesSubcribed: "Sie haben sich erfolgreich angemeldet.",
        company: "Unternehmen",
        getInTouch: "Kontakt",
        privacyPolicy: "Datenschutzrichtlinie",
        termsOfService: "Nutzungsbedingungen",
        pcGames: "PC-Spiele",
        xboxGames: "XBOX-Spiele",
        playstationGames: "PlayStation-Spiele",
        nintendoGames: "Nintendo-Spiele",
        giftCards: "Geschenkkarten",
        subscriptionCards: "Abonnementkarten",
        aboutUs: "Über uns",
        testimonials: "Erfahrungsberichte",
        testimonialsDescription: "Sehen Sie, was unsere Kunden an unseren Funktionen lieben. Entdecken Sie, wie wir in Effizienz und Zufriedenheit übertreffen. Schließen Sie sich uns an für niedrige Kosten, Einzelhandelsinnovation und zuverlässigen Support.",

        highlights: "Höhepunkte",
        pricing: "Preisgestaltung",
        faqs: "FAQ",
        giftAndSubscriptionCards: "Geschenk & Abonnementkarten",
        checkTopSellers: "Top-Verkäufer überprüfen",
        platforms: "Plattformen",
        category: "Kategorie",
        platform: "Plattform",
        contactSupportText: "Wenn Sie die Region Ihres Kontos ändern möchten, wenden Sie sich bitte an unseren Support",
        region: "Region",
        productType: "Produkttyp",
        none: "Keine",
        sort: "Sortieren",
        priceIncreasing: "Preiserhöhung",
        priceDecreasing: "Preis sinkend",

        consoles: "Konsolen",
        aboutUsDescription: "Seit 2002 ist Bigswiss.ch Ihr Anlaufpunkt für digitale Spiele und Geschenkkarten, stolz aus dem Herzen der Schweiz operierend.Mit über zwei Jahrzehnten Erfahrung sind wir bestrebt, Ihnen die neuesten und besten digitalen Unterhaltungsangebote zu bieten.\n\nUnser umfangreiches Sortiment umfasst eine Vielzahl von digitalen Spielen auf allen beliebten Plattformen sowie eine Auswahl an Geschenkkarten für Ihre bevorzugten Dienste.Ob Sie ein erfahrener Gamer sind, der auf der Suche nach dem nächsten großen Release ist, oder das perfekte Geschenk für einen geliebten Menschen suchen, Bigswiss.ch hat alles, was Sie brauchen.\n\nBei Bigswiss.ch legen wir großen Wert auf außergewöhnlichen Kundenservice und ein nahtloses Einkaufserlebnis.Unser engagiertes Team steht Ihnen jederzeit zur Verfügung, um sicherzustellen, dass Ihre Reise mit uns reibungslos und angenehm verläuft.",
        onlineStoreTitle: "Online-Shop",
        onlineStoreDescription: "Kaufen Sie über 10.000 verschiedene Videospiele und digitale Produkte",
        posTerminalsTitle: "POS-Terminals",
        posTerminalsDescription: "Integrieren Sie unsere POS-Terminals in Ihrem Geschäft und erhalten Sie Zugriff auf alle unsere Spiele",
        wholesaleApiTitle: "Großhandels-API",
        wholesaleApiDescription: "Integrieren Sie unsere Großhandels-API in Ihren Online-Shop",
        learnMore: "Erfahren Sie mehr",
        goToStore: "Zum Shop",
        highlightsDescription: "Erfahren Sie, warum unser Produkt herausragt: Anpassungsfähigkeit, Haltbarkeit, benutzerfreundliches Design und Innovation. Genießen Sie zuverlässigen Kundensupport und Präzision in jedem Detail.",
        instantDeliveryTitle: "Sofortige Lieferung",
        instantDeliveryDescription: "Erhalten Sie Ihre digitalen Codes sofort nach dem Kauf.",
        digitalDownloadTitle: "Digitaler Download",
        digitalDownloadDescription: "Greifen Sie auf Ihre Lieblingsspiele und -dienste mit einfach einzulösenden Codes zu.",
        customerSupportTitle: "24/7 Kundenservice",
        customerSupportDescription: "Wir sind rund um die Uhr für Sie da, bereit, jedes Problem zu lösen.",
        adaptablePerformanceTitle: "Anpassungsfähige Leistung",
        adaptablePerformanceDescription: "Unser Produkt passt sich mühelos Ihren Bedürfnissen an, steigert die Effizienz und vereinfacht Ihre Aufgaben.",
        innovativeFunctionalityTitle: "Innovative Funktionalität",
        innovativeFunctionalityDescription: "Bleiben Sie mit Funktionen, die neue Maßstäbe setzen, Ihrer Entwicklung stets einen Schritt voraus.",
        precisionDetailTitle: "Präzision in jedem Detail",
        precisionDetailDescription: "Genießen Sie ein sorgfältig gestaltetes Produkt, bei dem kleine Details einen großen Einfluss auf Ihr Gesamterlebnis haben.",
        ourTopSellingProducts: "Unsere Bestseller-Produkte",

        faqTitle: "Häufig gestellte Fragen",
        faq1Question: "Wie kontaktiere ich den Kundenservice, wenn ich eine Frage oder ein Problem habe?",
        faq1Answer: "Wir sind hier, um Ihnen umgehend zu helfen. Sie können unser Kundensupport-Team erreichen, indem Sie eine E-Mail an ",
        faq2Question: "Kann ich das Produkt zurückgeben, wenn es nicht meinen Erwartungen entspricht?",
        faq2Answer: "Absolut! Wir bieten eine unkomplizierte Rückgaberecht. Wenn Sie nicht vollständig zufrieden sind, können Sie das Produkt innerhalb von 7 Tagen für eine vollständige Rückerstattung oder einen Umtausch zurückgeben.",
        faq3Question: "Was unterscheidet Ihr Produkt von anderen auf dem Markt?",
        faq3Answer: "Unser Produkt zeichnet sich durch seine Anpassungsfähigkeit, Haltbarkeit und innovativen Funktionen aus. Wir legen großen Wert auf Kundenzufriedenheit und streben ständig danach, in allen Aspekten die Erwartungen zu übertreffen.",
        faq4Question: "Gibt es eine Garantie auf das Produkt und was deckt sie ab?",
        faq4Answer: "Ja, unser Produkt kommt mit einer 1 Jahr Garantie. Sie deckt Material- und Verarbeitungsfehler ab. Wenn Sie Probleme haben, die von der Garantie abgedeckt sind, kontaktieren Sie bitte unseren Kundenservice für Unterstützung.",
        available: "Verfügbar",
        now: "Jetzt",
        games: "Spiele",
        about: "Über",
        game: "Spiel",
        website: "Webseite",
        description: "Beschreibung",
        rating: "Bewertung",
        developer: "Entwickler",
        publisher: "Verleger",
        released: "Veröffentlichungsdatum",
        genre: "Genre",
        gallery: "Galerie",
        maxPrice: "Maximaler Preis",

        availableEditions: "Verfügbare Editionen",
        availablePlatforms: "Verfügbare Plattformen",
        addToCart: "In den Warenkorb",
        outOfStock: "Ausverkauft",
        emptyCartHeading: "Der Warenkorb ist leer...",
        emptyCartSubheading: "Ihr Warenkorb sehnt sich nach Gesellschaft. Beginnen Sie jetzt Ihr Einkaufserlebnis!",
        exploreCatalog: "Durchstöbern Sie unseren Katalog",
        yourCart: "Ihr Warenkorb",
        quantity: "Menge",
        maxProductAllowed: "Maximal {{max}} Produkte erlaubt",
        total: "Gesamt",
        goToCart: "Zum Warenkorb",
        payNow: "Jetzt bezahlen",
        goToPayment: "Zur Zahlung",
        discount: "Rabatt",
        originalPrice: "Originalpreis",
        checkMoreProducts: "Weitere Produkte ansehen",
        cartSummary: "Warenkorb Zusammenfassung",
        cartItems: "Warenkorb",
        payment: "Bezahlung",
        gameActivation: "Spielaktivierung",
        login: "Anmeldung",
        remove: "Entfernen",

        Notification: "Benachrichtigung",
        notificationsEnabled: "Browser-Benachrichtigungen sind aktiviert",
        notificationsEnableFail: "Fehler beim Aktivieren der Benachrichtigungen",
        notificationsDisabled: "Browser-Benachrichtigungen sind deaktiviert",
        notificationsDisableFail: "Fehler beim Deaktivieren der Benachrichtigungen",
        notificationsDenied: "Benachrichtigungsberechtigung wurde verweigert",
        notificationsNoPermission: "Benachrichtigungsberechtigung nicht erteilt",
        notificationsPermissionFaild: "Anforderung der Benachrichtigungsberechtigung fehlgeschlagen",
        notificationsNotSupported: "Push-Nachrichten werden nicht unterstützt",
        notificationsFailToSubscribe: "Fehler beim Abonnieren des Benutzers",
        notificationsFailToUnsubscribe: "Fehler beim Abbestellen des Benutzers",
        serviceWorkerNotReady: "Bitte laden Sie die Seite neu und versuchen Sie es erneut",
        notificationsSubscriptionErro: "Fehler beim Abrufen des Abonnements",
        browserNotifications: "Browser-Benachrichtigungen",

        noProductsFound: "Keine Produkte gefunden.",
        tryDifferentQuery: "Bitte versuchen Sie es mit einer anderen Suchanfrage oder setzen Sie die Filter zurück.",
        resetFilters: "Filter zurücksetzen",
        achievements: "Erfolge",
        noFavorites: "Hoppla! Keine Beute gefunden!",
        noFavoritesText: "Zeit, nach epischer Beute zu jagen!",
        favorites: "Favoriten",
        purchases: "Einkäufe",
        points: "Punkte",
        dashboard: "Armaturenbrett",
        settings: "Einstellungen",
        updateSettings: "Einstellungen aktualisieren",
        security: "Sicherheit",
        invoiceNotFound: "Rechnung nicht gefunden",
        action: "Aktion",
        date: "Datum",
        rowsPerPage: "Zeilen pro Seite",
        aboutOurApi: "Über unsere API",
        aboutOurApiDescription: "Willkommen auf unserer API-Produktseite! Unsere API bietet nahtlosen Zugang zu über 10.000 Produkten und stellt sicher, dass Sie jederzeit eine breite Palette von Artikeln für Ihre Kunden integrieren und anbieten können. Ob Sie eine E-Commerce-Plattform, eine mobile App oder einen anderen Service erstellen, unsere API hat Sie abgedeckt.",
        whyChooseOurApi: "Warum Sie unsere API wählen sollten?",
        whyChooseOurApiDescription: "Unsere API ist robust, zuverlässig und einfach zu bedienen. Hier sind einige der wichtigsten Funktionen:",
        featureApi1: "Zugang zu über 10.000 Produkten",
        featureApi2: "Echtzeit-Produktverfügbarkeit und -preisgestaltung",
        featureApi3: "Hohe Verfügbarkeit mit 99,9% Betriebszeit",
        featureApi4: "Umfassende Dokumentation und Unterstützung",
        featureApi5: "Einfache Integration mit RESTful-Endpunkten",
        featureApi6: "Sichere und skalierbare Architektur",
        WithOurApi: "Mit unserer API können Sie sicherstellen, dass Ihre Kunden jederzeit Zugang zu einem umfangreichen Produktinventar haben. Unsere hohe Verfügbarkeit und Echtzeit-Updates bedeuten, dass Sie nie einen Verkauf verpassen.",

        aboutOurPosTerminals: "Über unsere POS-Terminals",
        welcomeMessage: "Willkommen auf unserer Produktseite! Wir sind stolz darauf, hochmoderne POS-Terminals anzubieten, die speziell für Geschäfte entwickelt wurden, die Spiele und Abonnementkarten verkaufen. Unsere Terminals sind einfach zu bedienen, sicher und effizient und helfen Ihnen, Ihre Verkäufe und Ihr Inventar mit Leichtigkeit zu verwalten",
        whyChooseOurPosTerminals: "Warum unsere POS-Terminals wählen?",
        posTerminalFeatures: "Unsere POS-Terminals bieten eine Reihe von Funktionen zur Optimierung Ihrer Geschäftsabläufe:",
        feature1: "Nahtlose Integration in Ihre bestehenden Systeme",
        feature2: "Schnelle und sichere Zahlungsabwicklung",
        feature3: "Echtzeit-Inventarverwaltung",
        feature4: "Benutzerfreundliche Oberfläche für schnelles Training",
        feature5: "Anpassbare Einstellungen, die zu den Bedürfnissen Ihres Geschäfts passen",
        posTerminalDescription: "Ob Sie physische Spiele oder digitale Abonnementkarten verkaufen, unsere POS-Terminals sind darauf ausgelegt, alle Ihre Transaktionen effizient abzuwickeln. Verbessern Sie das Kundenerlebnis und steigern Sie Ihren Umsatz mit unseren zuverlässigen und vielseitigen POS-Lösungen,",

        invoiceNo: "Rechnungsnummer",
        howTo: "Wie",
        activate: "Aktivieren",
        step: "Schritt",
        giftToFriend: "Als Geschenk an einen Freund",
        enterEmailTwice: "Bitte geben Sie die E-Mail-Adresse Ihres Freundes zweimal zur Bestätigung ein.",
        friendEmail: "E-Mail des Freundes",
        confirmFriendsEmail: "E-Mail des Freundes bestätigen",
        emailsDoNotMatch: "Die E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es erneut.",
        sendGift: "Geschenk senden",
        giftSent: "Gift gesendet",
        giftSentMessage: "Wir haben den Schlüssel erfolgreich als Geschenk an Ihren Freund an die folgende E-Mail gesendet:",
        close: "Schließen",

        buyers: "Käufer",
        reviews: "Bewertungen",
        user: "Benutzer",
        comment: "Kommentar",

        requirements: "Anforderungen",
        minimumRequirements: "Mindestanforderungen",
        recommendedRequirements: "Empfohlene Anforderungen",
        OS: "Betriebssystem",
        Processor: "Prozessor",
        Memory: "Speicher",
        Graphics: "Grafik",
        DirectX: "DirectX",
        Storage: "Speicherplatz",
        AdditionalNotes: "Zusätzliche Hinweise",

        careers: "Karriere",
        contactUs: "Kontaktieren Sie uns",
        updatePersonalData: "Rechnungsdaten aktualisieren",
        updateApplicationSettings: "Anwendungseinstellungen aktualisieren",
        birthday: "Geburtstag",
        yourName: "Ihr Vorname",
        yourLastName: "Ihr Nachname",
        enterEmail: "Geben Sie Ihre E-Mail-Adresse ein",
        yourEmail: "Ihre E-Mail-Adresse",

        changePassword: "Passwort ändern",
        currentPassword: "Aktuelles Passwort",
        newPassword: "Neues Passwort",
        confirmPassword: "Passwort bestätigen",
        showLess: "Weniger anzeigen",
        showMore: "Mehr anzeigen",
        PageNotFound: "Seite nicht gefunden",
        DontGiveUp: "Gib nicht auf, vielleicht findest du hier, wonach du suchst.",
        Jobs: "Jobs",
        ElevateYourCareer: "Steigern Sie Ihre Karriere bei Bigswiss GmbH, wo Innovation und Leidenschaft aufeinandertreffen. Tauchen Sie ein in eine Welt, in der Ihre Arbeit die Zukunft des Gamings und der Technologie direkt mitgestaltet.",
        exploreOpportunities: "Erkunden Sie Möglichkeiten, stellen Sie sich Herausforderungen und werden Sie Teil eines dynamischen Teams, das sich der Exzellenz verschrieben hat. Ihre Reise, um Einfluss zu nehmen, beginnt hier.",
        enterYourEmailAddress: "Geben Sie Ihre E-Mail-Adresse ein",
        yourEmailAddress: "Ihre E-Mail-Adresse",
        contactUsAgree: 'Mit dem Klick auf "Kontaktieren Sie uns" stimmen Sie unseren',
        termsAndConditions: "Geschäftsbedingungen zu",
        bigswissSupport: "Kontakt Bigswiss ",
        support: "Unterstützung",
        contactDescription: "Von Anfragen zu unserem innovativen Online-Shop und Großhandel bis hin zur Unterstützung bei unseren Kiosk-Anwendungen für Videospielkäufe und dem Großhandels-API-Shop sind wir hier, um Ihre Erfahrung zu verbessern.",
        connectTeam: "Verbinden Sie sich mit unserem engagierten Team für erstklassige Funktionen und Dienstleistungen, die speziell für Sie entwickelt wurden.",

        pricingDescription: "Wählen Sie den Plan, der Ihren Anforderungen entspricht",
        basic: "Basis",
        pro: "Pro",
        enterprise: "Unternehmen",
        getStarted: "Loslegen",
        startNow: "Jetzt starten",
        mostPopular: "Am beliebtesten",
        perMonth: "pro Monat",
        basicDesc1: "API-Zugriff für bis zu 100 Anfragen pro Monat",
        basicDesc2: "Grundlegende Analysen und Berichte",
        basicDesc3: "Standardunterstützung",
        proDesc1: "API-Zugriff für bis zu 10.000 Anfragen pro Monat",
        proDesc2: "Erweiterte Analysen und Berichte",
        proDesc3: "Prioritätsunterstützung",
        proDesc4: "Zugang zu exklusiven Angeboten für Spiele und Abonnementkarten",
        enterpriseDesc1: "Unbegrenzte API-Anfragen",
        enterpriseDesc2: "Benutzerdefinierte Analysen und Berichte",
        enterpriseDesc3: "24/7 dedizierter Support",
        enterpriseDesc4: "Angepasste POS-Terminallösungen",
        enterpriseDesc5: "Beste Preise bei Massenkäufen",
        chooseActivation: "Wählen Sie, wo Sie Ihr Produkt aktivieren möchten.",
        thankYouForPurchase: "Vielen Dank für Ihren Kauf!",
        findYourKeysBellow: "Sie finden Ihre Schlüssel und Rechnung unten.",
        productKey: "Produktschlüssel",
        productKeys: "Produktschlüssel",
        productActivation: "Produktaktivierung",
        productActivationDescription: "Bitte befolgen Sie die Anweisungen unten, um Ihr Produkt zu aktivieren.",
        sendAsGift: "Verschenken",
        hide: "Ausblenden",
        viewKeys: "Schlüssel anzeigen",
        purchase_review_pending: "Ihr Kauf wird überprüft. Sie erhalten eine E-Mail, sobald er genehmigt wurde.",
        getting_key_please_wait: "Ihr Schlüssel wird abgerufen. Bitte warten...",
        purchaseReviewPending: "Ihr Kauf wird überprüft. Sie erhalten eine E-Mail, sobald er genehmigt wurde.",

        downloadInvoice: "Rechnung herunterladen",
        download: "Herunterladen",
        back: "Zurück",
        next: "Weiter",
        activationMethod: "Aktivierungsmethode",
        keylessActivation: "Schlüssellose Aktivierung",
        activationLink: "Aktivierungslink",
        enterTheKey: "Schlüssel eingeben",
        confirmActivation: "Aktivieren",
        battlenetStep1: "Klicken Sie auf die Schaltfläche unten, um zur Battle.net-Schlüsselaktivierungsseite zu navigieren.",
        goToBattlenetActivation: "Zur Battle.net-Aktivierungsseite gehen",
        battlenetStep2: "Melden Sie sich mit Ihren Battle.net-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        battlenetStep3: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie dann auf 'Code einlösen'.",
        battlenetStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Battle.net-Konto heruntergeladen und gespielt zu werden.",
        goToActivation: "Aktivierung",
        battlenetStep2app: "Öffnen Sie die Blizzard Battle.net-App. Und melden Sie sich mit Ihren Battle.net-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        battlenetStep3app: "Klicken Sie auf Ihr Profil, um ein Menü zu öffnen, und klicken Sie dann auf 'Code einlösen'",
        goToSteamActivation: "Zur Steam-Aktivierungsseite gehen",
        steamStep1: "Klicken Sie auf die Schaltfläche unten, um zur Steam-Schlüsselaktivierungsseite zu navigieren.",
        steamStep2: "Melden Sie sich mit Ihren Steam-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        steamStep3: "Lesen und akzeptieren Sie die Steam-Abonnementvereinbarung und klicken Sie dann auf 'Weiter'.",
        steamStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Steam-Konto heruntergeladen und gespielt zu werden.",
        steamStep2app: "Öffnen Sie die Steam-App und melden Sie sich mit Ihren Steam-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        steamStep3app: "Klicken Sie im oberen Menü auf 'Spiele' und dann auf 'Ein Produkt bei Steam aktivieren'.",
        steamStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie dann auf 'Bestätigen'.",

        goToMicrosoftActivation: "Zur Microsoft Store Aktivierungsseite gehen",
        microsoftStep1: "Klicken Sie auf die Schaltfläche unten, um zur Microsoft Store Schlüsselaktivierungsseite zu gelangen.",
        microsoftStep2: "Melden Sie sich mit Ihren Microsoft-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        microsoftStep3: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'.",
        microsoftStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Microsoft-Konto heruntergeladen und gespielt zu werden.",
        microsoftStep2app: "Öffnen Sie auf Ihrem Windows-System die Microsoft Store App und melden Sie sich mit Ihren Microsoft-Anmeldeinformationen an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        microsoftStep3app: "Klicken Sie auf das Profil-Logo oben rechts und dann auf 'Code oder Geschenkkarten einlösen'.",

        goToGOGActivation: "Zur GOG-Aktivierungsseite gehen",
        activateKey: "Schlüssel aktivieren",
        linkToAccount: "Mit Konto verknüpfen",
        gogStep1: "Klicken Sie auf die Schaltfläche unten, um zur GOG-Schlüsselaktivierungsseite zu gelangen.",
        gogStep2: "Klicken Sie auf 'Weiter', um die Schlüsselaktivierung zu bestätigen.",
        gogStep3: "Klicken Sie auf 'Anmelden', um den Schlüssel mit Ihrem GOG-Konto zu verknüpfen.",
        gogStep4: "Melden Sie sich mit Ihren GOG-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
        gogStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem GOG-Konto heruntergeladen und gespielt zu werden.",

        gogStep2app: "Öffnen Sie die GOG Galaxy-App und melden Sie sich mit Ihren GOG-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
        gogStep3app: "Klicken Sie im oberen Menü auf 'Spiele & Freunde hinzufügen' und dann auf 'GOG-Code einlösen'.",
        gogStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'.",


        goToEpicActivation: "Zur Epic Games-Aktivierungsseite gehen",
        epicStep1: "Klicken Sie auf die Schaltfläche unten, um zur Epic Games-Schlüsselaktivierungsseite zu navigieren.",
        epicStep2: "Klicken Sie auf 'Einlösen', um die Schlüsselaktivierung zu bestätigen.",
        epicStep3: "Klicken Sie auf 'Anmelden', um den Schlüssel mit Ihrem Epic Games-Konto zu verknüpfen.",
        epicStep4: "Melden Sie sich mit Ihren Epic Games-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
        epicStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert und es ist bereit, auf Ihrem Epic Games-Konto heruntergeladen und gespielt zu werden.",
        epicStep2app: "Öffnen Sie die Epic Games-App und melden Sie sich mit Ihren Epic Games-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
        epicStep3app: "Klicken Sie oben rechts auf Ihr Profil und dann auf 'Code einlösen'.",
        epicStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Einlösen'.",

        goToRockstarActivation: "Zur Rockstar-Aktivierungsseite gehen",
        rockstarStep1: "Klicken Sie auf den untenstehenden Button, um zur Rockstar-Schlüsselaktivierungsseite zu gelangen.",
        rockstarStep2: "Melden Sie sich mit Ihren Rockstar-Anmeldedaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        rockstarStep3: "Bitte warten Sie, bis die Seite geladen und die Rockstar-Validierung abgeschlossen ist. Dies kann einige Minuten dauern. Sobald die Seite geladen ist, sehen Sie eine Bestätigungsnachricht. Klicken Sie auf 'Bestätigen', um den Schlüssel auf Ihr Rockstar-Konto zu aktivieren.",
        rockstarStep4: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Rockstar-Konto heruntergeladen und gespielt zu werden.",
        rockstarStep2app: "Öffnen Sie die Rockstar Games Launcher-App und melden Sie sich mit Ihren Rockstar-Anmeldedaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        rockstarStep3app: "Klicken Sie auf das Menü in der oberen rechten Ecke und dann auf 'CODE EINLÖSEN'.",
        rockstarStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'PRÜFEN'.",
        rockstarStep5app: "Lesen und akzeptieren Sie die Allgemeinen Geschäftsbedingungen, und klicken Sie dann auf 'EINLÖSEN'.",

        goToEAPlayActivation: "Zur EA Play Aktivierungsseite gehen",
        eaStep1: "Klicken Sie unten auf die Schaltfläche, um zur EA Play-Schlüsselaktivierungsseite zu gelangen.",
        eaStep2: "Melden Sie sich mit Ihren EA Play-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
        eaStep3: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
        eaStep4: "Klicken Sie auf die Schaltfläche 'Weiter', um den Schlüssel mit Ihrem EA Play-Konto zu verknüpfen.",
        eaStep5: "Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es kann nun auf Ihrem EA Play-Konto heruntergeladen und gespielt werden.",

        eaStep2app: "Öffnen Sie die EA Play-App und melden Sie sich mit Ihren EA Play-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
        eaStep3app: "Klicken Sie oben rechts im Menü auf 'Code einlösen'.",
        eaStep4app: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",

        ubisoftStep2app: "Öffnen Sie die Ubisoft-App und melden Sie sich mit Ihren Ubisoft-Zugangsdaten an. Wenn Sie noch kein Konto haben, erstellen Sie kostenlos eines.",
        ubisoftStep3app: "Klicken Sie im Profilmenü in der oberen linken Ecke und dann auf 'Schlüsselaktivierung'.",
        ubisoftStep4app: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Aktivieren'.",

        goToSwitchActivation: "Zur Nintendo Switch-Aktivierungsseite gehen",
        openeshop: "eShop öffnen",
        selectUser: "Benutzer auswählen",
        redeem: "Schlüssel einlösen",
        switchStep1: "Klicken Sie unten auf die Schaltfläche, um zur Nintendo Switch-Schlüsselaktivierungsseite zu gelangen.",
        switchStep2: "Melden Sie sich mit Ihren Nintendo Switch-Zugangsdaten an. Wenn Sie kein Konto haben, erstellen Sie eines kostenlos.",
        switchStep3: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
        switchStep4: "Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es kann nun auf Ihrem Nintendo Switch-Konto heruntergeladen und gespielt werden.",
        switchStep1app: "Öffnen Sie die Nintendo eShop-App.",
        switchStep2app: "Wählen Sie das Konto, auf dem Sie das Produkt aktivieren möchten.",
        switchStep3app: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Bestätigen'.",
        switchStep4app: "Wählen Sie 'Einlösen' und klicken Sie auf 'Bestätigen', um das Produkt mit Ihrem Nintendo Switch-Konto zu verknüpfen.",

        goToPlayStationActivation: "Zur PlayStation-Aktivierungsseite gehen",
        openLogin: "Login öffnen",
        psStep1: "Klicken Sie unten, um zur PlayStation-Aktivierungsseite zu gelangen.",
        psStep2: "Klicken Sie oben rechts auf 'Login'",
        psStep3: "Melden Sie sich mit Ihren PlayStation-Zugangsdaten an. Wenn Sie noch kein Konto haben, erstellen Sie eines kostenlos.",
        psStep4: "Klicken Sie oben rechts auf Ihr Profil und dann auf 'Code einlösen'.",
        psStep5: "Geben Sie den erhaltenen Schlüssel in das entsprechende Feld ein und klicken Sie auf 'Weiter'.",
        psStep6: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert. Es ist jetzt bereit, auf Ihrem PlayStation-Konto heruntergeladen und gespielt zu werden.",
        openStore: "Store öffnen",
        psStep2console: "Wählen Sie den PlayStation Store auf der linken Seite aus und drücken Sie 'X'.",
        psStep3console: "Drücken Sie '△', um auf das Menü in der oberen rechten Ecke zuzugreifen, wählen Sie '...' und wählen Sie 'Code einlösen'.",
        psStep4console: "Geben Sie den erhaltenen Code in das vorgesehene Feld ein und klicken Sie auf 'Einlösen'.",
        psStep2app: "Öffnen Sie die PlayStation-App und den PlayStation Store.",
        openMenu: "Menü öffnen",
        psStep3app: "Wählen Sie das Menü in der oberen rechten Ecke aus, um weitere Optionen anzuzeigen.",
        psStep4app: "Wählen Sie 'Code einlösen'.",

        goToXboxActivation: "Zur Xbox-Aktivierungsseite gehen",
        xboxStep1: "Klicken Sie auf die Schaltfläche unten, um zur Xbox-Schlüsselaktivierungsseite zu navigieren.",
        xboxStep2: "Klicken Sie auf 'Anmelden, um einzulösen', um sich mit Ihren Xbox-Anmeldeinformationen anzumelden. Wenn Sie kein Konto haben, erstellen Sie kostenlos eines.",
        xboxStep3: "Klicken Sie auf 'Jetzt einlösen', um das Schlüsselaktivierungsfenster zu öffnen.",
        xboxStep4: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Weiter'. Folgen Sie den weiteren Anweisungen.",
        xboxStep5: "Herzlichen Glückwunsch! Sie haben Ihr Produkt erfolgreich aktiviert, und es ist bereit, auf Ihrem Xbox-Konto heruntergeladen und gespielt zu werden.",
        xboxStep2console: "Drücken Sie die Xbox-Taste auf Ihrem Controller, um den Guide zu öffnen. Während Sie mit Ihrem Konto angemeldet sind, wählen Sie 'Store'.",
        xboxStep3console: "Sobald es geöffnet ist, drücken Sie die Ansichtstaste auf Ihrem Controller, um das Menü zu öffnen, und wählen Sie 'Einlösen'.",
        xboxStep4console: "Geben Sie den erhaltenen Schlüssel in das vorgesehene Feld ein und klicken Sie auf 'Code prüfen'.",

        warningModalTitle: "Wichtiger Hinweis",
        warningModalMessage1: "Sobald Sie den Schlüssel anzeigen, verlieren Sie das Recht auf eine sofortige Rückerstattung.",
        warningModalMessage2: "Sie sind nur dann für eine Rückerstattung berechtigt, wenn der Schlüssel ungültig ist.",
        doYouWantToProceed: "Möchten Sie fortfahren?",
        revealKey: "Schlüssel anzeigen",

        // Chat
        helpCenter: "Hilfe-Center",
        talkWithourSupport: "Sprechen Sie mit dem Support",
        haveAquestion: "Haben Sie eine Frage?",
        typeAmessage: "Geben Sie eine Nachricht ein...",
        hello: "Hallo",
        howCanIHelpYou: "Wie kann ich Ihnen helfen?",
        home: "Startseite",
        history: "Verlauf",
        help: "Hilfe",
        noChatMessages: "Sie haben noch keine Chat-Historie.",
        allHelpTopics: "Alle Hilfethemen",
        searchTopics: "Themen durchsuchen...",
        noTopicsFound: "Keine Themen gefunden.",

        redeemingCodesCategory: "Codes einlösen",
        findingCodesCategory: "Codes finden",
        invoicesCategory: "Rechnungen",
        codeActivationIssueCategory: "Probleme bei der Code-Aktivierung",
        identityVerificationCategory: "Identitätsüberprüfung",

        howToRedeem: "Wie kann ich den Code einlösen?",
        whereToFindCodes: "Wo finde ich meine Codes?",
        whereToGetInvoice: "Wo bekomme ich eine Rechnung für meinen Kauf?",
        issuesActivatingCode: "Ich habe Probleme bei der Aktivierung meines Codes",
        issuesIdentityVerification: "Ich habe Probleme mit meiner Identitätsüberprüfung",

        howToRedeemDesc: "Befolgen Sie die folgenden Schritte, um Ihren Code erfolgreich einzulösen:",
        findingCodesDesc: "Befolgen Sie die folgenden Schritte, um Ihren Code zu finden:",
        invoicesDesc: "Um auf eine Rechnung für Ihren Kauf zuzugreifen, befolgen Sie diese Schritte:",
        activationIssueDesc: "Wenn Sie Probleme bei der Aktivierung Ihres Codes haben, überprüfen Sie bitte Folgendes:",
        identityVerificationDesc: "Wenn Sie Schwierigkeiten bei der Identitätsprüfung haben, stellen Sie bitte Folgendes sicher:",

        accessAccountTitle: "Zugriff auf Ihr Konto",
        accessAccountDesc: "Klicken Sie auf das Benutzersymbol in der oberen rechten Ecke der Seite.",

        navigatePurchasesTitle: "Zu Einkäufen gehen",
        navigatePurchasesDesc: "Wählen Sie im Dropdown-Menü „Einkäufe“ aus, um Ihre letzten Transaktionen anzuzeigen.",

        selectRelevantPurchaseTitle: "Relevanten Kauf auswählen",
        selectRelevantPurchaseDesc: "Wählen Sie in der Einkaufstabelle die Transaktion aus, die mit dem Code verknüpft ist, den Sie aktivieren möchten.",

        viewKeysTitle: "Verfügbare Codes anzeigen",
        viewKeysDesc: "Klicken Sie auf „Codes anzeigen“, um eine Liste verfügbarer Produktcodes zur Aktivierung anzuzeigen.",

        revealKeyTitle: "Code anzeigen",
        revealKeyDesc: "Klicken Sie auf das Augensymbol, um den Code anzuzeigen, den Sie aktivieren möchten.",

        activateKeyTitle: "Code aktivieren",
        activateKeyDesc: "Klicken Sie auf die Schaltfläche „Aktivieren“ und befolgen Sie die Anweisungen auf dem Bildschirm.",

        downloadInvoiceTitle: "Rechnung herunterladen",
        downloadInvoiceDesc: "Klicken Sie auf die Schaltfläche „Rechnung herunterladen“, um eine PDF-Version Ihrer Rechnung zu erhalten.",

        checkTyposTitle: "Auf Tippfehler prüfen",
        checkTyposDesc: "Stellen Sie sicher, dass Sie den Code korrekt und ohne Tippfehler eingegeben haben.",

        verifyUsageTitle: "Code-Nutzung überprüfen",
        verifyUsageDesc: "Stellen Sie sicher, dass der Code nicht bereits verwendet wurde.",

        correctPlatformTitle: "Richtige Plattform",
        correctPlatformDesc: "Stellen Sie sicher, dass Sie den Code auf der richtigen Plattform oder Anwendung verwenden.",

        clearDocumentsTitle: "Klare Dokumente",
        clearDocumentsDesc: "Ihre Ausweisdokumente müssen deutlich und lesbar sein.",

        matchPersonalInfoTitle: "Persönliche Daten abgleichen",
        matchPersonalInfoDesc: "Ihre persönlichen Daten müssen mit den Datensätzen übereinstimmen.",

        supportedVerificationMethodTitle: "Unterstützte Verifizierungsmethode",
        supportedVerificationMethodDesc: "Sie verwenden eine unterstützte Verifizierungsmethode.",

        notesContactSupport: "Wenn das Problem weiterhin besteht, kontaktieren Sie bitte unser Support-Team für weitere Hilfe.",
        stillHavingIssue: "Haben Sie immer noch Probleme mit",
    },
};
