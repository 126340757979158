export const enResources = {
  translation: {
    pleaseLoginToSeeTicketHistory: "Please login to see your ticket history.",
    languageChanged: "Language Changed",
    selectedLanguage: "English is now selected",
    dark: "Dark",
    light: "Light",
    systemPreferences: "System Preferences",
    changeApplicationTheme: "Select application theme",
    applicationTheme: "Application theme",
    darkDescription: "Welcome to the dark side, we have cookies!",
    lightDescription: "Embrace the light, noble gamer!",
    autoDescription: "Let your system decide your fate!",
    welcome: "Welcome",
    welcomeToBigswiss: "Welcome to Bigswiss",
    completeProfileMessage: "Please complete your profile",
    selectLanguage: "Select Default Language",
    selectLanguageLabel: "Select Language",
    gdprFadpDescription: "We use cookies to ensure the proper functioning of our website and to enhance your experience. By selecting 'Accept Necessary,' you consent to the use of essential cookies required for core functionality, including third-party cookies from Stripe for secure payment processing. By selecting 'Accept All,' you allow us to also use optional cookies, such as those for analytics (Google Analytics) and advertising, to further improve our services.",
    acceptTerms: "I agree to the terms and conditions.",
    acceptNecessary: "Essential Only",
    acceptAll: "Accept All",
    termsWarning: "Please agree to the terms and conditions to proceed.",
    selectCurrency: "Select Currency",
    newsletter: "Newsletter",
    subscribeMessage: "Subscribe to our newsletter for weekly updates and promotions.",
    subscribe: "Subscribe",
    products: "Products",
    position: "Position",
    message: "Message",
    invalidEmail: "Invalid Email",
    allFieldsRequired: "Please fill in all required fields",
    messageSent: "Message sent",
    contacted: "Request Sent",
    jobThankYou: "Thank you for your interest. We will contact you soon.",
    duplicateApplication: "You have already applied, please be patient until we contact you.",
    duplicateSubscription: "You are already subscribed.",
    succesSubcribed: "You have successfully subscribed.",
    company: "Company",
    getInTouch: "Get In Touch",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    pcGames: "PC Games",
    xboxGames: "XBOX Games",
    playstationGames: "PlayStation Games",
    nintendoGames: "Nintendo Games",
    giftCards: "Gift Cards",
    subscriptionCards: "Subscriptions Cards",
    aboutUs: "About us",
    testimonials: "Testimonials",
    testimonialsDescription: "See what our customers love about our Features. Discover how we excel in efficiency and satisfaction. Join us for low cost, retail innovation, and reliable support.",
    highlights: "Highlights",
    pricing: "Pricing",
    faqs: "FAQ",
    giftAndSubscriptionCards: "Gift & Subscription Cards",
    checkTopSellers: "Check Top Sellers",
    platforms: "Platforms",
    category: "Category",
    platform: "Platform",
    contactSupportText: "If you want to change your account region, please contact our support",
    region: "Region",
    productType: "Product Type",
    none: "None",
    sort: "Sort",
    priceIncreasing: "Price Increasing",
    priceDecreasing: "Price Decreasing",

    consoles: "Consoles",
    aboutUsDescription: "Since 2002, Bigswiss.ch has been your go-to destination for digital games and gift cards, proudly operating from the heart of Switzerland. With over two decades of experience, we are committed to bringing you the latest and greatest in the world of digital entertainment.\n\nOur extensive selection includes a wide variety of digital games across all popular platforms, as well as an array of gift cards for your favorite services. Whether you're a seasoned gamer looking for the next big release or searching for the perfect gift for a loved one, Bigswiss.ch has you covered.\n\nAt Bigswiss.ch, we pride ourselves on providing exceptional customer service and a seamless shopping experience. Our dedicated team is always here to assist you, ensuring that your journey with us is smooth and enjoyable.",
    onlineStoreTitle: "Online Store",
    onlineStoreDescription: "Buy over 10,000 different video games and digital products",
    posTerminalsTitle: "POS Terminals",
    posTerminalsDescription: "Integrate our POS Terminals in your store and get access to all our games",
    wholesaleApiTitle: "Wholesale API",
    wholesaleApiDescription: "Integrate our wholesale API to your online store",
    learnMore: "Learn More",
    goToStore: "Go to store",
    highlightsDescription: "Explore why our product stands out: adaptability, durability, user-friendly design, and innovation. Enjoy reliable customer support and precision in every detail.",
    instantDeliveryTitle: "Instant Delivery",
    instantDeliveryDescription: "Receive your digital codes instantly after purchase.",
    digitalDownloadTitle: "Digital Download",
    digitalDownloadDescription: "Get access to your favorite games and services with easy-to-redeem codes.",
    customerSupportTitle: "24/7 Customer Support",
    customerSupportDescription: "We're here for you at any time, any day, ready to resolve any issue.",
    adaptablePerformanceTitle: "Adaptable Performance",
    adaptablePerformanceDescription: "Our product effortlessly adjusts to your needs, boosting efficiency and simplifying your tasks.",
    innovativeFunctionalityTitle: "Innovative Functionality",
    innovativeFunctionalityDescription: "Stay ahead with features that set new standards, addressing your evolving needs better than the rest.",
    precisionDetailTitle: "Precision in Every Detail",
    precisionDetailDescription: "Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.",
    ourTopSellingProducts: "Our Top Selling Products",
    faqTitle: "Frequently asked questions",
    faq1Question: "How do I contact customer support if I have a question or issue?",
    faq1Answer: "We're here to assist you promptly. You can reach our customer support team by emailing ",
    faq2Question: "Can I return the product if it doesn't meet my expectations?",
    faq2Answer: "Absolutely! We offer a hassle-free return policy. If you're not completely satisfied, you can return the product within 7 days for a full refund or exchange.",
    faq3Question: "What makes your product stand out from others in the market?",
    faq3Answer: "Our product distinguishes itself through its adaptability, durability, and innovative features. We prioritize user satisfaction and continually strive to exceed expectations in every aspect.",
    faq4Question: "Is there a warranty on the product, and what does it cover?",
    faq4Answer: "Yes, our product comes with a 1 year warranty. It covers defects in materials and workmanship. If you encounter any issues covered by the warranty, please contact our customer support for assistance.",
    available: "Available",
    now: "Now",
    games: "Games",
    about: "About",
    game: "Game",
    website: "Website",
    description: "Description",
    rating: "Rating",
    developer: "Developer",
    publisher: "Publisher",
    released: "Release Date",
    genre: "Genre",
    gallery: "Gallery",
    maxPrice: "Max Price",

    availableEditions: "Available Editions",
    availablePlatforms: "Available Platforms",
    addToCart: "Add to Cart",
    outOfStock: "Out of Stock",

    emptyCartHeading: "Cart's feeling light...",
    emptyCartSubheading: "Your cart is longing for some company. Begin your shopping adventure now!",
    exploreCatalog: "Explore our catalog",
    yourCart: "Your Cart",
    quantity: "Quantity",
    maxProductAllowed: "Maximum of {{max}} products allowed",
    total: "Total",
    goToCart: "Go to Cart",
    payNow: "Pay Now",
    goToPayment: "Go to Payment",
    discount: "Discount",
    originalPrice: "Original Price",
    checkMoreProducts: "Check more products",
    cartSummary: "Cart Summary",
    cartItems: "Cart Items",
    payment: "Payment",
    gameActivation: "Game Activation",
    login: "Login",
    remove: "Remove",

    Notification: "Notification",
    notificationsEnabled: "Browser notifications are enabled",
    notificationsEnableFail: "Failed to enable notifications",
    notificationsDisabled: "Browser notifications are disabled",
    notificationsDisableFail: "Failed to disable notifications",
    notificationsDenied: "Notification permission was denied",
    notificationsNoPermission: "Notification permission not granted",
    notificationsPermissionFaild: "Notification permission request failed",
    notificationsNotSupported: "Push messaging is not supported",
    notificationsFailToSubscribe: "Failed to subscribe the user",
    notificationsFailToUnsubscritbe: "Failed to unsubscribe the user",
    serviceWorkerNotReady: "Please reload the page and try again",
    notificationsSubscriptionErro: "Error getting subscription",
    browserNotifications: "Browser Notifications",

    noProductsFound: "No products found.",
    tryDifferentQuery: "Please try a different search query or reset the filters.",
    resetFilters: "Reset Filters",
    achievements: "Achievements",
    noFavorites: "Oops! No Loot Found!",
    noFavoritesText: "Time to hunt for some epic loot!",
    favorites: "Favorites",
    purchases: "Purchases",
    points: "Points",
    dashboard: "Dashboard",
    settings: "Settings",
    updateSettings: "Update Settings",
    security: "Security",
    invoiceNotFound: "Invoice Not Found",
    action: "Action",
    date: "Date",
    rowsPerPage: "Rows per page",
    aboutOurApi: "About Our API",
    aboutOurApiDescription: "Welcome to our API product page! Our API provides seamless access to over 10,000 products, ensuring that you can integrate and offer a wide range of items to your customers all the time. Whether you're building an e-commerce platform, a mobile app, or any other service, our API has you covered.",
    whyChooseOurApi: "Why Choose Our API?",
    whyChooseOurApiDescription: "Our API is designed to be robust, reliable, and easy to use. Here are some of the key features:",
    featureApi1: "Access to over 10,000 products",
    featureApi2: "Real-time product availability and pricing",
    featureApi3: "High availability with 99.9% uptime",
    featureApi4: "Comprehensive documentation and support",
    featureApi5: "Easy integration with RESTful endpoints",
    featureApi6: "Secure and scalable architecture",
    WithOurApi: "With our API, you can ensure your customers have access to a vast inventory of products at all times. Our high availability and real-time updates mean you'll never miss a sale.",

    aboutOurPosTerminals: "About Our POS Terminals,",
    welcomeMessage: "Welcome to our product page! We are proud to offer state-of-the-art POS terminals designed specifically for stores that sell games and subscription cards. Our terminals are easy to use, secure, and efficient, helping you manage your sales and inventory with ease,",
    whyChooseOurPosTerminals: "Why Choose Our POS Terminals?",
    posTerminalFeatures: "Our POS terminals come with a range of features to streamline your store operations:",
    feature1: "Seamless integration with your existing systems",
    feature2: "Fast and secure payment processing,",
    feature3: "Real-time inventory management,",
    feature4: "User-friendly interface for quick training,",
    feature5: "Customizable settings to fit your store's needs,",
    posTerminalDescription: "Whether you're selling physical games or digital subscription cards, our POS terminals are equipped to handle all your transactions efficiently. Enhance your customer experience and boost your sales with our reliable and versatile POS solutions,",
    invoiceNo: "Invoice No",

    howTo: "How to",
    activate: "Activate",
    step: "Step",
    giftToFriend: "Gift to a Friend",
    enterEmailTwice: "Please enter your friend's email address twice for confirmation.",
    friendEmail: "Friend's Email",
    confirmFriendsEmail: "Confirm Friend's Email",
    emailsDoNotMatch: "The email addresses do not match. Please try again.",
    sendGift: "Send Gift",
    giftSent: "Gift Sent",
    giftSentMessage: "We have successfully sent the key as a gift to your friend at the following email:",
    close: "Close",

    buyers: "Buyers",
    reviews: "Reviews",
    user: "User",
    comment: "Comment",

    requirements: "Requirements",
    minimumRequirements: "Minimum Requirements",
    recommendedRequirements: "Recommended Requirements",
    OS: "OS",
    Processor: "Processor",
    Memory: "Memory",
    Graphics: "Graphics",
    DirectX: "DirectX",
    Storage: "Storage",
    AdditionalNotes: "Additional Notes",

    careers: "Careers",
    contactUs: "Contact Us",
    updatePersonalData: "Update your billing data",
    updateApplicationSettings: "Update Application Settings",
    birthday: "Birthday",
    yourName: "Your Name",
    yourLastName: "Your Last Name",
    enterEmail: "Enter your email address",
    yourEmail: "Your email address",
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    showLess: "Show Less",
    showMore: "Show More",
    PageNotFound: "Page not found",
    DontGiveUp: "Don't give up, maybe you will find here what you are looking for.",
    Jobs: "Jobs",
    ElevateYourCareer: "Elevate your career with Bigswiss GmbH, where innovation and passion meet. Dive into a world where your work directly shapes the future of gaming and technology.",
    exploreOpportunities: "Explore opportunities, embrace challenges, and be part of a dynamic team dedicated to excellence. Your journey to make an impact starts here.",
    enterYourEmailAddress: "Enter your email address",
    yourEmailAddress: "Your email address",
    contactUsAgree: 'By clicking "Contact us" you agree to our',
    termsAndConditions: "Terms & Conditions",
    bigswissSupport: "Contact Bigswiss ",
    support: "Support",
    contactDescription: "From inquiries about our innovative online shop and wholesale store to assistance with our kiosk applications for video game purchases and the wholesale API store, we're here to elevate your experience.",
    connectTeam: "Connect with our dedicated team for top-tier features and services designed just for you.",

    pricingDescription: "Choose the plan that suits your needs",
    basic: "Basic",
    pro: "Pro",
    enterprise: "enterprise",
    getStarted: "Get Started",
    startNow: "Start Now",
    mostPopular: "Most Popular",
    perMonth: "per month",
    basicDesc1: "API access for up to 100 requests per month",
    basicDesc2: "Basic analytics and reporting",
    basicDesc3: "Standard support",
    proDesc1: "API access for up to 10,000 requests per month",
    proDesc2: "Advanced analytics and reporting",
    proDesc3: "Priority support",
    proDesc4: "Access to exclusive game and subscription card deals",
    enterpriseDesc1: "Unlimited API requests",
    enterpriseDesc2: "Custom analytics and reporting",
    enterpriseDesc3: "24/7 dedicated support",
    enterpriseDesc4: "Customized POS terminal solutions",
    enterpriseDesc5: "Best pricing on bulk purchases",

    chooseActivation: "Choose where you wish to activate your product.",
    thankYouForPurchase: "Thank you for your purchase!",
    findYourKeysBellow: "You can find your keys and invoice below.",
    productKey: "Product Key",
    productKeys: "Product Keys",
    productActivation: "Product Activation",
    productActivationDescription: "Please follow the instructions below to activate your product.",
    sendAsGift: "Gift",
    hide: "Hide",
    viewKeys: "View Keys",
    purchase_review_pending: "Your purchase is under review. You will receive an email once it is approved.",
    getting_key_please_wait: "Getting your key. Please wait...",
    downloadInvoice: "Download Invoice",
    download: "Download",
    back: "Back",
    next: "Next",
    activationMethod: "Activation Method",
    keylessActivation: "Keyless Activation",
    activationLink: "Activation Link",
    enterTheKey: "Enter the Key",
    confirmActivation: "Confirm Activation",
    battlenetStep1: "Click the button below to navigate to the Battle.net key activation page.",
    goToBattlenetActivation: "Go to Battle.net Activation Page",
    battlenetStep2: "Log in with your Battle.net credentials. If you don’t have an account, create one for free.",
    battlenetStep3: "Enter the key you received in the provided field, then click 'Redeem Code'.",
    battlenetStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Battle.net account.",
    goToActivation: "Go to Activation",
    battlenetStep2app: "Open the Blizzard Battle.net app. And log in with your Battle.net credentials. If you don’t have an account, create one for free.",
    battlenetStep3app: "Click on your profile to open a menu, then click on 'Redeem Code'",
    goToSteamActivation: "Go to Steam Activation Page",
    steamStep1: "Click the button below to navigate to the Steam key activation page.",
    steamStep2: "Log in with your Steam credentials. If you don’t have an account, create one for free.",
    steamStep3: "Read and agree to Steam Subscriber Agreement, then click 'Continue'.",
    steamStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Steam account.",
    steamStep2app: "Open the Steam app and log in with your Steam credentials. If you don’t have an account, create one for free.",
    steamStep3app: "Click on 'Games' in the top menu, then click on 'Activate a Product on Steam'.",
    steamStep4app: "Enter the key you received in the provided field, then click 'Confirm'.",

    goToMicrosoftActivation: "Go to Microsoft Store Activation Page",
    microsoftStep1: "Click the button below to navigate to the Microsoft Store key activation page.",
    microsoftStep2: "Log in with your Microsoft credentials. If you don’t have an account, create one for free.",
    microsoftStep3: "Enter the key you received in the provided field, then click 'Next'.",
    microsoftStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Microsoft account.",

    microsoftStep2app: "On your Windows system, open the Microsoft Store app and log in with your Microsoft credentials. If you don’t have an account, create one for free.",
    microsoftStep3app: "Click on profile logo in top right corner, then click on 'Redeem code or gift cards'.",

    goToGOGActivation: "Go to GOG Activation Page",
    activateKey: "Activate Key",
    linkToAccount: "Link to Account",
    gogStep1: "Click the button below to navigate to the GOG key activation page.",
    gogStep2: "Click on 'Continue' to confirm key activation.",
    gogStep3: "Clic on 'Sign In' to link the key to your GOG account.",
    gogStep4: "Log in with your GOG credentials. If you don’t have an account, create one for free.",
    gogStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your GOG account.",

    gogStep2app: "Open the GOG Galaxy app and log in with your GOG credentials. If you don’t have an account, create one for free.",
    gogStep3app: "Click on 'Add games & friends' in the top menu, then click on 'Redeem GOG code'.",
    gogStep4app: "Enter the key you received in the provided field, then click 'Continue'.",

    goToEpicActivation: "Go to Epic Games Activation Page",
    epicStep1: "Click the button below to navigate to the Epic Games key activation page.",
    epicStep2: "Click on 'Redeem' to confirm key activation.",
    epicStep3: "Click on 'Sign In' to link the key to your Epic Games account.",
    epicStep4: "Log in with your Epic Games credentials. If you don’t have an account, create one for free.",
    epicStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Epic Games account.",

    epicStep2app: "Open the Epic Games app and log in with your Epic Games credentials. If you don’t have an account, create one for free.",
    epicStep3app: "Click on your profile in the top right corner, then click on 'Redeem Code'.",
    epicStep4app: "Enter the key you received in the provided field, then click 'Redeem'.",

    goToRockstarActivation: "Go to Rockstar Activation Page",
    rockstarStep1: "Click the button below to navigate to the Rockstar key activation page.",
    rockstarStep2: "Log in with your Rockstar credentials. If you don’t have an account, create one for free.",
    rockstarStep3: "Please wait for page to load, and Rockstar validation to complete. It can take up for several minutes. Once the page is loaded you will see confirmation message. Click on 'Confirm' to activate the key to your Rockstar account.",
    rockstarStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Rockstar account.",
    rockstarStep2app: "Open the Rockstar Games Launcher app and log in with your Rockstar credentials. If you don’t have an account, create one for free.",
    rockstarStep3app: "Click on the menu in the top righ corner, then click on 'REDEEM CODE'.",
    rockstarStep4app: "Enter the key you received in the provided field, then click 'CHECK'.",
    rockstarStep5app: "Read and agree to the terms and conditions, then click 'REDEEM'.",

    goToEAPlayActivation: "Go to EA Play Activation Page",
    eaStep1: "Click the button below to navigate to the EA Play key activation page.",
    eaStep2: "Log in with your EA Play credentials. If you don’t have an account, create one for free.",
    eaStep3: "Enter the key you received in the provided field, then click 'Next'.",
    eaStep4: "Click on the 'Next' button to link the key to your EA Play account.",
    eaStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your EA Play account.",

    eaStep2app: "Open the EA Play app and log in with your EA Play credentials. If you don’t have an account, create one for free.",
    eaStep3app: "Click on the menu in the top right corner, then click on 'Redeem Code'.",
    eaStep4app: "Enter the key you received in the provided field, then click 'Next'.",

    ubisoftStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Ubisoft account.",
    ubisoftStep2app: "Open the Ubisoft app and log in with your Ubisoft credentials. If you don’t have an account, create one for free.",
    ubisoftStep3app: "Click on the profile menu in the top left corner, then click on 'Key Activation'.",
    ubisoftStep4app: "Enter the key you received in the provided field, then click 'Activate'.",

    goToSwitchActivation: "Go to Nintendo Switch Activation Page",
    openeshop: "Open eShop",
    selectUser: "Select User",
    redeem: "Redeem Key",
    switchStep1: "Click the button below to navigate to the Nintendo Switch key activation page.",
    switchStep2: "Log in with your Nintendo Switch credentials. If you don’t have an account, create one for free.",
    switchStep3: "Enter the key you received in the provided field, then click 'Continue'.",
    switchStep4: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Nintendo Switch account.",
    switchStep1app: "Open the Nintendo eShop app.",
    switchStep2app: "Select account where you wish to activate the product.",
    switchStep3app: "Enter the key you received in the provided field, then click 'Confirm'.",
    switchStep4app: "Select 'Redeem' and click 'Confirm' to activate the product to your Nintendo Switch account.",

    goToPlayStationActivation: "Go to PlayStation Activation Page",
    openLogin: "Open Login",
    psStep1: "Click the button below to navigate to the PlayStation key activation page.",
    psStep2: "In top right corner click on 'Loign'",
    psStep3: "Log in with your PlayStation credentials. If you don’t have an account, create one for free.",
    psStep4: "In top right corner click on your profile, then click on 'Redeem Code'.",
    psStep5: "Enter the key you received in the provided field, then click 'Continue'.",
    psStep6: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your PlayStation account.",
    openStore: "Open Store",
    psStep2console: "Select the Playstation Store on the left and ress 'X'.",
    psStep3console: "Press '△' to acces menu on the top right corner, select '...' and select 'Redeem Code'.",
    psStep4console: "Enter the key you received in the provided field, then click 'Redeem'.",
    psStep2app: "Open the PlayStation app, and open the PlayStation store.",
    openMenu: "Open Menu",
    psStep3app: "Select the menu on the top right corner, to view more option",
    psStep4app: "Select 'Redeem Code'",

    goToXboxActivation: "Go to Xbox Activation Page",
    xboxStep1: "Click the button below to navigate to the Xbox key activation page.",
    xboxStep2: "Click on 'Sign in to redeem' to log in with your Xbox credentials. If you don’t have an account, create one for free.",
    xboxStep3: "Click on 'Redeem now' to to open key activation modal.",
    xboxStep4: "Enter the key you received in the provided field, then click 'Next'. And continue following the instructions",
    xboxStep5: "Congratulations! You have successfully activated your product, and it is ready to be downloaded and played on your Xbox account.",
    xboxStep2console: "Press the Xbox button on your controller to open the guide. While signed with your account, select 'Store'.",
    xboxStep3console: "Once opened, press the view button on your controller to open the menu, then select 'Redeem'.",
    xboxStep4console: "Enter the key you received in the provided field, then click 'Check code'.",

    warningModalTitle: "Important Notice",
    warningModalMessage1: "Once you reveal the key, you lose the right for an instant refund.",
    warningModalMessage2: "You will only be eligible for a refund if the key is invalid.",
    doYouWantToProceed: "Do you wish to proceed?",
    revealKey: "Reveal Key",

    // chat 
    helpCenter: "Help Center",
    talkWithourSupport: "Talk with support",
    haveAquestion: "Have a question?",
    typeAmessage: "Type a message...",
    hello: "Hello",
    howCanIHelpYou: "How can I help you?",
    home: "Home",
    history: "History",
    help: "Help",
    noChatMessages: "You dont have any chat history yet.",
    allHelpTopics: "All Help Topics",
    searchTopics: "Search topics...",
    noTopicsFound: "No topics found.",

    redeemingCodesCategory: "Redeeming Codes",
    findingCodesCategory: "Finding Codes",
    invoicesCategory: "Invoices",
    codeActivationIssueCategory: "Code Activation Issues",
    identityVerificationCategory: "Identity Verification",

    howToRedeem: "How to redeem the code?",
    whereToFindCodes: "Where can I find my codes?",
    whereToGetInvoice: "Where can I get an invoice for my purchase?",
    issuesActivatingCode: "I have issues activating my code",
    issuesIdentityVerification: "I have issues with my identity verification",

    howToRedeemDesc: "Follow the steps below to successfully redeem your code:",
    findingCodesDesc: "Follow the steps below to find your code:",
    invoicesDesc: "To access an invoice for your purchase, follow the steps below:",
    activationIssueDesc: "If you're experiencing problems activating your code, please check the following:",
    identityVerificationDesc: "If you're facing challenges with identity verification, please ensure that:",

    accessAccountTitle: "Access Your Account",
    accessAccountDesc: "Click on the user icon located at the top right corner of the page.",

    navigatePurchasesTitle: "Navigate to Purchases",
    navigatePurchasesDesc: "In the dropdown menu, select the 'Purchases' option to view your recent transactions.",

    selectRelevantPurchaseTitle: "Select the Relevant Purchase",
    selectRelevantPurchaseDesc: "From the purchases table, select the transaction associated with the key you wish to activate.",

    viewKeysTitle: "View Available Keys",
    viewKeysDesc: "Click on the 'View Keys' button to display a list of available product keys for activation.",

    revealKeyTitle: "Reveal the Key",
    revealKeyDesc: "Click the eye icon to reveal the key you wish to activate.",

    activateKeyTitle: "Activate the Key",
    activateKeyDesc: "Click on the 'Activate' button and follow the on-screen instructions.",

    downloadInvoiceTitle: "Download Invoice",
    downloadInvoiceDesc: "Click on the 'Download Invoice' button to access a PDF copy of your invoice.",

    checkTyposTitle: "Check for Typos",
    checkTyposDesc: "Ensure the code is entered correctly without typos.",

    verifyUsageTitle: "Verify Code Usage",
    verifyUsageDesc: "Verify that the code hasn't been used previously.",

    correctPlatformTitle: "Correct Platform",
    correctPlatformDesc: "Confirm that you're using the code in the correct platform or application.",

    clearDocumentsTitle: "Clear Documents",
    clearDocumentsDesc: "Your identification documents are clear and legible.",

    matchPersonalInfoTitle: "Match Personal Info",
    matchPersonalInfoDesc: "Your personal information matches the records.",

    supportedVerificationMethodTitle: "Supported Method",
    supportedVerificationMethodDesc: "You are using a supported verification method.",

    notesContactSupport: "If the issue persists, please contact our support team for further assistance.",
    stillHavingIssue: "Still having issues with"
  },
};
