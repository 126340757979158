import React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import platformIcons from './platformLogosMap';

interface PlatformLogoProps extends AvatarProps {
  platformId?: string;
  regionId?: string;
}

const PlatformLogo: React.FC<PlatformLogoProps> = ({ platformId, regionId, ...rest }) => {
  const IconComponent = platformId ? platformIcons[platformId] : undefined;
  const regionFlagSrc = regionId ? `/img/flags/${regionId.toLowerCase()}.svg` : undefined;

  if (regionId && regionFlagSrc) {
    return (
      <Avatar
        src={regionFlagSrc}
        variant="circular"
        {...rest}
      />
    );
  }

  if (platformId && IconComponent) {
    return (
      <Avatar
        component={'span'}
        variant="square"
        {...rest}
      >
        <IconComponent />
      </Avatar>
    );
  }

  return null;
};

export default PlatformLogo;