// VerifyIdentity.tsx
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import VerificationStatus from './VerificationStatus';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { setUpdateIdentityRequireCheck } from 'redux/auth/authSlice';
import { setWelcomePageLoading } from 'redux/auth/authSlice';


interface VerifyIdentityProps {
  onComplete: () => void;
}

const VerifyIdentity: React.FC<VerifyIdentityProps> = ({ onComplete }) => {
  // 1) Use our custom hook
  const verificationStatus = useSelector((state: RootState) => state.auth.verificationStatus);

  const [hasCompleted, setHasCompleted] = useState(false);
  const dispatch = useDispatch();

  // 2) If verification is success, mark complete & call onComplete
  useEffect(() => {
    if (verificationStatus === 'verification_success' && !hasCompleted) {
      setHasCompleted(true);
      dispatch(setUpdateIdentityRequireCheck(false));
      dispatch(setWelcomePageLoading(true));
      onComplete();
    }
  }, [verificationStatus, hasCompleted, dispatch, onComplete]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <VerificationStatus
        paymentStatus={verificationStatus}
        fallbackMessage="We detected unusual activity on your account. Please verify your identity to continue."
      />
    </Box>
  );
};

export default VerifyIdentity;