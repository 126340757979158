// File: src/pages/ProductPage/ProductPage.tsx

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/store';
import { setLanguage } from 'redux/auth/authSlice';

import { Container } from '@mui/material';

import { ProductCover } from './Components/ProductCover';
import { ProductCart } from './Components/ProductCart';
import { ProductAbout } from './Components/ProductAbout';
import { ProdcutDescription } from './Components/ProdcutDescription';
import { ProductGallery } from './Components/ProductGallery';
import { ProductRequirements } from './Components/ProductRequirements';
import { ProductReviews } from './Components/ProductReviews';
import NoDataFound from 'components/NoDataFound';
import { ProductLoading } from './Components/ProductLoading';

import {
  useFetchProductWithTitleQuery,
  useFetchProductDetailByIdQuery
} from 'redux/RTK/productsApi';
import {
  useFetchCategoriesQuery,
  useFetchPlatformsQuery,
  useFetchDevelopersQuery,
  useFetchPublishersQuery,
  useFetchGenresQuery,
  useFetchRatingsQuery
} from 'redux/RTK/optionsApi';


import { getProductImgLg } from 'utils/getNames';
import SEO from 'utils/SEO';
import { ProductSuggestions } from './Components/ProductSuggestion';

export default function ProductPage() {
  const { categoryId, platformName, productName } = useParams<{
    categoryId?: string;
    platformName?: string;
    productName?: string;
  }>();
  const dispatch = useDispatch();

  // We'll read the language from store, or default to something
  const language = useSelector((state: RootState) => state.auth.language);

  // We'll load local userLanguage from localStorage if available
  useEffect(() => {
    const storedLanguage = localStorage.getItem('userLanguage');
    if (storedLanguage && storedLanguage !== language) {
      dispatch(setLanguage(storedLanguage));
    }
  }, [dispatch, language]);

  // 1) Use RTK Query to fetch product by ID (skip if no productId)
  const {
    data: product,
    isLoading: isProductLoading,
    isError: isProductError,
  } = useFetchProductWithTitleQuery(productName!, {
    skip: !productName,
  });

  // 2) Use RTK Query to fetch product detail by ID (and lang)
  const {
    data: productDetail,
    isLoading: isDetailLoading,
    isError: isDetailError,
  } = useFetchProductDetailByIdQuery(
    { productId: product?._id!, lang: language },
    { skip: !product?._id }
  );

  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: categories = [] } = useFetchCategoriesQuery();
  const { data: developers = [] } = useFetchDevelopersQuery();
  const { data: publishers = [] } = useFetchPublishersQuery();
  const { data: genres = [] } = useFetchGenresQuery();
  const { data: ratings = [] } = useFetchRatingsQuery();

  // Combined loading & error states
  const isLoading = isProductLoading || isDetailLoading;
  const isErrorState = isProductError || isDetailError;

  // If either is loading => show loading
  if (isLoading) {
    return <ProductLoading />;
  }
  // If error or no data => NoDataFound
  if (isErrorState || !product || !productDetail) {
    return <NoDataFound text="Product not found or details are missing." />;
  }

  // *** Continue with local logic ***

  // decode platform from the param (like 'steam' => 'Steam')
  const decodedPlatformName = platformName?.replace(/-/g, ' ').toLowerCase();
  const matchedPlatform = platforms.find((p) => p.name.toLowerCase() === decodedPlatformName);


  const developerName =
    developers.find((dev) => dev._id === productDetail.developer)?.name || 'Unknown Developer';
  const publisherName =
    publishers.find((pub) => pub._id === productDetail.publisher)?.name || 'Unknown Publisher';

  // The user used to do product?.platforms, but now we do `product.platforms`
  const selectedPlatform = product.platforms.find((p) => p.platformId === matchedPlatform?._id)
    ?.platformId;

  const ratingNameId = (productDetail.ratings ?? []).map((ratingId) => {
    const foundRating = ratings.find((rat) => rat._id === ratingId);
    return { id: ratingId, name: foundRating?.name || 'Unknown Rating' };
  });

  const genresWithId = (product.genre ?? []).map((genreId) => {
    const foundGenre = genres.find((gen) => gen._id === genreId);
    return { id: genreId, name: foundGenre?.name || 'Unknown Genre' };
  });


  if (!matchedPlatform) {
    return <NoDataFound text="Platform information not found." />;
  }

  const platformDetail = product.platforms.find(
    (platform) => platform.platformId === matchedPlatform._id
  );
  if (!platformDetail) {
    return <NoDataFound text="Platform details not found." />;
  }

  const currentCategory = categories.find((cat) => cat._id === categoryId);
  const isPC = currentCategory?.name.toLowerCase() === 'pc';

  return (
    <>
      <SEO
        title={product.title}
        description={productDetail.description}
        keywords={`${genresWithId.join(', ')}, Bigswiss, video games, digital codes, gift cards, game points, subscription cards, gaming, online marketplace`}
        image={getProductImgLg(product._id)}
      />

      <ProductCover productCoverImg={product._id} />

      <Container
        sx={{
          mt: { xs: '-130px', sm: '-340px' },
          gap: 6,
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <ProductCart
          product={product}
          productDetail={productDetail}
          platformDetail={platformDetail}
          categoryId={categoryId || ''}
          platformName={matchedPlatform.name || ''}
          ProductPrice={platformDetail.price}
          selectedPlatform={selectedPlatform || ''}
          platformGroupId={platformDetail._id}
        />



        <ProductAbout
          ProductDescription={productDetail.description || 'Description not available'}
          Ratings={ratingNameId}
          Developer={developerName}
          Publisher={publisherName}
          Genres={genresWithId}
          ReleaseDate={productDetail.releaseDate}
          Website={productDetail.website}
          metacritic={productDetail.metacritic}
        />



        <ProductGallery
          productDetails={productDetail}
          title={product.title} productId={product._id} video={productDetail.video} />

        <ProdcutDescription
          ProductDescription={productDetail.descriptionLong || 'Long Description is missing'}
        />

        {isPC && productDetail.requirements && (
          <ProductRequirements
            minimumRequirements={productDetail.requirements.minimumRequirements}
            recommendedRequirements={productDetail.requirements.recommendedRequirements}
            minimumRequirementsMac={productDetail.requirements_mac?.minimumRequirements}
            recommendedRequirementsMac={productDetail.requirements_mac?.recommendedRequirements}
            minimumRequirementsLinux={productDetail.requirements_linux?.minimumRequirements}
            recommendedRequirementsLinux={productDetail.requirements_linux?.recommendedRequirements}
          />
        )}

        <ProductSuggestions
          platformDetail={platformDetail}
          product={product} />

        {productDetail.reviews && productDetail.reviews.length > 0 && (
          <ProductReviews reviews={productDetail.reviews} />
        )}
      </Container>
    </>
  );
}