// File: src/redux/RTK/optionsApi.ts

import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  Category,
  Platform,
  Region,
  Type as ProductType,
  Developer,
  Publisher,
  Genre,
  Rating,
  Countries,
  Settings,
} from '../types/dataTypes';

import { axiosBaseQuery } from '../axiosBaseQuery'; // your custom base query

export const optionsApi = createApi({
  reducerPath: 'optionsApi',

  // 1) Use your custom axios base query with baseUrl = '' 
  //    because your axios instance is already using /api as baseURL
  baseQuery: axiosBaseQuery({ baseUrl: '' }),

  tagTypes: [
    'Platforms',
    'Developers',
    'Publishers',
    'Genres',
    'Ratings',
    'Regions',
    'Types',
    'Categories',
    'Countries',
    'Settings',
  ],
  endpoints: (builder) => ({
    // 1) fetchPlatforms
    fetchPlatforms: builder.query<Platform[], void>({
      query: () => ({
        url: '/platform',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Platforms', id: 'LIST' }]
          : [{ type: 'Platforms', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 2) fetchDevelopers
    fetchDevelopers: builder.query<Developer[], void>({
      query: () => ({
        url: '/developer',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Developers', id: 'LIST' }]
          : [{ type: 'Developers', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 3) fetchPublishers
    fetchPublishers: builder.query<Publisher[], void>({
      query: () => ({
        url: '/publisher',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Publishers', id: 'LIST' }]
          : [{ type: 'Publishers', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 4) fetchGenres
    fetchGenres: builder.query<Genre[], void>({
      query: () => ({
        url: '/genre',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Genres', id: 'LIST' }]
          : [{ type: 'Genres', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 5) fetchRatings
    fetchRatings: builder.query<Rating[], void>({
      query: () => ({
        url: '/rating',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Ratings', id: 'LIST' }]
          : [{ type: 'Ratings', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 6) fetchRegions
    fetchRegions: builder.query<Region[], void>({
      query: () => ({
        url: '/region',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Regions', id: 'LIST' }]
          : [{ type: 'Regions', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 7) fetchTypes
    fetchTypes: builder.query<ProductType[], void>({
      query: () => ({
        url: '/product-type',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Types', id: 'LIST' }]
          : [{ type: 'Types', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 8) fetchCategories
    fetchCategories: builder.query<Category[], void>({
      query: () => ({
        url: '/category',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Categories', id: 'LIST' }]
          : [{ type: 'Categories', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // 9) fetchCountries
    fetchCountries: builder.query<Countries[], void>({
      query: () => ({
        url: '/countries',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Countries', id: 'LIST' }]
          : [{ type: 'Countries', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),

    // fetch a single country by code
    fetchCountryByCode: builder.query<Countries, string>({
      query: (code) => ({
        url: `/countries/code/${code}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, code) => [{ type: 'Countries', id: code }],
    }),

    // 10) fetchSettings
    fetchSettings: builder.query<Settings, void>({
      query: () => ({
        url: '/product/settings',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Settings', id: 'LIST' }]
          : [{ type: 'Settings', id: 'LIST' }],
      keepUnusedDataFor: 3600,
    }),
  }),
});

// Auto-generated hooks
export const {
  useFetchPlatformsQuery,
  useFetchDevelopersQuery,
  useFetchPublishersQuery,
  useFetchGenresQuery,
  useFetchRatingsQuery,
  useFetchRegionsQuery,
  useFetchTypesQuery,
  useFetchCategoriesQuery,
  useFetchCountriesQuery,
  useFetchCountryByCodeQuery,
  useFetchSettingsQuery,
} = optionsApi;