// src/services/userApi.ts
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'redux/axiosBaseQuery';
// or wherever you placed axiosBaseQuery
import type { FavoriteItem } from '../auth/authTypes';

interface AddFavoriteResponse {
  message: string;
  favorites?: FavoriteItem[];
}

interface RemoveFavoriteResponse {
  message: string;
  favorites?: FavoriteItem[];
}

export interface InvoiceItem {
  productId: string;
  platformGroupId: string;
  price: number;
  key: string;
  quantity: number;
}

export interface Invoice {
  _id: string;
  userId: string;
  userEmail: string;
  userName: string;
  userAddress: string;
  userCity: string;
  userZip: string;
  userCountry: string;
  items: InvoiceItem[];
  total: number;
  paymentMethod: string;
  paymentId: string;
  currency: string;
  invoiceNumber: string;
  status: 'preorder' | 'paid';
  createdAt: string;
  version: number;
}

// For fetching a single invoice by any of the 3 keys
// Only one is typically needed, but we keep them optional
export interface FetchInvoiceDetailArgs {
  invoiceId?: string;         // e.g. _id from Mongo
  invoiceNumber?: string;     // e.g. 'INV-0012345'
  paymentId?: string;         // e.g. 'pi_3Ccv4Z...'
}

interface ChatHistoryItem {
  category: string;
  topic: string;
  content: string;
  status: string
}

interface IMessage {
  ticketId?: string;
  sender: 'user' | 'ai' | 'support';
  message: string;
  socketRoomId?: string;
  sentAt?: Date;
  seen?: boolean;
}

interface ITicket extends Document {
  _id: string;
  userId?: string | null;    // can be null if it's an anonymous guest
  guestSessionId?: string | null;
  topic?: string;
  category?: string;
  status: 'ongoing' | 'solved';
  messages: IMessage[];
  createdAt: Date;
  updatedAt: Date;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  // Instead of fetchBaseQuery, we use axiosBaseQuery with our custom axios instance
  baseQuery: axiosBaseQuery({ baseUrl: '/users' }),
  tagTypes: ['Favorites', 'Invoices', 'Tickets'],
  endpoints: (builder) => ({
    /**
     * GET /favorites
     */
    fetchUserFavorites: builder.query<FavoriteItem[], void>({
      query: () => ({
        url: '/favorites',
        method: 'GET',
      }),
      providesTags: ['Favorites'],
    }),

    /**
     * GET /invoices
     */
    fetchUserInvoices: builder.query<Invoice[], void>({
      query: () => ({
        url: '/invoices',
        method: 'GET',
      }),
      providesTags: ['Invoices'],
    }),

    /**
     * POST /favorites/add
     * body: { productId, platformGroupId }
     */
    addFavorite: builder.mutation<AddFavoriteResponse, FavoriteItem>({
      query: (favoriteData) => ({
        url: '/favorites/add',
        method: 'POST',
        data: favoriteData, // <-- note 'data' field for axios
      }),
      invalidatesTags: ['Favorites'],
    }),

    /**
     * POST /favorites/remove
     * body: { platformGroupId }
     */
    removeFavorite: builder.mutation<RemoveFavoriteResponse, string>({
      query: (platformGroupId) => ({
        url: '/favorites/remove',
        method: 'POST',
        data: { platformGroupId },
      }),
      invalidatesTags: ['Favorites'],
    }),

    /**
     * GET /invoices/detail?invoiceId|invoiceNumber|paymentId
     */
    fetchInvoiceDetail: builder.query<Invoice, FetchInvoiceDetailArgs>({
      query: ({ invoiceId, invoiceNumber, paymentId }) => {
        // build querystring manually or with URLSearchParams
        const params: Record<string, string> = {};
        if (invoiceId) params.invoiceId = invoiceId;
        if (invoiceNumber) params.invoiceNumber = invoiceNumber;
        if (paymentId) params.paymentId = paymentId;

        return {
          url: '/invoices/detail',
          method: 'GET',
          params, // axios will convert to ?invoiceId=xxx etc.
        };
      },
      providesTags: ['Invoices'],
    }),

    // ─────────────────────────────────────────────────────────────────────────
    //  1) GET /tickets => returns array of user's tickets
    // ─────────────────────────────────────────────────────────────────────────
    fetchUserTickets: builder.query<ITicket[], void>({
      query: () => ({
        url: '/tickets',
        method: 'GET',
      }),
      providesTags: ['Tickets'],
    }),

    // ─────────────────────────────────────────────────────────────────────────
    //  2) GET /tickets/:ticketId => returns detail for a single ticket
    // ─────────────────────────────────────────────────────────────────────────
    fetchUserTicketDetail: builder.query<ITicket, string>({
      query: (ticketId) => ({
        url: `/tickets/${ticketId}`,
        method: 'GET',
      }),
      // We can provide a more specific tag for this ticket ID if desired:
      providesTags: (result, error, ticketId) => [{ type: 'Tickets', id: ticketId }],
    }),

  }),
});

// Auto-generated React hooks
export const {
  useFetchUserFavoritesQuery,
  useFetchUserInvoicesQuery,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
  useFetchInvoiceDetailQuery,

  // new ticket hooks
  useFetchUserTicketsQuery,
  useFetchUserTicketDetailQuery,
} = userApi;