import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';

interface LoadingAnimationProps {
  onAnimationComplete: () => void; // Callback prop
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ onAnimationComplete }) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(200);
  const [isFadeOut, setIsFadeOut] = useState(false);
  const { t } = useTranslation();

  const texts = [
    t('hello'),
    t('howCanIHelpYou'),
  ];

  useEffect(() => {
    if (isFadeOut) return; // Stop typing if fade-out has started

    const handleTyping = () => {
      const currentIndex = loopNum % texts.length;
      const fullText = texts[currentIndex];
      const updatedText = isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (!isDeleting && updatedText === fullText) {
        // Pause before starting to delete
        setTimeout(() => setIsDeleting(true), 1800);
        setTypingSpeed(50);
      } else if (isDeleting && updatedText === '') {
        // Move to next text after deletion
        setIsDeleting(false);
        setTypingSpeed(100);
        setLoopNum(prev => prev + 1);
      }
    };

    const typingTimer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimer);
  }, [text, isDeleting, typingSpeed, loopNum, isFadeOut]);

  useEffect(() => {
    // Trigger fade-out after completing all text loops
    if (loopNum === texts.length) {
      setIsFadeOut(true);
    }
  }, [loopNum, texts.length]);


  const handleTransitionEnd = () => {
    if (isFadeOut) {
      onAnimationComplete(); // Notify parent after fade-out
    }
  };

  return (
    <Grid
      size={{ xs: 12 }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        position: 'absolute',
        zIndex: 1,
        opacity: isFadeOut ? 0 : 1, // Control opacity for fade-out
        transition: 'opacity 1s', // Duration of fade-out
      }}
      onTransitionEnd={handleTransitionEnd} // Listen for transition completion
    >
      <div className="circles" style={{ marginRight: '10%' }}>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <Box sx={{ position: 'absolute', zIndex: 10, textAlign: 'center' }}>
        <Typography
          variant="h4"
          color="text.primary"
          className="typing-effect"
          sx={{ transition: 'opacity 0.6s', fontWeight: 900 }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default LoadingAnimation;
