// --- FULL SPANISH TRANSLATIONS ---
export const esResources = {
  translation: {
    languageChanged: "Idioma cambiado",
    selectedLanguage: "Español ha sido seleccionado",
    dark: "Oscuro",
    light: "Claro",
    systemPreferences: "Preferencias del sistema",
    changeApplicationTheme: "Selecciona el tema de la aplicación",
    applicationTheme: "Tema de la aplicación",
    darkDescription: "¡Bienvenido al lado oscuro, tenemos galletas!",
    lightDescription: "¡Abraza la luz, noble jugador!",
    autoDescription: "¡Permite que tu sistema decida tu destino!",
    welcome: "Bienvenido",
    welcomeToBigswiss: "Bienvenido a Bigswiss",
    completeProfileMessage: "Por favor completa tu perfil",
    selectLanguage: "Selecciona el idioma predeterminado",
    selectLanguageLabel: "Seleccionar idioma",
    gdprFadpDescription: "Utilizamos cookies para garantizar el correcto funcionamiento de nuestro sitio web y para mejorar tu experiencia. Al seleccionar 'Aceptar esenciales', das tu consentimiento para el uso de cookies esenciales necesarias para el funcionamiento básico, incluidas cookies de terceros de Stripe para procesar pagos de forma segura. Al seleccionar 'Aceptar todas', también nos permites usar cookies opcionales, como las de análisis (Google Analytics) y publicidad, para mejorar aún más nuestros servicios.",
    acceptTerms: "Acepto los términos y condiciones.",
    acceptNecessary: "Sólo esenciales",
    acceptAll: "Aceptar todas",
    termsWarning: "Por favor, acepta los términos y condiciones para continuar.",
    selectCurrency: "Selecciona moneda",
    newsletter: "Boletín",
    subscribeMessage: "Suscríbete a nuestro boletín para recibir actualizaciones y promociones semanales.",
    subscribe: "Suscribirse",
    products: "Productos",
    position: "Posición",
    message: "Mensaje",
    invalidEmail: "Correo electrónico no válido",
    allFieldsRequired: "Por favor, completa todos los campos requeridos",
    messageSent: "Mensaje enviado",
    contacted: "Solicitud Enviada",
    jobThankYou: "Gracias por tu interés. Nos pondremos en contacto contigo pronto.",
    duplicateApplication: "Ya has aplicado, por favor espera a que nos contactemos contigo.",
    duplicateSubscription: "Ya estás suscrito.",
    succesSubcribed: "Te has suscrito exitosamente.",
    company: "Empresa",
    getInTouch: "Ponte en contacto",
    privacyPolicy: "Política de privacidad",
    termsOfService: "Términos de servicio",
    pcGames: "Juegos de PC",
    xboxGames: "Juegos de XBOX",
    playstationGames: "Juegos de PlayStation",
    nintendoGames: "Juegos de Nintendo",
    giftCards: "Tarjetas de regalo",
    subscriptionCards: "Tarjetas de suscripción",
    aboutUs: "Sobre nosotros",
    testimonials: "Testimonios",
    testimonialsDescription: "Descubre lo que nuestros clientes aman de nuestras funciones. Descubre cómo destacamos en eficiencia y satisfacción. Únete a nosotros para disfrutar de costos bajos, innovación en ventas minoristas y soporte confiable.",
    highlights: "Destacados",
    pricing: "Precios",
    faqs: "Preguntas frecuentes",
    giftAndSubscriptionCards: "Tarjetas de regalo y suscripción",
    checkTopSellers: "Revisar los más vendidos",
    platforms: "Plataformas",
    category: "Categoría",
    platform: "Plataforma",
    contactSupportText: "Si deseas cambiar la región de tu cuenta, por favor contacta con nuestro soporte",
    region: "Región",
    productType: "Tipo de producto",
    none: "Ninguno",
    sort: "Ordenar",
    priceIncreasing: "Precio ascendente",
    priceDecreasing: "Precio descendente",
    consoles: "Consolas",
    aboutUsDescription: "Desde 2002, Bigswiss.ch ha sido tu destino principal para juegos digitales y tarjetas de regalo, operando con orgullo desde el corazón de Suiza. Con más de dos décadas de experiencia, nos comprometemos a traerte lo último y lo mejor en el mundo del entretenimiento digital.\n\nNuestra amplia selección incluye una gran variedad de juegos digitales para todas las plataformas populares, así como una variedad de tarjetas de regalo para tus servicios favoritos. Tanto si eres un jugador experimentado en busca del próximo gran lanzamiento como si buscas el regalo perfecto para un ser querido, Bigswiss.ch tiene lo que necesitas.\n\nEn Bigswiss.ch, nos enorgullecemos de ofrecer un servicio al cliente excepcional y una experiencia de compra sin complicaciones. Nuestro equipo dedicado siempre está aquí para ayudarte, garantizando que tu experiencia con nosotros sea fluida y placentera.",
    onlineStoreTitle: "Tienda en línea",
    onlineStoreDescription: "Compra más de 10.000 videojuegos y productos digitales diferentes",
    posTerminalsTitle: "Terminales POS",
    posTerminalsDescription: "Integra nuestros terminales POS en tu tienda y obtén acceso a todos nuestros juegos",
    wholesaleApiTitle: "API al por mayor",
    wholesaleApiDescription: "Integra nuestra API al por mayor en tu tienda en línea",
    learnMore: "Más información",
    goToStore: "Ir a la tienda",
    highlightsDescription: "Descubre por qué nuestro producto destaca: adaptabilidad, durabilidad, diseño fácil de usar e innovación. Disfruta de un soporte al cliente confiable y la máxima atención al detalle.",
    instantDeliveryTitle: "Entrega instantánea",
    instantDeliveryDescription: "Recibe tus códigos digitales al instante después de la compra.",
    digitalDownloadTitle: "Descarga digital",
    digitalDownloadDescription: "Obtén acceso a tus juegos y servicios favoritos con códigos fáciles de canjear.",
    customerSupportTitle: "Atención al cliente 24/7",
    customerSupportDescription: "Estamos aquí para ti en cualquier momento, cualquier día, listos para resolver cualquier problema.",
    adaptablePerformanceTitle: "Rendimiento adaptable",
    adaptablePerformanceDescription: "Nuestro producto se ajusta fácilmente a tus necesidades, aumentando la eficiencia y simplificando tus tareas.",
    innovativeFunctionalityTitle: "Funcionalidad innovadora",
    innovativeFunctionalityDescription: "Mantente a la vanguardia con características que establecen nuevos estándares, satisfaciendo tus necesidades cambiantes mejor que nadie.",
    precisionDetailTitle: "Precisión en cada detalle",
    precisionDetailDescription: "Disfruta de un producto cuidadosamente elaborado en el que los pequeños detalles marcan una gran diferencia en tu experiencia.",
    ourTopSellingProducts: "Nuestros productos más vendidos",
    faqTitle: "Preguntas frecuentes",
    faq1Question: "¿Cómo puedo contactar al servicio de atención al cliente si tengo una pregunta o problema?",
    faq1Answer: "Estamos aquí para ayudarte rápidamente. Puedes contactar con nuestro equipo de soporte enviando un correo electrónico a ",
    faq2Question: "¿Puedo devolver el producto si no cumple con mis expectativas?",
    faq2Answer: "¡Por supuesto! Ofrecemos una política de devoluciones sin complicaciones. Si no quedas completamente satisfecho, puedes devolver el producto dentro de los 7 días para obtener un reembolso completo o un cambio.",
    faq3Question: "¿Qué hace que su producto se destaque de otros en el mercado?",
    faq3Answer: "Nuestro producto se distingue por su adaptabilidad, durabilidad y características innovadoras. Priorizamos la satisfacción del usuario y nos esforzamos continuamente por superar las expectativas en todos los aspectos.",
    faq4Question: "¿Hay una garantía en el producto y qué cubre?",
    faq4Answer: "Sí, nuestro producto cuenta con una garantía de 1 año. Cubre defectos de materiales y mano de obra. Si encuentras algún problema cubierto por la garantía, ponte en contacto con nuestro equipo de soporte para obtener ayuda.",
    available: "Disponible",
    now: "Ahora",
    games: "Juegos",
    about: "Acerca de",
    game: "Juego",
    website: "Sitio web",
    description: "Descripción",
    rating: "Clasificación",
    developer: "Desarrollador",
    publisher: "Editor",
    released: "Fecha de lanzamiento",
    genre: "Género",
    gallery: "Galería",
    maxPrice: "Precio máximo",
    availableEditions: "Ediciones disponibles",
    availablePlatforms: "Plataformas disponibles",
    addToCart: "Agregar al carrito",
    outOfStock: "Agotado",
    emptyCartHeading: "El carrito está vacío...",
    emptyCartSubheading: "Tu carrito anhela algo de compañía. ¡Comienza tu aventura de compras ahora!",
    exploreCatalog: "Explora nuestro catálogo",
    yourCart: "Tu Carrito",
    quantity: "Cantidad",
    maxProductAllowed: "Se permiten un máximo de {{max}} productos",
    total: "Total",
    goToCart: "Ir al carrito",
    payNow: "Pagar ahora",
    goToPayment: "Ir al pago",
    discount: "Descuento",
    originalPrice: "Precio original",
    checkMoreProducts: "Ver más productos",
    cartSummary: "Resumen del carrito",
    cartItems: "Artículos en el carrito",
    payment: "Pago",
    gameActivation: "Activación del juego",
    login: "Iniciar sesión",
    remove: "Eliminar",
    Notification: "Notificación",
    notificationsEnabled: "Notificaciones del navegador están habilitadas",
    notificationsEnableFail: "No se pudieron habilitar las notificaciones",
    notificationsDisabled: "Notificaciones del navegador están deshabilitadas",
    notificationsDisableFail: "No se pudieron deshabilitar las notificaciones",
    notificationsDenied: "Se denegó el permiso de notificaciones",
    notificationsNoPermission: "No se ha otorgado el permiso de notificaciones",
    notificationsPermissionFaild: "Error al solicitar permiso de notificaciones",
    notificationsNotSupported: "La mensajería Push no es compatible",
    notificationsFailToSubscribe: "No se pudo suscribir al usuario",
    notificationsFailToUnsubscritbe: "No se pudo cancelar la suscripción del usuario",
    serviceWorkerNotReady: "Por favor, recarga la página y vuelve a intentarlo",
    notificationsSubscriptionErro: "Error al obtener la suscripción",
    browserNotifications: "Notificaciones del navegador",
    noProductsFound: "No se encontraron productos.",
    tryDifferentQuery: "Por favor, prueba una búsqueda diferente o restablece los filtros.",
    resetFilters: "Restablecer filtros",
    achievements: "Logros",
    noFavorites: "¡Vaya! No se encontró nada.",
    noFavoritesText: "¡Es hora de buscar un botín épico!",
    favorites: "Favoritos",
    purchases: "Compras",
    points: "Puntos",
    dashboard: "Panel de control",
    settings: "Configuraciones",
    updateSettings: "Actualizar configuraciones",
    security: "Seguridad",
    invoiceNotFound: "Factura no encontrada",
    action: "Acción",
    date: "Fecha",
    rowsPerPage: "Filas por página",
    aboutOurApi: "Acerca de nuestra API",
    aboutOurApiDescription:
      "¡Bienvenido a nuestra página de producto de API! Nuestra API proporciona acceso sin complicaciones a más de 10,000 productos, asegurándote que puedas integrarlos y ofrecer una amplia gama de artículos a tus clientes en todo momento. Ya sea que estés construyendo una plataforma de comercio electrónico, una aplicación móvil o cualquier otro servicio, nuestra API te cubre.",
    whyChooseOurApi: "¿Por qué elegir nuestra API?",
    whyChooseOurApiDescription:
      "Nuestra API está diseñada para ser robusta, confiable y fácil de usar. Estas son algunas de sus características clave:",
    featureApi1: "Acceso a más de 10,000 productos",
    featureApi2: "Disponibilidad y precios de productos en tiempo real",
    featureApi3: "Alta disponibilidad con 99.9% de tiempo de actividad",
    featureApi4: "Documentación y soporte completos",
    featureApi5: "Integración sencilla con endpoints RESTful",
    featureApi6: "Arquitectura segura y escalable",
    WithOurApi:
      "Con nuestra API, te aseguras de que tus clientes tengan acceso a un extenso inventario de productos en todo momento. Nuestra alta disponibilidad y actualizaciones en tiempo real significan que nunca perderás una venta.",
    aboutOurPosTerminals: "Acerca de nuestros terminales POS,",
    welcomeMessage:
      "¡Bienvenido a nuestra página de producto! Nos enorgullece ofrecer terminales POS de última generación diseñados específicamente para tiendas que venden juegos y tarjetas de suscripción. Nuestros terminales son fáciles de usar, seguros y eficientes, lo que te ayuda a gestionar tus ventas e inventario con facilidad.",
    whyChooseOurPosTerminals: "¿Por qué elegir nuestros terminales POS?",
    posTerminalFeatures:
      "Nuestros terminales POS incluyen varias funciones para optimizar las operaciones de tu tienda:",
    feature1: "Integración perfecta con tus sistemas existentes",
    feature2: "Procesamiento de pagos rápido y seguro",
    feature3: "Gestión de inventario en tiempo real",
    feature4: "Interfaz fácil de usar para capacitación rápida",
    feature5: "Ajustes personalizables para adaptarse a las necesidades de tu tienda",
    posTerminalDescription:
      "Ya sea que vendas juegos físicos o tarjetas de suscripción digitales, nuestros terminales POS están equipados para manejar todas tus transacciones de manera eficiente. Mejora la experiencia del cliente y aumenta tus ventas con nuestras soluciones POS confiables y versátiles.",
    invoiceNo: "Factura n.º",
    howTo: "Cómo",
    activate: "Activar",
    step: "Paso",
    giftToFriend: "Regalar a un amigo",
    enterEmailTwice:
      "Por favor, ingresa la dirección de correo electrónico de tu amigo dos veces para confirmación.",
    friendEmail: "Correo electrónico del amigo",
    confirmFriendsEmail: "Confirmar el correo electrónico del amigo",
    emailsDoNotMatch:
      "Las direcciones de correo electrónico no coinciden. Por favor, inténtalo de nuevo.",
    sendGift: "Enviar regalo",
    giftSent: "Regalo enviado",
    giftSentMessage:
      "Hemos enviado correctamente la clave como regalo a tu amigo en el siguiente correo electrónico:",
    close: "Cerrar",
    buyers: "Compradores",
    reviews: "Opiniones",
    user: "Usuario",
    comment: "Comentario",
    requirements: "Requisitos",
    minimumRequirements: "Requisitos mínimos",
    recommendedRequirements: "Requisitos recomendados",
    OS: "SO",
    Processor: "Procesador",
    Memory: "Memoria",
    Graphics: "Gráficos",
    DirectX: "DirectX",
    Storage: "Almacenamiento",
    AdditionalNotes: "Notas adicionales",
    careers: "Carreras",
    contactUs: "Contáctanos",
    updatePersonalData: "Actualiza tus datos de facturación",
    updateApplicationSettings: "Actualiza la configuración de la aplicación",
    birthday: "Cumpleaños",
    yourName: "Tu nombre",
    yourLastName: "Tu apellido",
    enterEmail: "Introduce tu dirección de correo electrónico",
    yourEmail: "Tu dirección de correo electrónico",
    changePassword: "Cambiar contraseña",
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirmar contraseña",
    showLess: "Ver menos",
    showMore: "Ver más",
    PageNotFound: "Página no encontrada",
    DontGiveUp:
      "No te rindas, quizás encuentres aquí lo que buscas.",
    Jobs: "Empleos",
    ElevateYourCareer:
      "Impulsa tu carrera con Bigswiss GmbH, donde la innovación y la pasión se unen. Sumérgete en un mundo donde tu trabajo da forma al futuro de los videojuegos y la tecnología.",
    exploreOpportunities:
      "Explora oportunidades, enfrenta desafíos y sé parte de un equipo dinámico dedicado a la excelencia. Tu camino para marcar la diferencia comienza aquí.",
    enterYourEmailAddress: "Introduce tu dirección de correo electrónico",
    yourEmailAddress: "Tu dirección de correo electrónico",
    contactUsAgree: 'Al hacer clic en "Contáctanos" aceptas nuestros',
    termsAndConditions: "Términos y condiciones",
    bigswissSupport: "Contacta con Bigswiss",
    support: "Soporte",
    contactDescription:
      "Desde consultas sobre nuestra innovadora tienda en línea y tienda mayorista, hasta asistencia con nuestras aplicaciones de kiosco para compras de videojuegos y la tienda mayorista con API, estamos aquí para mejorar tu experiencia.",
    connectTeam:
      "Comunícate con nuestro equipo dedicado para obtener funciones y servicios de primer nivel diseñados especialmente para ti.",
    pricingDescription: "Elige el plan que se ajuste a tus necesidades",
    basic: "Básico",
    pro: "Pro",
    enterprise: "Empresa",
    getStarted: "Comenzar",
    startNow: "Comienza ahora",
    mostPopular: "Más popular",
    perMonth: "por mes",
    basicDesc1: "Acceso a la API para hasta 100 solicitudes al mes",
    basicDesc2: "Analíticas e informes básicos",
    basicDesc3: "Soporte estándar",
    proDesc1: "Acceso a la API para hasta 10,000 solicitudes al mes",
    proDesc2: "Analíticas e informes avanzados",
    proDesc3: "Soporte prioritario",
    proDesc4: "Acceso a ofertas exclusivas de juegos y tarjetas de suscripción",
    enterpriseDesc1: "Solicitudes de API ilimitadas",
    enterpriseDesc2: "Analíticas e informes personalizados",
    enterpriseDesc3: "Soporte dedicado 24/7",
    enterpriseDesc4: "Soluciones de terminales POS personalizadas",
    enterpriseDesc5: "Mejores precios en compras al por mayor",
    chooseActivation: "Elige dónde deseas activar tu producto.",
    thankYouForPurchase: "¡Gracias por tu compra!",
    findYourKeysBellow:
      "Puedes encontrar tus claves e invoice a continuación.",
    productKey: "Clave del producto",
    productKeys: "Claves del producto",
    productActivation: "Activación del producto",
    productActivationDescription:
      "Por favor, sigue las instrucciones a continuación para activar tu producto.",
    sendAsGift: "Regalo",
    hide: "Ocultar",
    viewKeys: "Ver claves",
    purchase_review_pending:
      "Tu compra está en revisión. Recibirás un correo electrónico una vez que sea aprobada.",
    getting_key_please_wait: "Obteniendo tu clave. Por favor, espera...",
    downloadInvoice: "Descargar factura",
    download: "Descargar",
    back: "Atrás",
    next: "Siguiente",
    activationMethod: "Método de activación",
    keylessActivation: "Activación sin clave",
    activationLink: "Enlace de activación",
    enterTheKey: "Introduce la clave",
    confirmActivation: "Confirmar activación",
    battlenetStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Battle.net.",
    goToBattlenetActivation: "Ir a la página de activación de Battle.net",
    battlenetStep2:
      "Inicia sesión con tus credenciales de Battle.net. Si no tienes una cuenta, crea una gratuitamente.",
    battlenetStep3:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Canjear código'.",
    battlenetStep4:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Battle.net.",
    goToActivation: "Ir a la activación",
    battlenetStep2app:
      "Abre la aplicación Blizzard Battle.net e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    battlenetStep3app:
      "Haz clic en tu perfil para abrir un menú, luego haz clic en 'Canjear código'.",
    goToSteamActivation: "Ir a la página de activación de Steam",
    steamStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Steam.",
    steamStep2:
      "Inicia sesión con tus credenciales de Steam. Si no tienes una cuenta, crea una gratis.",
    steamStep3:
      "Lee y acepta el Acuerdo de Suscriptor de Steam, luego haz clic en 'Continuar'.",
    steamStep4:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Steam.",
    steamStep2app:
      "Abre la aplicación de Steam e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    steamStep3app:
      "Haz clic en 'Juegos' en el menú superior, luego haz clic en 'Activar un producto en Steam'.",
    steamStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Confirmar'.",
    goToMicrosoftActivation:
      "Ir a la página de activación de la Tienda Microsoft",
    microsoftStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de la Tienda Microsoft.",
    microsoftStep2:
      "Inicia sesión con tus credenciales de Microsoft. Si no tienes una cuenta, crea una gratis.",
    microsoftStep3:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Siguiente'.",
    microsoftStep4:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Microsoft.",
    microsoftStep2app:
      "En tu sistema Windows, abre la aplicación de Microsoft Store e inicia sesión con tus credenciales de Microsoft. Si no tienes una cuenta, crea una gratis.",
    microsoftStep3app:
      "Haz clic en el logotipo de perfil en la esquina superior derecha, luego haz clic en 'Canjear código o tarjetas de regalo'.",
    goToGOGActivation: "Ir a la página de activación de GOG",
    activateKey: "Activar clave",
    linkToAccount: "Vincular a la cuenta",
    gogStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de GOG.",
    gogStep2: "Haz clic en 'Continuar' para confirmar la activación de la clave.",
    gogStep3: "Haz clic en 'Iniciar sesión' para vincular la clave a tu cuenta de GOG.",
    gogStep4:
      "Inicia sesión con tus credenciales de GOG. Si no tienes una cuenta, crea una gratis.",
    gogStep5:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de GOG.",
    gogStep2app:
      "Abre la aplicación GOG Galaxy e inicia sesión con tus credenciales de GOG. Si no tienes una cuenta, crea una gratis.",
    gogStep3app:
      "Haz clic en 'Agregar juegos y amigos' en el menú superior, luego haz clic en 'Canjear código de GOG'.",
    gogStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Continuar'.",
    goToEpicActivation: "Ir a la página de activación de Epic Games",
    epicStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Epic Games.",
    epicStep2: "Haz clic en 'Canjear' para confirmar la activación de la clave.",
    epicStep3:
      "Haz clic en 'Iniciar sesión' para vincular la clave a tu cuenta de Epic Games.",
    epicStep4:
      "Inicia sesión con tus credenciales de Epic Games. Si no tienes una cuenta, crea una gratis.",
    epicStep5:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Epic Games.",
    epicStep2app:
      "Abre la aplicación de Epic Games e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    epicStep3app:
      "Haz clic en tu perfil en la esquina superior derecha, luego haz clic en 'Canjear código'.",
    epicStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Canjear'.",
    goToRockstarActivation: "Ir a la página de activación de Rockstar",
    rockstarStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Rockstar.",
    rockstarStep2:
      "Inicia sesión con tus credenciales de Rockstar. Si no tienes una cuenta, crea una gratis.",
    rockstarStep3:
      "Espera a que la página se cargue y a que se complete la validación de Rockstar. Puede tardar varios minutos. Una vez que la página se cargue, verás un mensaje de confirmación. Haz clic en 'Confirmar' para activar la clave en tu cuenta de Rockstar.",
    rockstarStep4:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Rockstar.",
    rockstarStep2app:
      "Abre la aplicación Rockstar Games Launcher e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    rockstarStep3app:
      "Haz clic en el menú en la esquina superior derecha, luego haz clic en 'REDEEM CODE'.",
    rockstarStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'CHECK'.",
    rockstarStep5app:
      "Lee y acepta los términos y condiciones, luego haz clic en 'REDEEM'.",
    goToEAPlayActivation: "Ir a la página de activación de EA Play",
    eaStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de EA Play.",
    eaStep2:
      "Inicia sesión con tus credenciales de EA Play. Si no tienes una cuenta, crea una gratis.",
    eaStep3:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Siguiente'.",
    eaStep4:
      "Haz clic en el botón 'Siguiente' para vincular la clave a tu cuenta de EA Play.",
    eaStep5:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de EA Play.",
    eaStep2app:
      "Abre la aplicación EA Play e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    eaStep3app:
      "Haz clic en el menú en la esquina superior derecha, luego haz clic en 'Canjear código'.",
    eaStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Siguiente'.",
    ubisoftStep5:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Ubisoft.",
    ubisoftStep2app:
      "Abre la aplicación de Ubisoft e inicia sesión con tus credenciales. Si no tienes una cuenta, crea una gratis.",
    ubisoftStep3app:
      "Haz clic en el menú de tu perfil en la esquina superior izquierda, luego haz clic en 'Activación de clave'.",
    ubisoftStep4app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Activar'.",
    goToSwitchActivation: "Ir a la página de activación de Nintendo Switch",
    openeshop: "Abrir eShop",
    selectUser: "Seleccionar usuario",
    redeem: "Canjear clave",
    switchStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Nintendo Switch.",
    switchStep2:
      "Inicia sesión con tus credenciales de Nintendo Switch. Si no tienes una cuenta, crea una gratis.",
    switchStep3:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Continuar'.",
    switchStep4:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Nintendo Switch.",
    switchStep1app: "Abre la aplicación Nintendo eShop.",
    switchStep2app:
      "Selecciona la cuenta donde deseas activar el producto.",
    switchStep3app:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Confirmar'.",
    switchStep4app:
      "Selecciona 'Canjear' y haz clic en 'Confirmar' para activar el producto en tu cuenta de Nintendo Switch.",
    goToPlayStationActivation: "Ir a la página de activación de PlayStation",
    openLogin: "Abrir inicio de sesión",
    psStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de PlayStation.",
    psStep2: "En la esquina superior derecha, haz clic en 'Iniciar sesión'.",
    psStep3:
      "Inicia sesión con tus credenciales de PlayStation. Si no tienes una cuenta, crea una gratis.",
    psStep4:
      "En la esquina superior derecha, haz clic en tu perfil, luego haz clic en 'Canjear código'.",
    psStep5:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Continuar'.",
    psStep6:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de PlayStation.",
    openStore: "Abrir tienda",
    psStep2console:
      "Selecciona PlayStation Store a la izquierda y presiona 'X'.",
    psStep3console:
      "Presiona '△' para acceder al menú en la esquina superior derecha, selecciona '...' y luego 'Canjear código'.",
    psStep4console:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Canjear'.",
    psStep2app:
      "Abre la aplicación de PlayStation y abre la tienda de PlayStation.",
    openMenu: "Abrir menú",
    psStep3app:
      "Selecciona el menú en la esquina superior derecha para ver más opciones",
    psStep4app: "Selecciona 'Canjear código'",
    goToXboxActivation: "Ir a la página de activación de Xbox",
    xboxStep1:
      "Haz clic en el botón de abajo para ir a la página de activación de claves de Xbox.",
    xboxStep2:
      "Haz clic en 'Iniciar sesión para canjear' para iniciar sesión con tus credenciales de Xbox. Si no tienes una cuenta, crea una gratis.",
    xboxStep3:
      "Haz clic en 'Canjear ahora' para abrir la ventana de activación de clave.",
    xboxStep4:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Siguiente'. Y continúa siguiendo las instrucciones.",
    xboxStep5:
      "¡Felicidades! Has activado tu producto correctamente, y está listo para descargarse y jugarse en tu cuenta de Xbox.",
    xboxStep2console:
      "Presiona el botón de Xbox en tu control para abrir la guía. Mientras estés conectado con tu cuenta, selecciona 'Tienda'.",
    xboxStep3console:
      "Una vez abierta la tienda, presiona el botón 'Ver' en tu control para abrir el menú, luego selecciona 'Canjear'.",
    xboxStep4console:
      "Ingresa la clave que recibiste en el campo provisto, luego haz clic en 'Verificar código'.",
    warningModalTitle: "Aviso importante",
    warningModalMessage1:
      "Una vez que reveles la clave, pierdes el derecho a un reembolso instantáneo.",
    warningModalMessage2:
      "Solo serás elegible para un reembolso si la clave es inválida.",
    doYouWantToProceed: "¿Deseas continuar?",
    revealKey: "Revelar clave",
    //CHAT 
    helpCenter: "Centro de ayuda",
    talkWithOurSupport: "Habla con soporte",
    haveAQuestion: "¿Tienes una pregunta?",
    typeAMessage: "Escribe un mensaje...",
    hello: "Hola",
    howCanIHelpYou: "¿En qué puedo ayudarte?",
    home: "Inicio",
    history: "Historial",
    help: "Ayuda",
    noChatMessages: "Aún no tienes historial de chat.",
    allHelpTopics: "Todos los temas de ayuda",
    searchTopics: "Buscar temas...",
    noTopicsFound: "No se encontraron temas.",

    redeemingCodesCategory: "Códigos de canje",
    findingCodesCategory: "Búsqueda de códigos",
    invoicesCategory: "Facturas",
    codeActivationIssueCategory: "Problemas de activación de códigos",
    identityVerificationCategory: "Verificación de identidad",

    howToRedeem: "¿Cómo canjear el código?",
    whereToFindCodes: "¿Dónde puedo encontrar mis códigos?",
    whereToGetInvoice: "¿Dónde puedo obtener una factura de mi compra?",
    issuesActivatingCode: "Tengo problemas para activar mi código",
    issuesIdentityVerification: "Tengo problemas con la verificación de mi identidad",

    howToRedeemDesc: "Sigue los pasos a continuación para canjear tu código con éxito:",
    findingCodesDesc: "Sigue los pasos a continuación para encontrar tu código:",
    invoicesDesc: "Para acceder a una factura de tu compra, sigue los pasos a continuación:",
    activationIssueDesc: "Si tienes problemas para activar tu código, por favor verifica lo siguiente:",
    identityVerificationDesc: "Si enfrentas desafíos con la verificación de identidad, asegúrate de lo siguiente:",

    accessAccountTitle: "Accede a tu cuenta",
    accessAccountDesc: "Haz clic en el ícono de usuario ubicado en la esquina superior derecha de la página.",

    navigatePurchasesTitle: "Ir a Compras",
    navigatePurchasesDesc: "En el menú desplegable, selecciona 'Compras' para ver tus transacciones recientes.",

    selectRelevantPurchaseTitle: "Selecciona la compra correspondiente",
    selectRelevantPurchaseDesc: "En la tabla de compras, selecciona la transacción asociada con la clave que deseas activar.",

    viewKeysTitle: "Ver claves disponibles",
    viewKeysDesc: "Haz clic en 'Ver Claves' para mostrar una lista de las claves de producto disponibles para activación.",

    revealKeyTitle: "Revelar la clave",
    revealKeyDesc: "Haz clic en el ícono de ojo para mostrar la clave que deseas activar.",

    activateKeyTitle: "Activar la clave",
    activateKeyDesc: "Haz clic en el botón 'Activar' y sigue las instrucciones en pantalla.",

    downloadInvoiceTitle: "Descargar factura",
    downloadInvoiceDesc: "Haz clic en el botón 'Descargar Factura' para obtener una copia en PDF de tu factura.",

    checkTyposTitle: "Verifica errores de escritura",
    checkTyposDesc: "Asegúrate de haber ingresado correctamente el código, sin errores de escritura.",

    verifyUsageTitle: "Verifica uso del código",
    verifyUsageDesc: "Confirma que el código no haya sido utilizado previamente.",

    correctPlatformTitle: "Plataforma correcta",
    correctPlatformDesc: "Verifica que estés usando el código en la plataforma o aplicación correcta.",

    clearDocumentsTitle: "Documentos claros",
    clearDocumentsDesc: "Tus documentos de identificación deben ser claros y legibles.",

    matchPersonalInfoTitle: "Coincidencia de datos personales",
    matchPersonalInfoDesc: "Tu información personal debe coincidir con los registros.",

    supportedVerificationMethodTitle: "Método de verificación soportado",
    supportedVerificationMethodDesc: "Estás usando un método de verificación soportado.",

    notesContactSupport: "Si el problema persiste, por favor contacta a nuestro equipo de soporte para recibir más ayuda.",
    stillHavingIssue: "¿Sigues teniendo problemas con",
    pleaseLoginToSeeTicketHistory: "Por favor inicia sesión para ver tu historial de tickets.",
  },
};