// File: src/pages/HomePage/Components/BestSellerGrid.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid2 as Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import SiteTitle from 'components/SiteTitle';
import ProductCardComponent from 'components/ProductCard';
import { BestSellerGridLoading } from './BestSellerGridLoading';
import { makeUrlSafe } from 'utils/getNames';


interface SettingsProductItem {
  _id: string;
  title: string;
  type: string;
  productId: string;
  editionName?: string;
  category: string;
  platformId: string;
  region: string;
  price: number;
}

interface BestSellerGridProps {
  categoryTitle: string;
  category: string;
  productsData: SettingsProductItem[];
  loading: boolean;
}

const BestSellerGrid: React.FC<BestSellerGridProps> = ({
  categoryTitle,
  category,
  productsData,
  loading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // If we’re loading and have no items => show skeleton
  if (loading && productsData.length === 0) {
    return (
      <Grid size={12}>
        <BestSellerGridLoading />
      </Grid>
    );
  }

  // If the array is empty => skip
  if (!productsData || productsData.length === 0) {
    return null;
  }

  const handleTitleClick = () => {
    // e.g. navigate => /products?category=pc
    navigate(`/products?category=${makeUrlSafe(category)}`);
  };

  return (
    <Grid container spacing={4} mb={10}>
      <Grid size={12}>
        <SiteTitle
          firstTitle={categoryTitle}
          secondTitle={t('games')}
          variant="h4"
          color="primary"
          onClick={handleTitleClick}
          arrow
        />
      </Grid>

      {/* Render each item in the array */}
      {productsData.map((item, index) => {
        const { productId } = item;
        const safePrice = (typeof item.price === 'number') ? item.price : 0;

        // e.g. pass platform._id as “platformGroupId” if your ProductCard expects that:
        return (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={`${item._id}-${productId}-${index}`}>
            <ProductCardComponent
              // If your ProductCard has a “withFav” boolean:
              withFav={false}
              title={item.title}
              price={safePrice}
              type={item.type}
              category={item.category}
              platform={item.platformId}
              productId={item._id}
              productPlatformId={item.productId}
              editionName={item.editionName || ''}
              regionId={item.region}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BestSellerGrid;