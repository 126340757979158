import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchUserInvoicesQuery, type Invoice } from 'redux/RTK/userApi';

import { Typography, Grid2 as Grid, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TableSortLabel, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InvoicePdfGenerator from 'components/Invoice/InvoicePdfGenerator';

const PurchasesPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // 1) Fetch the invoices via RTK Query
    const {
        data: invoicesData = [], // default to empty array
        isLoading,
        error,
    } = useFetchUserInvoicesQuery();

    // 2) Local states for sorting & pagination
    const [sortField, setSortField] = useState<keyof Invoice>('invoiceNumber');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // 3) Handle table sorting
    const handleSort = (field: keyof Invoice) => {
        const isAsc = sortField === field && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortField(field);
    };

    // 4) Compute sorted + paginated invoices
    const sortedInvoices = [...invoicesData].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const paginatedInvoices = sortedInvoices.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    // 5) Table pagination handlers
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // 6) Loading & error states
    if (isLoading) {
        return <Typography>{t('loadingInvoices') || 'Loading invoices...'}</Typography>;
    }

    if (error) {
        return (
            <Typography color="error">
                {t('failedToLoadInvoices') || 'Failed to load invoices.'}
            </Typography>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} >
                <TableContainer component={Card}>
                    <Table aria-label="purchases table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'invoiceNumber'}
                                        direction={sortField === 'invoiceNumber' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('invoiceNumber')}
                                    >
                                        {t('invoiceNo')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        active={sortField === 'createdAt'}
                                        direction={sortField === 'createdAt' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('createdAt')}
                                    >
                                        {t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel
                                        active={sortField === 'total'}
                                        direction={sortField === 'total' ? sortOrder : 'asc'}
                                        onClick={() => handleSort('total')}
                                    >
                                        {t('total')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">{t('action')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedInvoices.length > 0 ? (
                                paginatedInvoices.map((invoice) => (
                                    <TableRow
                                        key={invoice.invoiceNumber}
                                        hover
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            navigate(`/dashboard/purchases/${invoice.invoiceNumber}`)
                                        }
                                    >
                                        <TableCell component="th" scope="row">
                                            {invoice.invoiceNumber}
                                        </TableCell>
                                        <TableCell align="right">
                                            {new Date(invoice.createdAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {invoice.total.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            onClick={(e) => e.stopPropagation()} // Stop row click
                                        >
                                            <InvoicePdfGenerator title={t('download')} invoice={invoice} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <Typography variant="body1">
                                            {t('invoiceNotFound') || 'No invoices found.'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    sx={{
                        mt: 2,
                        '& .MuiTablePagination-toolbar': {
                            backgroundColor: 'rgba(19, 27, 32, 0.6)',
                            color: '#fff',
                        },
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={invoicesData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('rowsPerPage')}
                />
            </Grid>
        </Grid>
    );
};

export default PurchasesPage;