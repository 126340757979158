// File: src/components/ChatBot/ChatMenu.tsx

import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Button, Divider, ListItemButton, ListItemIcon } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useFetchUserTicketsQuery } from 'redux/RTK/userApi';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ConstructionIcon from '@mui/icons-material/Construction';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import GamepadIcon from '@mui/icons-material/Gamepad';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface ChatMenuProps {
  onAskQuestion: () => void;
  openChatHistory: (ticketId: string) => void;
}

const ChatMenu: React.FC<ChatMenuProps> = ({ onAskQuestion, openChatHistory }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);

  if (!user) {
    return <Typography variant="h5" textAlign={'center'} sx={{ margin: 'auto' }}>{t('pleaseLoginToSeeTicketHistory')}</Typography>;
  }
  // Provide a default value of [] for tickets
  const { data: tickets = [], isLoading, error } = useFetchUserTicketsQuery();

  // (Optional) Display a loading or error state
  if (isLoading) {
    return <Typography>{t('loadingTickets') || 'Loading your tickets...'}</Typography>;
  }


  if (error) {
    return <Typography color="error">{t('errorLoadingTickets') || 'Error loading tickets.'}</Typography>;
  }


  // Render icon based on ticket category
  const renderIcon = (category: string) => {
    switch (category) {
      case 'technical':
        return <ConstructionIcon />;
      case 'billing':
        return <ShoppingCartCheckoutIcon />;
      case 'identity':
        return <FingerprintIcon />;
      case 'product':
        return <GamepadIcon />;
      case 'other':
        return <ChatBubbleIcon />;
      default:
        return <ChatBubbleIcon />;
    }
  };

  return (
    <>
      {tickets.length > 0 ? (
        <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {tickets.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                <ListItemButton onClick={() => openChatHistory(item._id)} component="span">
                  <ListItemIcon sx={{ textAlign: 'center' }}>
                    {renderIcon(item.category || 'other')}
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <>
                        <Typography variant="subtitle1" fontWeight="bold" textTransform={'capitalize'}>
                          {item.category}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          {item.topic}
                        </Typography>
                      </>
                    }
                  />
                  <ChevronRightIcon sx={{ margin: 'auto' }} />
                </ListItemButton>
              </ListItem>
              {index < tickets.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img
            src="img/noMessages.webp"
            alt="No messages"
            style={{ width: '100%', maxWidth: 200 }}
          />
          <Typography variant="body1" gutterBottom mt={4}>
            {t('noChatMessages')}
          </Typography>
        </Box>
      )}
      <Button variant="contained" color="primary" onClick={() => onAskQuestion()}>
        {t('haveAquestion')}
      </Button>
    </>
  );
};

export default ChatMenu;