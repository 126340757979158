// src/components/CustomSnackbarContent.tsx
import React from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CustomSnackbarContentProps {
  title?: string;
  message: string;
  icon?: React.ReactNode;
}

const CustomSnackbarContent = React.forwardRef<HTMLDivElement, CustomSnackbarContentProps>(
  function CustomSnackbarContent(props, ref) {
    const { message, title, icon } = props;
    const { t } = useTranslation();
    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref} onClick={() => closeSnackbar()}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon ? icon : <img src="/img/180.png" width="32" height="32" alt="APP Notification" />}
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">
              {title ? title : t('Notification')}
            </Typography>
            <Typography variant="body2">{message}</Typography>
          </Box>
        </Box>
      </SnackbarContent>
    );
  }
);

export default CustomSnackbarContent;