import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store';
import App from './App';
import logAsciiArt from './utils/ascii';
import { setOnlineStatus } from './redux/auth/authSlice';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SocketProvider } from 'sockets/SocketContext';
import { SnackbarProvider } from 'notistack';

logAsciiArt();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

const AppWrapper: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateOnlineStatus = () => {
      dispatch(setOnlineStatus(navigator.onLine));
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    updateOnlineStatus();

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [dispatch]);

  useEffect(() => {
    // Register the service worker and delegate all update logic to serviceWorkerRegistration.js
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        if (registration && registration.waiting) {
          if (window.confirm('A new version of the app is available. Do you want to reload?')) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            registration.waiting.addEventListener('statechange', (event) => {
              const target = event.target as ServiceWorker;
              console.log('Service worker state changed:', target?.state);
              if (event.target instanceof ServiceWorker && event.target.state === 'activated') {
                console.log('New service worker activated, reloading the page.');
                window.location.reload();
              }
            });
          } else {
            console.log('User canceled the update. The new version will not be activated now.');
          }
        } else {
          console.log('No service worker in waiting state, no action needed.');
        }
      },
    });
  }, []);

  return <App />;
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
        <SocketProvider>
          <BrowserRouter>
            <AppWrapper />
          </BrowserRouter>
        </SocketProvider>
      </SnackbarProvider>
    </PersistGate>
  </Provider >
);
