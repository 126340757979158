import { Invoice } from '../RTK/userApi';

export interface FavoriteItem {
    productId: string;
    platformGroupId: string;
  }
  
  export interface User {
    email: string;
    _id: string;
    firstName: string;
    lastName: string;
    birthday: string;
    address: string;
    city: string;
    zip: string;
    country: string;
    favorites: FavoriteItem[];
    invoices: Invoice[];
  }
  
  interface AuthState {
    user: User | null;
    token: string | null;
    isLoggedIn: boolean;
    welcomePageLoading: boolean;
    isLoading: boolean;
    hasMissingInfo: boolean;
    isEmailVerified: boolean;
    identityRequireCheck: boolean;
    isGoogleLoading: boolean;
    verificationSecret: string | null;
    verificationError: string | null;
    verificationStatus: string;
    mainLayoutFade: boolean;
    activePurchase: boolean;
    error: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    language: string;
    filter: {
      platform: string;
      maxPrice: string;
      category: string;
      type: string;
      genre: string;
      sort: string;
    };
    selectedCurrency: string;
    selectedCurrencySymbol: string;
    selectedRegion: string;
    userIp: string | null;
    isOnline: boolean;
  }
  
  export const initialState: AuthState = {
    user: null,
    token: null,
    isLoggedIn: false,
    welcomePageLoading: false,
    isGoogleLoading: false,
    isLoading: false,
    isEmailVerified: false,
    hasMissingInfo: true,
    identityRequireCheck: false,
    verificationSecret: null,
    verificationError: null,
    verificationStatus: 'processing_verification',
    mainLayoutFade: false,
    activePurchase: false,
    error: null,
    status: 'idle',
    language: 'en',
    filter: {
      platform: '',
      maxPrice: '',
      category: '',
      type: '',
      genre: '',
      sort: 'A-Z',
    },
    selectedCurrency: 'EUR',
    selectedCurrencySymbol: '€',
    selectedRegion: 'EU',
    userIp: null,
    isOnline: typeof navigator !== 'undefined' ? navigator.onLine : true,
  };
  