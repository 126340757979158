export const frResources = {
    translation: {
        languageChanged: "Langue changée",
        selectedLanguage: "Le français est maintenant sélectionné",
        dark: "Sombre",
        light: "Clair",
        systemPreferences: "Préférences système",
        changeApplicationTheme: "Sélectionnez le thème de l'application",
        applicationTheme: "Thème de l'application",
        darkDescription: "Bienvenue du côté obscur, nous avons des cookies!",
        lightDescription: "Embrassez la lumière, noble joueur!",
        autoDescription: "Laissez votre système décider de votre destin!",
        welcome: "Bienvenue",
        welcomeToBigswiss: "Bienvenue chez Bigswiss",
        completeProfileMessage: "Veuillez compléter votre profil",
        selectLanguage: "Sélectionnez la langue par défaut",
        selectLanguageLabel: "Choisir la langue",
        gdprFadpDescription: "Nous utilisons des cookies pour garantir le bon fonctionnement de notre site Web et améliorer votre expérience. En sélectionnant 'Accepter uniquement les cookies nécessaires', vous consentez à l'utilisation de cookies essentiels pour les fonctionnalités de base, y compris les cookies tiers de Stripe pour le traitement sécurisé des paiements. En sélectionnant 'Accepter tous les cookies', vous nous permettez également d'utiliser des cookies facultatifs, tels que ceux destinés à l'analyse (Google Analytics) et à la publicité, afin d'améliorer davantage nos services.",
        acceptTerms: "J'accepte les termes et conditions.",
        acceptNecessary: "Essentiel Seulement",
        acceptAll: "Tout Accepter",
        termsWarning: "Veuillez accepter les conditions générales pour continuer.",


        selectCurrency: "Sélectionner la devise",
        newsletter: "Bulletin",
        subscribeMessage: "Abonnez-vous à notre newsletter pour des mises à jour et des promotions hebdomadaires.",
        subscribe: "S'abonner",
        products: "Produits",
        position: "Poste",
        message: "Message",
        invalidEmail: "E-mail invalide",
        allFieldsRequired: "Veuillez remplir tous les champs obligatoires",
        messageSent: "Message envoyé",
        contacted: "Demande envoyée",
        jobThankYou: "Merci de votre intérêt. Nous vous contacterons bientôt.",
        duplicateApplication: "Vous avez déjà postulé, veuillez patienter jusqu'à ce que nous vous contactions.",
        duplicateSubscription: "Vous êtes déjà abonné.",
        succesSubcribed: "Vous vous êtes abonné avec succès.",
        company: "Entreprise",
        getInTouch: "Prendre contact",
        privacyPolicy: "Politique de confidentialité",
        termsOfService: "Conditions d'utilisation",
        pcGames: "Jeux PC",
        xboxGames: "Jeux XBOX",
        playstationGames: "Jeux PlayStation",
        nintendoGames: "Jeux Nintendo",
        giftCards: "Cartes-cadeaux",
        subscriptionCards: "Cartes d'abonnement",
        aboutUs: "À propos de nous",
        testimonials: "Témoignages",
        testimonialsDescription: "Découvrez ce que nos clients aiment dans nos fonctionnalités. Découvrez comment nous excellons en efficacité et satisfaction. Rejoignez-nous pour des coûts réduits, une innovation dans la vente au détail et un support fiable.",

        highlights: "Points forts",
        pricing: "Tarification",

        faqs: "FAQ",
        giftAndSubscriptionCards: "Cartes cadeaux & d'abonnement",
        checkTopSellers: "Vérifiez les meilleures ventes",
        platforms: "Plateformes",
        category: "Catégorie",
        platform: "Plateforme",
        contactSupportText: "Si vous souhaitez changer la région de votre compte, veuillez contacter notre support",
        region: "Région",
        productType: "Type de produit",
        none: "Aucun",
        sort: "Trier",
        priceIncreasing: "Augmentation des prix",
        priceDecreasing: "Diminution des prix",

        consoles: "Consoles",
        aboutUsDescription: "Depuis 2002, Bigswiss.ch est votre destination privilégiée pour les jeux numériques et les cartes cadeaux, opérant fièrement depuis le cœur de la Suisse. Avec plus de deux décennies d'expérience, nous nous engageons à vous offrir le meilleur du divertissement numérique.\n\nNotre vaste sélection comprend une grande variété de jeux numériques sur toutes les plateformes populaires, ainsi qu'une gamme de cartes cadeaux pour vos services préférés. Que vous soyez un joueur chevronné à la recherche de la prochaine grande sortie ou à la recherche du cadeau parfait pour un être cher, Bigswiss.ch a tout ce qu'il vous faut.\n\nChez Bigswiss.ch, nous sommes fiers de fournir un service client exceptionnel et une expérience d'achat fluide. Notre équipe dédiée est toujours là pour vous assister, garantissant que votre parcours avec nous soit agréable et sans encombre.",
        onlineStoreTitle: "Boutique en ligne",
        onlineStoreDescription: "Achetez plus de 10 000 jeux vidéo et produits numériques différents",
        posTerminalsTitle: "Terminaux POS",
        posTerminalsDescription: "Intégrez nos terminaux POS dans votre magasin et accédez à tous nos jeux",
        wholesaleApiTitle: "API de gros",
        wholesaleApiDescription: "Intégrez notre API de gros à votre boutique en ligne",
        learnMore: "En savoir plus",
        goToStore: "Aller au magasin",
        highlightsDescription: "Découvrez pourquoi notre produit se distingue : adaptabilité, durabilité, design convivial et innovation. Profitez d'un support client fiable et d'une précision dans chaque détail.",
        instantDeliveryTitle: "Livraison Instantanée",
        instantDeliveryDescription: "Recevez vos codes numériques instantanément après l'achat.",
        digitalDownloadTitle: "Téléchargement Numérique",
        digitalDownloadDescription: "Accédez à vos jeux et services préférés avec des codes faciles à utiliser.",
        customerSupportTitle: "Support Client 24/7",
        customerSupportDescription: "Nous sommes là pour vous à tout moment, prêts à résoudre tout problème.",
        adaptablePerformanceTitle: "Performance Adaptable",
        adaptablePerformanceDescription: "Notre produit s'ajuste facilement à vos besoins, augmentant l'efficacité et simplifiant vos tâches.",
        innovativeFunctionalityTitle: "Fonctionnalité Innovante",
        innovativeFunctionalityDescription: "Restez en avance avec des fonctionnalités qui établissent de nouvelles normes, répondant mieux à vos besoins en évolution.",
        precisionDetailTitle: "Précision dans chaque détail",
        precisionDetailDescription: "Profitez d'un produit méticuleusement conçu où les petits détails ont un impact significatif sur votre expérience globale.",
        ourTopSellingProducts: "Nos produits les plus vendus",

        faqTitle: "Questions fréquemment posées",
        faq1Question: "Comment contacter le support client si j'ai une question ou un problème?",
        faq1Answer: "Nous sommes là pour vous aider rapidement. Vous pouvez contacter notre équipe d'assistance clientèle en envoyant un e-mail à ",
        faq2Question: "Puis-je retourner le produit s'il ne répond pas à mes attentes?",
        faq2Answer: "Absolument! Nous offrons une politique de retour sans tracas. Si vous n'êtes pas entièrement satisfait, vous pouvez retourner le produit dans les 7 jours pour un remboursement complet ou un échange.",
        faq3Question: "Qu'est-ce qui distingue votre produit des autres sur le marché?",
        faq3Answer: "Notre produit se distingue par son adaptabilité, sa durabilité et ses fonctionnalités innovantes. Nous priorisons la satisfaction de l'utilisateur et nous efforçons continuellement de dépasser les attentes dans tous les aspects.",
        faq4Question: "Y a-t-il une garantie sur le produit, et que couvre-t-elle?",
        faq4Answer: "Oui, notre produit est livré avec une garantie de 1 an. Elle couvre les défauts de matériaux et de fabrication. Si vous rencontrez des problèmes couverts par la garantie, veuillez contacter notre support client pour obtenir de l'aide.",

        available: "Disponible",
        now: "Maintenant",
        games: "Jeux",
        about: "À propos de",
        game: "Jeu",
        website: "Site web",
        description: "Description",
        rating: "Évaluation",
        developer: "Développeur",
        publisher: "Éditeur",
        released: "Date de sortie",
        genre: "Genre",
        gallery: "Galerie",
        maxPrice: "Prix maximum",

        availableEditions: "Éditions Disponibles",
        availablePlatforms: "Plateformes Disponibles",
        addToCart: "Ajouter au panier",
        outOfStock: "En rupture de stock",
        emptyCartHeading: "Le panier est vide...",
        emptyCartSubheading: "Votre panier se sent seul. Commencez votre aventure shopping maintenant!",
        exploreCatalog: "Explorez notre catalogue",
        yourCart: "Votre Panier",
        quantity: "Quantité",
        maxProductAllowed: "Maximum de {{max}} produits autorisés",
        total: "Total",
        goToCart: "Aller au Panier",
        payNow: "Payer maintenant",
        goToPayment: "Aller au Paiement",
        discount: "Remise",
        originalPrice: "Prix d'origine",
        checkMoreProducts: "Voir plus de produits",
        cartSummary: "Récapitulatif du panier",
        cartItems: "Articles dans le panier",
        payment: "Paiement",
        gameActivation: "Activation du jeu",
        login: "Connexion",
        remove: "Supprimer",

        Notification: "Notification",
        notificationsEnabled: "Les notifications du navigateur sont activées",
        notificationsEnableFail: "Échec de l'activation des notifications",
        notificationsDisabled: "Les notifications du navigateur sont désactivées",
        notificationsDisableFail: "Échec de la désactivation des notifications",
        notificationsDenied: "Autorisation de notification refusée",
        notificationsNoPermission: "Autorisation de notification non accordée",
        notificationsPermissionFaild: "Échec de la demande d'autorisation de notification",
        notificationsNotSupported: "La messagerie push n'est pas prise en charge",
        notificationsFailToSubscribe: "Échec de l'abonnement de l'utilisateur",
        notificationsFailToUnsubscribe: "Échec de la désinscription de l'utilisateur",
        serviceWorkerNotReady: "Veuillez recharger la page et réessayer",
        notificationsSubscriptionErro: "Erreur lors de l'obtention de l'abonnement",
        browserNotifications: "Notifications du navigateur",

        noProductsFound: "Aucun produit trouvé.",
        tryDifferentQuery: "Veuillez essayer une autre recherche ou réinitialiser les filtres.",
        resetFilters: "Réinitialiser les filtres",
        achievements: "Réalisations",
        noFavorites: "Oups ! Pas de butin trouvé !",
        noFavoritesText: "Il est temps de chasser du butin épique !",
        favorites: "Favoris",
        purchases: "Achats",
        points: "Points",
        dashboard: "Tableau de bord",
        settings: "Paramètres",
        updateSettings: "Mettre à jour les paramètres",
        security: "Sécurité",
        invoiceNotFound: "Facture non trouvée",
        action: "Action",
        date: "Date",
        rowsPerPage: "Lignes par page",
        aboutOurApi: "À propos de notre API",
        aboutOurApiDescription: "Bienvenue sur notre page produit API! Notre API offre un accès transparent à plus de 10 000 produits, garantissant que vous pouvez intégrer et proposer une large gamme d'articles à vos clients à tout moment. Que vous construisiez une plateforme de commerce électronique, une application mobile ou tout autre service, notre API vous couvre.",
        whyChooseOurApi: "Pourquoi choisir notre API?",
        whyChooseOurApiDescription: "Notre API est conçue pour être robuste, fiable et facile à utiliser. Voici quelques-unes des principales caractéristiques:",
        featureApi1: "Accès à plus de 10 000 produits",
        featureApi2: "Disponibilité et tarification des produits en temps réel",
        featureApi3: "Haute disponibilité avec un temps de fonctionnement de 99,9%",
        featureApi4: "Documentation complète et support",
        featureApi5: "Intégration facile avec des points de terminaison RESTful",
        featureApi6: "Architecture sécurisée et évolutive",
        WithOurApi: "Avec notre API, vous pouvez garantir à vos clients un accès à un vaste inventaire de produits à tout moment. Notre haute disponibilité et nos mises à jour en temps réel signifient que vous ne manquerez jamais une vente.",

        aboutOurPosTerminals: "À propos de nos terminaux de point de vente",
        welcomeMessage: "Bienvenue sur notre page produit! Nous sommes fiers de proposer des terminaux de point de vente de pointe conçus spécifiquement pour les magasins qui vendent des jeux et des cartes d'abonnement. Nos terminaux sont faciles à utiliser, sécurisés et efficaces, vous aidant à gérer vos ventes et votre inventaire avec facilité",
        whyChooseOurPosTerminals: "Pourquoi choisir nos terminaux de point de vente?",
        posTerminalFeatures: "Nos terminaux de point de vente sont dotés d'une gamme de fonctionnalités pour rationaliser les opérations de votre magasin:",
        feature1: "Intégration transparente avec vos systèmes existants",
        feature2: "Traitement des paiements rapide et sécurisé",
        feature3: "Gestion des stocks en temps réel",
        feature4: "Interface conviviale pour une formation rapide",
        feature5: "Paramètres personnalisables pour répondre aux besoins de votre magasin",
        posTerminalDescription: "Que vous vendiez des jeux physiques ou des cartes d'abonnement numériques, nos terminaux de point de vente sont équipés pour gérer toutes vos transactions efficacement. Améliorez l'expérience de vos clients et augmentez vos ventes avec nos solutions POS fiables et polyvalentes",

        invoiceNo: "No de facture",
        howTo: "Comment",
        activate: "Activer",
        step: "Étape",
        giftToFriend: "Offrir à un ami",
        enterEmailTwice: "Veuillez entrer deux fois l'adresse e-mail de votre ami pour confirmation.",
        friendEmail: "E-mail de l'ami",
        confirmFriendsEmail: "Confirmer l'e-mail de l'ami",
        emailsDoNotMatch: "Les adresses e-mail ne correspondent pas. Veuillez réessayer.",
        sendGift: "Envoyer le cadeau",
        giftSent: "Cadeau envoyé",
        giftSentMessage: "Nous avons envoyé la clé en cadeau à votre ami à l'adresse e-mail suivante :",
        close: "Fermer",


        buyers: "Acheteurs",
        reviews: "Avis",
        user: "Utilisateur",
        comment: "Commentaire",

        requirements: "Exigences",
        minimumRequirements: "Exigences minimales",
        recommendedRequirements: "Exigences recommandées",
        OS: "OS",
        Processor: "Processeur",
        Memory: "Mémoire",
        Graphics: "Graphiques",
        DirectX: "DirectX",
        Storage: "Stockage",
        AdditionalNotes: "Notes supplémentaires",

        careers: "Carrières",
        contactUs: "Nous contacter",
        updatePersonalData: "Mettre à jour les données de facturation",
        updateApplicationSettings: "Mettre à jour les paramètres de l'application",
        birthday: "Anniversaire",
        yourName: "Votre prénom",
        yourLastName: "Votre nom",
        enterEmail: "Entrez votre adresse e-mail",
        yourEmail: "Votre adresse e-mail",
        changePassword: "Changer le mot de passe",
        currentPassword: "Mot de passe actuel",
        newPassword: "Nouveau mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        showLess: "Montrer moins",
        showMore: "Montrer plus",
        PageNotFound: "Page non trouvée",
        DontGiveUp: "Ne vous découragez pas, peut-être trouverez-vous ici ce que vous cherchez.",
        Jobs: "Emplois",
        ElevateYourCareer: "Élevez votre carrière chez Bigswiss GmbH, où l'innovation et la passion se rencontrent. Plongez dans un monde où votre travail façonne directement l'avenir du jeu et de la technologie.",
        exploreOpportunities: "Explorez les opportunités, relevez les défis et faites partie d'une équipe dynamique dédiée à l'excellence. Votre voyage pour avoir un impact commence ici.",
        enterYourEmailAddress: "Entrez votre adresse email",
        yourEmailAddress: "Votre adresse email",
        contactUsAgree: 'En cliquant sur "Contactez-nous", vous acceptez nos',
        termsAndConditions: "Conditions générales",
        support: "Support",
        contactDescription: "Des demandes de renseignements sur notre boutique en ligne innovante et notre magasin de gros à l'assistance pour nos applications de kiosque pour les achats de jeux vidéo et le magasin API de gros, nous sommes là pour améliorer votre expérience.",
        connectTeam: "Connectez-vous avec notre équipe dédiée pour des fonctionnalités et des services de premier ordre conçus juste pour vous.",

        pricingDescription: "Choisissez le plan qui convient à vos besoins",
        basic: "Basique",
        pro: "Pro",
        enterprise: "Entreprise",
        getStarted: "Commencer",
        startNow: "Commencez maintenant",
        mostPopular: "Le plus populaire",
        perMonth: "par mois",
        basicDesc1: "Accès API pour jusqu'à 100 demandes par mois",
        basicDesc2: "Analyses et rapports de base",
        basicDesc3: "Support standard",
        proDesc1: "Accès API pour jusqu'à 10 000 demandes par mois",
        proDesc2: "Analyses et rapports avancés",
        proDesc3: "Support prioritaire",
        proDesc4: "Accès à des offres exclusives de jeux et de cartes d'abonnement",
        enterpriseDesc1: "Requêtes API illimitées",
        enterpriseDesc2: "Analyses et rapports personnalisés",
        enterpriseDesc3: "Support dédié 24/7",
        enterpriseDesc4: "Solutions de terminal POS personnalisées",
        enterpriseDesc5: "Meilleurs prix pour les achats en gros",


        chooseActivation: "Choisissez où vous souhaitez activer votre produit.",
        thankYouForPurchase: "Merci pour votre achat!",
        findYourKeysBellow: "Vous trouverez vos clés et votre facture ci-dessous.",
        productKey: "Clé de produit",
        productKeys: "Clés de produit",
        productActivation: "Activation du produit",
        productActivationDescription: "Veuillez suivre les instructions ci-dessous pour activer votre produit.",
        sendAsGift: "Offrir",
        hide: "Cacher",
        viewKeys: "Voir les clés",
        purchase_review_pending: "Votre achat est en cours de traitement. Vous recevrez un e-mail une fois qu'il sera approuvé.",
        getting_key_please_wait: "Veuillez patienter, nous récupérons votre clé...",
        downloadInvoice: "Télécharger la facture",
        download: "Télécharger",
        back: "Retour",
        next: "Suivant",
        activationMethod: "Méthode d'activation",
        keylessActivation: "Activation sans clé",
        activationLink: "Lien d'activation",
        enterTheKey: "Entrez la clé",
        confirmActivation: "Confirmer l'activation",
        battlenetStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Battle.net.",
        goToBattlenetActivation: "Aller à la page d'activation Battle.net",
        battlenetStep2: "Connectez-vous avec vos identifiants Battle.net. Si vous n'avez pas de compte, créez-en un gratuitement.",
        battlenetStep3: "Entrez la clé reçue dans le champ prévu à cet effet, puis cliquez sur 'Racheter'.",
        battlenetStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Battle.net.",
        goToActivation: "Aller à l'activation",
        battlenetStep2app: "Ouvrez l'application Blizzard Battle.net. Et connectez-vous avec vos identifiants Battle.net. Si vous n'avez pas de compte, créez-en un gratuitement.",
        battlenetStep3app: "Cliquez sur votre profil pour ouvrir un menu, puis cliquez sur 'Racheter un code'.",
        goToSteamActivation: "Aller à la page d'activation Steam",
        steamStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Steam.",
        steamStep2: "Connectez-vous avec vos identifiants Steam. Si vous n'avez pas de compte, créez-en gratuitement.",
        steamStep3: "Lisez et acceptez l'accord d'abonnement Steam, puis cliquez sur 'Continuer'.",
        steamStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Steam.",
        steamStep2app: "Ouvrez l'application Steam et connectez-vous avec vos identifiants Steam. Si vous n'avez pas de compte, créez-en un gratuitement.",
        steamStep3app: "Cliquez sur 'Jeux' dans le menu supérieur, puis cliquez sur 'Activer un produit sur Steam'.",
        steamStep4app: "Entrez la clé reçue dans le champ prévu à cet effet, puis cliquez sur 'Valider'.",

        goToMicrosoftActivation: "Aller à la page d'activation du Microsoft Store",
        microsoftStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé du Microsoft Store.",
        microsoftStep2: "Connectez-vous avec vos identifiants Microsoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
        microsoftStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",
        microsoftStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Microsoft.",
        microsoftStep2app: "Sur votre système Windows, ouvrez l'application Microsoft Store et connectez-vous avec vos identifiants Microsoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
        microsoftStep3app: "Cliquez sur l'icône de profil en haut à droite, puis cliquez sur 'Utiliser un code ou une carte cadeau'.",

        goToGOGActivation: "Aller à la page d'activation GOG",
        activateKey: "Activer la clé",
        linkToAccount: "Lier au compte",
        gogStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé GOG.",
        gogStep2: "Cliquez sur 'Continuer' pour confirmer l'activation de la clé.",
        gogStep3: "Cliquez sur 'Se connecter' pour lier la clé à votre compte GOG.",
        gogStep4: "Connectez-vous avec vos identifiants GOG. Si vous n'avez pas de compte, créez-en un gratuitement.",
        gogStep5: "Félicitations ! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte GOG.",

        gogStep2app: "Ouvrez l'application GOG Galaxy et connectez-vous avec vos identifiants GOG. Si vous n'avez pas de compte, créez-en un gratuitement.",
        gogStep3app: "Cliquez sur 'Ajouter des jeux et des amis' dans le menu supérieur, puis sur 'Racheter un code GOG'.",
        gogStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Continuer'.",

        goToEpicActivation: "Aller à la page d'activation Epic Games",
        epicStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Epic Games.",
        epicStep2: "Cliquez sur 'Échanger' pour confirmer l'activation de la clé.",
        epicStep3: "Cliquez sur 'Se connecter' pour lier la clé à votre compte Epic Games.",
        epicStep4: "Connectez-vous avec vos identifiants Epic Games. Si vous n'avez pas de compte, créez-en un gratuitement.",
        epicStep5: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Epic Games.",
        epicStep2app: "Ouvrez l'application Epic Games et connectez-vous avec vos identifiants Epic Games. Si vous n'avez pas de compte, créez-en un gratuitement.",
        epicStep3app: "Cliquez sur votre profil en haut à droite, puis sur 'Échanger un code'.",
        epicStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Échanger'.",

        goToRockstarActivation: "Aller à la page d'activation Rockstar",
        rockstarStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Rockstar.",
        rockstarStep2: "Connectez-vous avec vos identifiants Rockstar. Si vous n'avez pas de compte, créez-en un gratuitement.",
        rockstarStep3: "Veuillez patienter pendant le chargement de la page et la validation Rockstar. Cela peut prendre plusieurs minutes. Une fois la page chargée, vous verrez un message de confirmation. Cliquez sur 'Confirmer' pour activer la clé sur votre compte Rockstar.",
        rockstarStep4: "Félicitations ! Vous avez activé votre produit avec succès et il est prêt à être téléchargé et joué sur votre compte Rockstar.",
        rockstarStep2app: "Ouvrez l'application Rockstar Games Launcher et connectez-vous avec vos identifiants Rockstar. Si vous n'avez pas de compte, créez-en un gratuitement.",
        rockstarStep3app: "Cliquez sur le menu en haut à droite, puis sur 'UTILISER UN CODE'.",
        rockstarStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'VÉRIFIER'.",
        rockstarStep5app: "Lisez et acceptez les conditions générales, puis cliquez sur 'UTILISER'.",

        goToEAPlayActivation: "Aller à la page d'activation EA Play",
        eaStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé EA Play.",
        eaStep2: "Connectez-vous avec vos identifiants EA Play. Si vous n'avez pas de compte, créez-en un gratuitement.",
        eaStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",
        eaStep4: "Cliquez sur le bouton 'Suivant' pour lier la clé à votre compte EA Play.",
        eaStep5: "Félicitations! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte EA Play.",

        eaStep2app: "Ouvrez l'application EA Play et connectez-vous avec vos identifiants EA Play. Si vous n'avez pas de compte, créez-en un gratuitement.",
        eaStep3app: "Cliquez sur le menu en haut à droite, puis cliquez sur 'Utiliser un code'.",
        eaStep4app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'.",

        ubisoftStep2app: "Ouvrez l'application Ubisoft et connectez-vous avec vos identifiants Ubisoft. Si vous n'avez pas de compte, créez-en un gratuitement.",
        ubisoftStep3app: "Cliquez sur le menu de profil en haut à gauche, puis cliquez sur 'Activation de clé'.",
        ubisoftStep4app: "Entrez la clé que vous avez reçue dans le champ prévu, puis cliquez sur 'Activer'.",

        goToSwitchActivation: "Aller à la page d'activation Nintendo Switch",
        openeshop: "Ouvrir eShop",
        selectUser: "Sélectionner l'utilisateur",
        redeem: "Utiliser la clé",
        switchStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de la clé Nintendo Switch.",
        switchStep2: "Connectez-vous avec vos identifiants Nintendo Switch. Si vous n'avez pas de compte, créez-en un gratuitement.",
        switchStep3: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Continuer'.",
        switchStep4: "Félicitations! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte Nintendo Switch.",
        switchStep1app: "Ouvrez l'application Nintendo eShop.",
        switchStep2app: "Sélectionnez le compte sur lequel vous souhaitez activer le produit.",
        switchStep3app: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Confirmer'.",
        switchStep4app: "Sélectionnez 'Utiliser' et cliquez sur 'Confirmer' pour activer le produit sur votre compte Nintendo Switch.",

        goToPlayStationActivation: "Aller à la page d'activation PlayStation",
        openLogin: "Ouvrir la connexion",
        psStep1: "Cliquez ci-dessous pour accéder à la page d'activation PlayStation.",
        psStep2: "Cliquez en haut à droite sur 'Connexion'",
        psStep3: "Connectez-vous avec vos identifiants PlayStation. Si vous n'avez pas de compte, créez-en un gratuitement.",
        psStep4: "Cliquez en haut à droite sur votre profil, puis sur 'Utiliser un code'.",
        psStep5: "Entrez la clé que vous avez reçue dans le champ prévu, puis cliquez sur 'Continuer'.",
        psStep6: "Félicitations! Vous avez activé votre produit avec succès. Il est prêt à être téléchargé et joué sur votre compte PlayStation.",
        openStore: "Ouvrir le Store",
        psStep2console: "Sélectionnez le PlayStation Store à gauche et appuyez sur 'X'.",
        psStep3console: "Appuyez sur '△' pour accéder au menu en haut à droite, sélectionnez '...' et sélectionnez 'Utiliser un code'.",
        psStep4console: "Entrez le code reçu dans le champ prévu, puis cliquez sur 'Utiliser'.",
        psStep2app: "Ouvrez l'application PlayStation et accédez au PlayStation Store.",
        openMenu: "Ouvrir le menu",
        psStep3app: "Sélectionnez le menu en haut à droite pour afficher plus d'options.",
        psStep4app: "Sélectionnez 'Utiliser un code'.",

        goToXboxActivation: "Aller à la page d'activation Xbox",
        xboxStep1: "Cliquez sur le bouton ci-dessous pour accéder à la page d'activation de clé Xbox.",
        xboxStep2: "Cliquez sur 'Se connecter pour échanger' pour vous connecter avec vos identifiants Xbox. Si vous n'avez pas de compte, créez-en un gratuitement.",
        xboxStep3: "Cliquez sur 'Échanger maintenant' pour ouvrir la fenêtre d'activation de la clé.",
        xboxStep4: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Suivant'. Continuez à suivre les instructions.",
        xboxStep5: "Félicitations ! Vous avez activé votre produit avec succès, et il est prêt à être téléchargé et joué sur votre compte Xbox.",
        xboxStep2console: "Appuyez sur le bouton Xbox de votre manette pour ouvrir le guide. Une fois connecté à votre compte, sélectionnez 'Store'.",
        xboxStep3console: "Une fois ouvert, appuyez sur le bouton Vue de votre manette pour ouvrir le menu, puis sélectionnez 'Échanger'.",
        xboxStep4console: "Entrez la clé reçue dans le champ prévu, puis cliquez sur 'Vérifier le code'.",

        warningModalTitle: "Avis Important",
        warningModalMessage1: "Une fois que vous révélez la clé, vous perdez le droit à un remboursement immédiat.",
        warningModalMessage2: "Vous ne serez éligible à un remboursement que si la clé est invalide.",
        doYouWantToProceed: "Souhaitez-vous continuer?",
        revealKey: "Révéler la clé",
        // CHAT
        helpCenter: "Centre d'aide",
        talkWithourSupport: "Parlez avec notre support",
        haveAquestion: "Vous avez une question?",
        typeAmessage: "Tapez un message...",
        hello: "Bonjour",
        howCanIHelpYou: "Comment puis-je vous aider?",
        home: "Accueil",
        history: "Historique",
        help: "Aide",
        noChatMessages: "Vous n'avez pas encore de conversation.",
        allHelpTopics: "Tous les sujets d'aide",
        searchTopics: "Rechercher des sujets...",
        noTopicsFound: "Aucun sujet trouvé.",

        redeemingCodesCategory: "Utilisation de codes",
        findingCodesCategory: "Recherche de codes",
        invoicesCategory: "Factures",
        codeActivationIssueCategory: "Problèmes d'activation de code",
        identityVerificationCategory: "Vérification d'identité",

        howToRedeem: "Comment utiliser le code ?",
        whereToFindCodes: "Où puis-je trouver mes codes ?",
        whereToGetInvoice: "Où puis-je obtenir une facture pour mon achat ?",
        issuesActivatingCode: "J'ai des problèmes pour activer mon code",
        issuesIdentityVerification: "J'ai des problèmes avec ma vérification d'identité",

        howToRedeemDesc: "Suivez les étapes ci-dessous pour utiliser votre code avec succès :",
        findingCodesDesc: "Suivez les étapes ci-dessous pour trouver votre code :",
        invoicesDesc: "Pour accéder à une facture de votre achat, suivez les étapes ci-dessous :",
        activationIssueDesc: "Si vous rencontrez des problèmes pour activer votre code, veuillez vérifier les points suivants :",
        identityVerificationDesc: "Si vous rencontrez des difficultés avec la vérification d'identité, assurez-vous de :",

        accessAccountTitle: "Accéder à votre compte",
        accessAccountDesc: "Cliquez sur l'icône utilisateur située en haut à droite de la page.",

        navigatePurchasesTitle: "Aller aux achats",
        navigatePurchasesDesc: "Dans le menu déroulant, sélectionnez « Achats » pour voir vos transactions récentes.",

        selectRelevantPurchaseTitle: "Sélectionner l'achat concerné",
        selectRelevantPurchaseDesc: "Dans le tableau des achats, sélectionnez la transaction associée à la clé que vous souhaitez activer.",

        viewKeysTitle: "Afficher les clés disponibles",
        viewKeysDesc: "Cliquez sur « Afficher les clés » pour afficher une liste des clés de produit disponibles pour l'activation.",

        revealKeyTitle: "Révéler la clé",
        revealKeyDesc: "Cliquez sur l'icône de l'œil pour révéler la clé que vous souhaitez activer.",

        activateKeyTitle: "Activer la clé",
        activateKeyDesc: "Cliquez sur le bouton « Activer » et suivez les instructions à l'écran.",

        downloadInvoiceTitle: "Télécharger la facture",
        downloadInvoiceDesc: "Cliquez sur le bouton « Télécharger la facture » pour obtenir une copie PDF de votre facture.",

        checkTyposTitle: "Vérifier les fautes de frappe",
        checkTyposDesc: "Assurez-vous d'avoir saisi le code correctement, sans faute de frappe.",

        verifyUsageTitle: "Vérifier l'utilisation du code",
        verifyUsageDesc: "Vérifiez que le code n'a pas déjà été utilisé.",

        correctPlatformTitle: "Plateforme correcte",
        correctPlatformDesc: "Vérifiez que vous utilisez le code sur la bonne plateforme ou application.",

        clearDocumentsTitle: "Documents clairs",
        clearDocumentsDesc: "Vos documents d'identification doivent être clairs et lisibles.",

        matchPersonalInfoTitle: "Correspondance des informations personnelles",
        matchPersonalInfoDesc: "Vos informations personnelles doivent correspondre aux registres.",

        supportedVerificationMethodTitle: "Méthode de vérification prise en charge",
        supportedVerificationMethodDesc: "Vous utilisez une méthode de vérification prise en charge.",

        notesContactSupport: "Si le problème persiste, veuillez contacter notre équipe d'assistance pour plus d'aide.",
        stillHavingIssue: "Vous rencontrez toujours des problèmes avec",
        pleaseLoginToSeeTicketHistory: "Veuillez vous connecter pour voir l'historique de vos tickets.",
    },
};
