// HomePage.tsx
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';
import {
  useFetchSettingsQuery,
  useFetchPlatformsQuery,
  useFetchCategoriesQuery,
  useFetchTypesQuery,
  useFetchRegionsQuery,
} from 'redux/RTK/optionsApi';

import { getPlatformName, getRegionName, getCategoryName, getTypeName } from 'utils/getNames';
import LoadingAnimation from 'components/Animations/LoadingAnimation';
import SEO from 'utils/SEO';
import { HomeCover } from './Components/HomeCover';
import BestSellerGrid from './Components/BestSellerGrid';
import WhyUs from './Components/WhyUs';
// File: src/redux/RTK/optionsApi.ts
import { Settings } from 'redux/types/dataTypes';

export default function HomePage() {
  // 1) The server returns a single doc for settings, not an array
  const { data: homeConfig = {} as Settings, isLoading: isSetLoading } = useFetchSettingsQuery();
    // 2) Other queries
  const { data: platforms = [], isLoading: isPlatLoading } = useFetchPlatformsQuery();
  const { data: categories = [], isLoading: isCatLoading } = useFetchCategoriesQuery();
  const { data: types = [], isLoading: isTypeLoading } = useFetchTypesQuery();
  const { data: regions = [], isLoading: isRegLoading } = useFetchRegionsQuery();

  // 3) Also check if “homeConfig” doc is loaded
  // If your doc has shape: { coverProduct, pc, xb, ps, nt, etc. }
  const language = useSelector((state: RootState) => state.auth.language);

  // 4) If still loading or no doc
  const isStillLoading = isSetLoading || isPlatLoading || isCatLoading || isTypeLoading || isRegLoading;

  if (isStillLoading) {
    return <LoadingAnimation />;
  }
  if (!homeConfig) {
    return <div>No home config doc found</div>;
  }

  // 7) Build <HomeCover> data
  const coverImageUrl = homeConfig.coverProductDetail?._id || 'someDefaultValue';
  const heroTitle = homeConfig.coverProductDetail?.title;
  const heroType = getTypeName(homeConfig.coverProductDetail?.type, types);
  const heroProductId = homeConfig.coverProductDetail?._id;

  const platformData = homeConfig.coverProductDetail?.platforms.map((p: { platformId: string; price: number; category: string; region: string }) => ({
    imageUrl: p.platformId,
    price: p.price,
    category: p.category,
    platformName: getPlatformName(p.platformId, platforms),
    regionName: getRegionName(p.region, regions),
  }));


  return (
    <>
      <SEO
        title="Bigswiss GmbH"
        description="Bigswiss is a digital marketplace..."
        keywords="Bigswiss..."
        image="https://bigswiss.ch/img/OG.jpg"
      />

      <HomeCover
        productCoverImg={coverImageUrl}
        heroTitle={heroTitle}
        heroType={heroType}
        productId={heroProductId}
        platforms={platformData}
      />

      <Container>
        <WhyUs />

        {/* For each array in homeConfig => only if it's not empty */}
        {homeConfig.pc && homeConfig.pc.length > 0 && (
          <BestSellerGrid
            category="pc"
            categoryTitle={getCategoryName('pc', categories)}
            productsData={homeConfig.pc}
            loading={false}
          />
        )}
        {homeConfig.xb && homeConfig.xb.length > 0 && (
          <BestSellerGrid
            category="xb"
            categoryTitle={getCategoryName('xb', categories)}
            productsData={homeConfig.xb}
            loading={false}
          />
        )}
        {homeConfig.ps && homeConfig.ps.length > 0 && (
          <BestSellerGrid
            category="ps"
            categoryTitle={getCategoryName('ps', categories)}
            productsData={homeConfig.ps}
            loading={false}
          />
        )}
        {homeConfig.nt && homeConfig.nt.length > 0 && (
          <BestSellerGrid
            category="nt"
            categoryTitle={getCategoryName('nt', categories)}
            productsData={homeConfig.nt}
            loading={false}
          />
        )}
      </Container>
    </>
  );
}