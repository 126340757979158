import React, { useState, useEffect, useRef } from 'react';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import { useFetchProductByIdAndPlatformQuery } from 'redux/RTK/productsApi';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  InputAdornment,
  CardMedia,
  Collapse,
  CircularProgress,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFetchPlatformsQuery, useFetchRegionsQuery } from 'redux/RTK/optionsApi';

import PlatformLogo from 'components/icons/PlatformLogos';
import { getProductImgSm, getPlatformName, getRegionName } from 'utils/getNames';
import { useTranslation } from 'react-i18next';
import { activateKey } from 'redux/key/keyActivationThunk';
import { renderInstructions } from './Instructions/Instructions';

import WarningModal from './WarningModal';
import { keyframes } from '@mui/system';
import ProductKeysAction from './ProductKeysAction';

interface BundleSubKey {
  _id?: string;
  gnSKU?: string;
  productName?: string;
  // any other fields
}

interface ProductKeysProps {
  productId: string;
  invoiceId: string;
  platformGroupId: string;
  keyCode?: string | BundleSubKey[]; // single key or array of sub-keys
  isBundle?: boolean;
  keyIsRevealed?: boolean; // if the entire item was "pre revealed" from the server
  isGifted?: string | null | undefined;
  productKeysRef: React.RefObject<HTMLDivElement | null>;
  hideImage?: boolean;
}

// Keyframes for the blinking effect
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const ProductKeys: React.FC<ProductKeysProps> = ({
  keyCode,
  productId,
  platformGroupId,
  invoiceId,
  hideImage = false,
  isBundle = false,
  keyIsRevealed,
  isGifted,
  productKeysRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  // ---------------------------------------------------------------------------
  // SINGLE-KEY STATES
  // ---------------------------------------------------------------------------
  const [showKey, setShowKey] = useState(false);
  const [retrievedKey, setRetrievedKey] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false); // single-key modal
  const [actualKey, setActualKey] = useState<string>(
    typeof keyCode === 'string' ? keyCode : ''
  );

  // ---------------------------------------------------------------------------
  // BUNDLE STATES
  // ---------------------------------------------------------------------------
  // Only used if isBundle=true. For each subKeyId, we track:
  //  subKeyShow[subKeyId] => whether that sub-key is revealed
  //  subKeyRetrieved[subKeyId] => the retrieved code for that sub-key
  const [bundleHasRevealed, setBundleHasRevealed] = useState(false);
  const [subKeyShow, setSubKeyShow] = useState<Record<string, boolean>>({});
  const [subKeyRetrieved, setSubKeyRetrieved] = useState<Record<string, string>>({});

  // We can track a "pendingSubKey" to reveal if we do need a modal for the first time
  const [pendingSubKey, setPendingSubKey] = useState<string>('');

  // We store a "bundleModalOpen" if needed
  const [bundleModalOpen, setBundleModalOpen] = useState(false);

  // ---------------------------------------------------------------------------
  // SHARED UI STATES
  // ---------------------------------------------------------------------------
  const [showInstructions, setShowInstructions] = useState(false);

  // Product info
  const [title, setTitle] = useState('');
  const [platformDetails, setPlatformDetails] = useState<{
    platformId: string;
    region: string;
  } | null>(null);

  const cardRef = useRef<HTMLDivElement>(null);

  // MUI queries
  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: regions = [] } = useFetchRegionsQuery();

  // RTK Query to fetch product info
  const { data: productInfo } = useFetchProductByIdAndPlatformQuery({
    productId,
    platformId: platformGroupId,
    lang: 'en',
  });

  // Populate local product info
  useEffect(() => {
    if (productInfo) {
      setTitle(productInfo.title || '');
      setPlatformDetails({
        platformId: productInfo.platformId || '',
        region: productInfo.region,
      });
    }
  }, [productInfo]);

  // ---------------------------------------------------------------------------
  // SINGLE KEY LOGIC
  // ---------------------------------------------------------------------------
  const handleSingleRevealKey = (keyIdParam: string) => {
    if (!keyIdParam) {
      console.error('Cannot reveal an empty keyId.');
      return;
    }
    if (keyIsRevealed) {
      // Already "confirmed" => directly dispatch
      dispatch(activateKey({ keyId: keyIdParam, invoiceId }))
        .unwrap()
        .then((retrieved) => {
          setRetrievedKey(retrieved);
          setShowKey(true);
        })
        .catch((error) => {
          console.error('Failed to retrieve key', error);
        });
      return;
    }
    // Show modal for single scenario
    setModalOpen(true);
    setActualKey(keyIdParam);
  };

  const handleSingleModalConfirm = () => {
    setModalOpen(false);
    if (!actualKey) {
      console.error('Missing actualKey for reveal.');
      return;
    }
    dispatch(activateKey({ keyId: actualKey, invoiceId }))
      .unwrap()
      .then((retrieved) => {
        setRetrievedKey(retrieved);
        setShowKey(true);
      })
      .catch((error) => {
        console.error('Failed to retrieve key', error);
      });
  };

  // ---------------------------------------------------------------------------
  // BUNDLE REVEAL LOGIC
  // ---------------------------------------------------------------------------
  /** Called when user clicks the "eye" on a subKey in a bundle. */
  const handleBundleRevealKey = (subKeyId: string) => {
    if (!subKeyId) {
      console.error('Cannot reveal an empty subKeyId.');
      return;
    }

    // Already revealed? Do nothing
    if (subKeyShow[subKeyId]) {
      return;
    }

    // If keyIsRevealed = true => that means "no modal"? We just dispatch
    // or if we've already shown the modal once => dispatch
    if (keyIsRevealed || bundleHasRevealed) {
      dispatch(activateKey({ keyId: subKeyId, invoiceId }))
        .unwrap()
        .then((retrieved) => {
          // store the code for that subKey
          setSubKeyRetrieved((prev) => ({
            ...prev,
            [subKeyId]: retrieved
          }));
          setSubKeyShow((prev) => ({
            ...prev,
            [subKeyId]: true
          }));
        })
        .catch((error) => {
          console.error('Failed to retrieve key', error);
        });
      return;
    }

    // Otherwise => first time => show the modal
    setBundleModalOpen(true);
    setPendingSubKey(subKeyId);
  };

  /** Called when user confirms the warning modal for a BUNDLE subKey. */
  const handleBundleModalConfirm = () => {
    setBundleModalOpen(false);
    if (!pendingSubKey) {
      console.error('No pending subKey to reveal');
      return;
    }
    // Dispatch
    dispatch(activateKey({ keyId: pendingSubKey, invoiceId }))
      .unwrap()
      .then((retrieved) => {
        // store the code for that subKey
        setSubKeyRetrieved((prev) => ({
          ...prev,
          [pendingSubKey]: retrieved
        }));
        setSubKeyShow((prev) => ({
          ...prev,
          [pendingSubKey]: true
        }));

        // now we won't show the modal for subsequent subKeys
        setBundleHasRevealed(true);
        setPendingSubKey('');
      })
      .catch((error) => {
        console.error('Failed to retrieve key', error);
      });
  };

  const handleBundleModalClose = () => {
    setBundleModalOpen(false);
    setPendingSubKey('');
  };

  // ---------------------------------------------------------------------------
  // COPY & TOGGLE INSTRUCTIONS
  // ---------------------------------------------------------------------------
  const handleCopyToClipboard = (val: string) => {
    navigator.clipboard.writeText(val);
  };

  const handleToggleInstructions = () => {
    setShowInstructions((prev) => {
      if (prev && productKeysRef.current) {
        const offsetTop =
          productKeysRef.current.getBoundingClientRect().top +
          window.pageYOffset -
          100;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      } else if (!prev && cardRef.current) {
        const offsetTop =
          cardRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
      return !prev;
    });
  };

  // ---------------------------------------------------------------------------
  // RENDER HELPERS
  // ---------------------------------------------------------------------------
  /** For single-key card (non-bundle). */
  const renderSingleCard = (displayedKey: string, displayedTitle: string) => {
    const revealedText = showKey
      ? retrievedKey || displayedKey
      : '•••••-•••••-•••••';

    return (
      <Card
        ref={cardRef}
        sx={{ display: 'flex', flexDirection: 'column', marginBottom: 5, width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!hideImage && (
            <CardMedia
              component="img"
              sx={{ width: 250, height: 160, objectFit: 'cover', display: { xs: 'none', md: 'block' } }}
              image={getProductImgSm(productId)}
              alt={displayedTitle}
            />
          )}

          <CardContent sx={{ flex: '1 0 auto', marginRight: 'auto' }}>
            <Typography
              variant="h6"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
                minWidth: 0,
                mb: 1
              }}
            >
              {displayedTitle}
            </Typography>

            {platformDetails && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center', mt: 0 }}
              >
                <PlatformLogo
                  platformId={platformDetails.platformId}
                  sx={{ width: 24, height: 24, mr: 1 }}
                />
                {getPlatformName(platformDetails.platformId, platforms)} -{' '}
                {getRegionName(platformDetails.region, regions)}
              </Typography>
            )}

            {displayedKey === '' ? (
              <Typography variant="body1" color="text.secondary" sx={{ mt: 3 }}>
                <CircularProgress size={16} sx={{ mr: 2 }} />
                {t('getting_key_please_wait')}
              </Typography>
            ) : (
              <TextField
                label="Key"
                variant="outlined"
                value={revealedText}
                fullWidth
                margin="normal"
                disabled={showKey}
                sx={{ maxWidth: '290px', }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {!showKey && (
                          <IconButton
                            onClick={() => handleSingleRevealKey(displayedKey)}
                            edge="end"
                            sx={{
                              animation: `${blink} 2s infinite`,
                              color: 'secondary.main',
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        )}
                        {showKey && (
                          <IconButton
                            onClick={() => handleCopyToClipboard(revealedText)}
                            edge="end"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          </CardContent>

          {displayedKey !== '' && (
            <ProductKeysAction
              isGifted={isGifted}
              showKey={showKey}
              showInstructions={showInstructions}
              handleToggleInstructions={handleToggleInstructions}
            />
          )}
        </Box>

        <Collapse in={showInstructions} timeout="auto" unmountOnExit>
          <CardContent>
            {renderInstructions({
              platformDetails,
              actualKey: showKey ? (retrievedKey || displayedKey) : displayedKey,
            }) as React.ReactNode}
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  /** For one sub-key card in a bundle. */
  const renderBundleSubCard = (subKeyId: string, subTitle: string) => {
    // subKeyShow[subKeyId] indicates if we've revealed this sub-key
    // subKeyRetrieved[subKeyId] is the code we got from the backend
    const isRevealed = subKeyShow[subKeyId] || false;
    const returnedCode = subKeyRetrieved[subKeyId] || '';
    const revealedText = isRevealed
      ? returnedCode || subKeyId // if we do want to fallback to subKeyId
      : '•••••-•••••-•••••';

    return (
      <Card
        ref={cardRef}
        sx={{ display: 'flex', flexDirection: 'column', marginBottom: 5, width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!hideImage && (
            <CardMedia
              component="img"
              sx={{ width: 250, height: 160, objectFit: 'cover', display: { xs: 'none', md: 'block' } }}
              image={getProductImgSm(productId)}
              alt={subTitle}
            />
          )}

          <CardContent sx={{ flex: '1 0 auto', marginRight: 'auto' }}>
            <Typography
              variant="h6"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
                minWidth: 0,
                mb: 1
              }}
            >
              {subTitle}
            </Typography>

            {platformDetails && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center', mt: 0 }}
              >
                <PlatformLogo
                  platformId={platformDetails.platformId}
                  sx={{ width: 24, height: 24, mr: 1 }}
                />
                {getPlatformName(platformDetails.platformId, platforms)} -{' '}
                {getRegionName(platformDetails.region, regions)}
              </Typography>
            )}

            {/* If subKeyId is empty => waiting for payment or something */}
            {subKeyId === '' ? (
              <Typography variant="body1" color="text.secondary" sx={{ mt: 3 }}>
                <CircularProgress size={16} sx={{ mr: 2 }} />
                {t('getting_key_please_wait')}
              </Typography>
            ) : (
              <TextField
                label="Key"
                variant="outlined"
                value={revealedText}
                fullWidth
                margin="normal"
                disabled={isRevealed}
                sx={{ maxWidth: '290px', }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {!isRevealed && (
                          <IconButton
                            onClick={() => handleBundleRevealKey(subKeyId)}
                            edge="end"
                            sx={{
                              animation: `${blink} 2s infinite`,
                              color: 'secondary.main',
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        )}
                        {isRevealed && (
                          <IconButton
                            onClick={() => handleCopyToClipboard(revealedText)}
                            edge="end"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          </CardContent>

          {subKeyId !== '' && (
            <ProductKeysAction
              isGifted={isGifted}
              showKey={isRevealed}
              showInstructions={showInstructions}
              handleToggleInstructions={handleToggleInstructions}
            />
          )}
        </Box>

        <Collapse in={showInstructions} timeout="auto" unmountOnExit>
          <CardContent>
            {renderInstructions({
              platformDetails,
              actualKey: isRevealed ? returnedCode : subKeyId,
            }) as React.ReactNode}
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  // ---------------------------------------------------------------------------
  // MAIN RENDER
  // ---------------------------------------------------------------------------
  // If NOT a bundle => single-key approach
  if (!isBundle || !Array.isArray(keyCode)) {
    return (
      <>
        {renderSingleCard(actualKey, title)}
        {/* Single-key modal */}
        {!keyIsRevealed && (
          <WarningModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={handleSingleModalConfirm}
          />
        )}
      </>
    );
  }

  // If BUNDLE => map sub-keys
  return (
    <>
      <Typography variant="h4" sx={{ mb: 3 }} id="product-keys">
        {title}
      </Typography>

      {keyCode.map((sub, idx) => {
        const subTitle = sub.productName || title || `Bundle Key ${idx + 1}`;
        const subKeyId = sub._id || '';

        return (
          <React.Fragment key={subKeyId || idx}>
            {renderBundleSubCard(String(subKeyId), subTitle)}
          </React.Fragment>
        );
      })}

      {/* The single bundle warning modal, if the user hasn't revealed anything yet */}
      {!keyIsRevealed && (
        <WarningModal
          open={bundleModalOpen}
          onClose={handleBundleModalClose}
          onConfirm={handleBundleModalConfirm}
        />
      )}
    </>
  );
};

export default ProductKeys;