import React, { useState } from 'react';
import { Button, Stack, Typography, Tooltip, CardActions } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import GiftToFriendModal from './GiftToFriendModal';

interface ProductKeysActionProps {
    isGifted: string | null | undefined;
    showKey: boolean;
    showInstructions: boolean;
    handleToggleInstructions: () => void;
}

const ProductKeysAction: React.FC<ProductKeysActionProps> = ({
    showKey,
    isGifted,
    showInstructions,
    handleToggleInstructions,

}) => {
    const { t } = useTranslation();
    const [giftModalOpen, setGiftModalOpen] = useState(false);

    const handleGiftModalOpen = () => setGiftModalOpen(true);
    const handleGiftModalClose = () => setGiftModalOpen(false);

    const handleSendGift = (email: string) => {
        console.log(`Gift sent to ${email}`);
    };
    
    return (
        <>
            <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', width: '320px', p: 2 }}>
                <Typography variant="h6" sx={{ alignSelf: 'left', ml: '8px', mb: 0 }}>
                    {t('productActivation')}
                </Typography>
                <Typography variant="body2" sx={{ alignSelf: 'left' }}>
                    {t('productActivationDescription')}
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                    <Tooltip title={showKey ? '' : t('inOrderToActivateRevealKey')} arrow>
                        <span style={{ width: '100%' }}>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                startIcon={
                                    <PlayArrowIcon
                                        sx={{
                                            transition: 'transform 0.3s ease',
                                            transform: showInstructions ? 'rotate(90deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                }
                                onClick={handleToggleInstructions}
                                disabled={!showKey}
                            >
                                {showInstructions ? t('hide') : t('activate')}
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={showKey ? '' : t('inOrderToSendGiftRevealKey')} arrow>
                        <span style={{ width: '100%' }}>
                            <Button
                                sx={{ width: '100%' }}
                                variant="contained"
                                startIcon={isGifted ? <SendIcon /> : <CardGiftcardIcon />}
                                onClick={handleGiftModalOpen}
                                disabled={!showKey}
                            >
                                {t('sendAsGift')}
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>
            </CardActions>

            <GiftToFriendModal open={giftModalOpen} onClose={handleGiftModalClose} onSendGift={handleSendGift} />
        </>
    );
};

export default ProductKeysAction;
