// NewChat.tsx
import React from 'react';
import { Card, CardActionArea, CardHeader } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

interface NewChatProps {
  title: string;
  subheader: string;
  topic?: string;
  onClick: (topic?: string) => void;
}

const NewChat: React.FC<NewChatProps> = ({ title, subheader, topic, onClick }) => {
  return (
    <Card sx={{ textAlign: 'left' }}>
      <CardActionArea onClick={() => onClick(topic)}>
        <CardHeader title={title} subheader={subheader} avatar={<ChatIcon />} />
      </CardActionArea>
    </Card>
  );
};

export default NewChat;
