import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem,Grid2 as Grid, TextField, Button, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { setLanguage } from 'redux/auth/authSlice';
import EnglishFlag from 'components/icons/flags/en';
import GermanSwissFlag from 'components/icons/flags/de';
import FrenchSwissFlag from 'components/icons/flags/fr';
import ItalianFlag from 'components/icons/flags/it';
import SpanishFlag from './icons/flags/es';
import { useSnackbar } from 'notistack';
import CustomSnackbarContent from 'components/Snack';

const languages = [
  { code: 'de', name: 'Deutsch', icon: <GermanSwissFlag sx={{ mr: 2, height: '20px', width: '30px' }} /> },
  { code: 'en', name: 'English', icon: <EnglishFlag sx={{ mr: 2, height: '20px', width: '30px' }} /> },
  { code: 'es', name: 'Español', icon: <SpanishFlag sx={{ mr: 2, height: '20px', width: '30px' }} /> },
  { code: 'fr', name: 'Français', icon: <FrenchSwissFlag sx={{ mr: 2, height: '20px', width: '30px' }} /> },
  { code: 'it', name: 'Italiano', icon: <ItalianFlag sx={{ mr: 2, height: '20px', width: '30px' }} /> },
];

interface LanguageSwitcherProps {
  useButtons?: boolean;
  fullWidth?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ useButtons, fullWidth }) => {
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();
  const currentLanguage = i18n.language;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const storedLanguage = localStorage.getItem('userLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      dispatch(setLanguage(storedLanguage));
    } else {
      i18n.changeLanguage('en');
      dispatch(setLanguage('en'));
      localStorage.setItem('userLanguage', 'en');
    }
  }, [i18n, dispatch]);

  const changeLanguage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    dispatch(setLanguage(newLanguage));
    localStorage.setItem('userLanguage', newLanguage);

    const language = languages.find((lang) => lang.code === newLanguage);
    if (language) {
      enqueueSnackbar(
        <CustomSnackbarContent
          message={t('selectedLanguage')}
          icon={language.icon}
        />
      );
    }
  };

  if (useButtons) {
    return (
      <Grid container spacing={2}> 
        {languages.map((lang) => (
          <Grid size={{ xs: 12, sm: 6, md: 3 }} key={lang.code}>
            <Button
              variant="outlined"
              onClick={() => changeLanguage({ target: { value: lang.code } } as React.ChangeEvent<HTMLInputElement>)}
              startIcon={lang.icon}
              disabled={lang.code === currentLanguage}
              aria-label={lang.name}
            >
              {lang.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <TextField
      select
      label={t('selectLanguageLabel')}
      aria-label={t('selectLanguageLabel')} 
      sx={{ maxWidth: fullWidth || isSmallScreen ? '100%' : 200 }}
      fullWidth={fullWidth || isSmallScreen}
      value={localStorage.getItem('userLanguage') || 'en'}
      onChange={changeLanguage}
      variant="outlined"
      size="small"
      slotProps={{ select: { MenuProps: { PaperProps: { style: { width: fullWidth || isSmallScreen ? 'auto' : 160 } } } } }}

    >
      {languages.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          {lang.icon}
          {lang.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSwitcher;
