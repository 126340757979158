// NormalLayout.tsx
import React, { useState } from 'react';
import AppHeader from '../components/Header/AppHeaderWrapper';
import Footer from '../components/Footer/Footer';
import { Divider, Box } from '@mui/material';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

interface NormalLayoutProps {
    children: React.ReactNode;
}

const NormalLayout: React.FC<NormalLayoutProps> = ({ children }) => {
    const [$duration] = useState(300);
    const fadeOut = useSelector((state: RootState) => state.auth.mainLayoutFade);

    return (
        <Box sx={{ transition: 'opacity 2s ease', opacity: fadeOut ? 0 : 1, }} >
            <AppHeader $duration={$duration} />
            {children}
            <Divider />
            <Footer />
        </Box>
    );
};

export default NormalLayout;
