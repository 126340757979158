import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { activateKey } from './keyActivationThunk';

interface KeyState {
    key?: string;
    loading: boolean;
    error?: string | null;
}

const initialState: KeyState = {
    key: undefined,
    loading: false,
    error: null,
};

const keySlice = createSlice({
    name: 'keys',
    initialState,
    reducers: {    },
    extraReducers: (builder) => {
        builder.addCase(activateKey.pending, (state) => {
            state.loading = true;
            state.error = null;
        });

        builder.addCase(activateKey.fulfilled, (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.key = action.payload;
        });

        builder.addCase(activateKey.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default keySlice.reducer;
