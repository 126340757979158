import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosDefault, { AxiosError } from 'axios';
import axiosGetToken from '../auth/axiosGetToken';

interface PaymentIntentResponse {
    clientSecret: string;
    paymentId: string;
}

interface PaymentIntentRequest {
    amount: number;
    items: {
        productId: string;
        platformGroupId: string;
        price: number;
        quantity: number;
    }[];
}
// Thunk for fetching order status


export const createPaymentIntent = createAsyncThunk<PaymentIntentResponse, PaymentIntentRequest, { rejectValue: string }>(
    'payment/create-payment-intent',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosGetToken.put("/payment/create-payment-intent", data);
            if (response.status === 200) {
                return {
                    clientSecret: response.data.clientSecret,
                    paymentId: response.data.paymentId
                };
            } else {
                return rejectWithValue('Failed to create payment intent due to non-200 response');
            }
        } catch (error: any) {
            if (axiosDefault.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    if (axiosError.response.status === 429) {
                        return rejectWithValue('Too many failed requests');
                    }
                    return rejectWithValue((axiosError.response.data as { message: string }).message || 'An unexpected error occurred');
                }
            }
            return rejectWithValue('An unexpected error occurred');
        }
    }
);

export const verifyGameActivation = createAsyncThunk(
    'payment/verify-game-activation',
    async (queryParams: { paymentIntent: string; clientSecret: string }, { rejectWithValue }) => {
        try {
            const { paymentIntent, clientSecret } = queryParams;
            const response = await axiosGetToken.get(`/payment/game-activation`, {
                params: {
                    payment_intent: paymentIntent,
                    payment_intent_client_secret: clientSecret,
                }
            });

            if (response.status === 200) {
                return response.data.paymentId;
            } else {
                return rejectWithValue('Unauthorized or invalid payment intent.');
            }
        } catch (error: any) {
            if (axiosDefault.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    const errorMessage = (axiosError.response.data as { message: string }).message || 'Failed to verify payment.';
                    // Check for specific error message indicating payment is still processing
                    if (errorMessage.includes('Payment is still processing')) {
                        return rejectWithValue('Payment is still processing.');
                    }
                    return rejectWithValue(errorMessage);
                }
            }
            return rejectWithValue('An unexpected error occurred.');
        }
    }
);
