// src/components/HelpFAQ.tsx

import React from 'react';
import { Card, List, ListItemButton, ListItemText, Typography, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TopicData } from './topics/topicsData';

interface HelpFAQProps {
    topics: TopicData[];
    onTopicSelect: (topicTitle: string) => void;
}

const HelpFAQ: React.FC<HelpFAQProps> = ({ topics, onTopicSelect }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Card sx={{ width: '100%', padding: 2, pb: 0 }}>

                <Typography variant="h6" textAlign={'center'}>
                    FAQ
                </Typography>
                <List sx={{ mb: 1 }}>
                {topics.filter((topic) => topic.faq).map((topic) => (
                        <ListItemButton key={topic.topic} onClick={() => onTopicSelect(topic.topic)}>
                            <ListItemText primary={topic.topic} />
                            <ChevronRightIcon />
                        </ListItemButton>
                    ))}
                </List>
            </Card>
        </Box>
    );
};

export default HelpFAQ;
