import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography, Card, Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link as RouterLink } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';

import ProductComponent from 'pages/CartPage/Components/CartProductItem';
import { RootState, AppDispatch } from 'redux/store';
import {
    addItem,
    removeItem,
    deleteItem,
    updateItemQuantity,
    maxProductAllowed,
} from 'redux/reducers/cartReducer';

// Updated RTK Query hook
import { useFetchProductByIdQuery } from 'redux/RTK/productsApi';
import { CartItem } from 'redux/types/cartTypes';

import { getPlatformName, getRegionName, getCategoryName } from 'utils/getNames';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ProductPriceTag from 'components/ProductPriceTag';

import {
    useFetchCategoriesQuery,
    useFetchPlatformsQuery,
    useFetchRegionsQuery,
} from 'redux/RTK/optionsApi';

// Helper for Flipper exit animation
function exitAnimation(element: HTMLElement, index: number, removeElement: () => void) {
    element.style.transition = 'opacity 0.5s, transform 0.5s';
    element.style.opacity = '0';
    element.style.transform = 'translateX(-100%)';
    requestAnimationFrame(() => {
        setTimeout(() => {
            removeElement();
        }, 500);
    });
}

interface CartItemsProps {
    onProcessToPayment: () => void;
}

/**
 * A subcomponent that handles fetching the product data for a single cart item,
 * then rendering the row's UI with the existing ProductComponent.
 */
const CartItemRow: React.FC<{
    item: CartItem;
    removed: boolean;
    onAddMore: (id: string) => void;
    onRemove: (id: string) => void;
    onDelete: (id: string) => void;
    onQuantityChange: (id: string, newQty: number) => void;
    categories: any[];
    platforms: any[];
    regions: any[];
}> = ({
    item,
    removed,
    onAddMore,
    onRemove,
    onDelete,
    onQuantityChange,
    categories,
    platforms,
    regions,
}) => {
        // Fetch product by ID via RTK Query
        const { data: productData, isLoading, isError } = useFetchProductByIdQuery(item.productId);

        if (removed || isLoading || isError || !productData) {
            // If it's removed or loading or error, we skip rendering the row
            return null;
        }

        // We have a product; let's find the platform
        const platformDetails = productData.platforms?.find((p) => p._id === item.platformGroupId);

        if (!platformDetails) {
            return null; // no matching platform found
        }

        // Render the row
        return (
            <Grid size={12}>
                <ProductComponent
                    title={productData.title}
                    price={platformDetails.price}
                    imageUrl={productData._id}
                    quantity={item.quantity}
                    category={getCategoryName(platformDetails.category, categories)}
                    platfrom={getPlatformName(platformDetails.platformId, platforms)}
                    platformId={platformDetails.platformId}
                    region={getRegionName(platformDetails.region, regions)}
                    onAdd={() => onAddMore(item.id)}
                    onRemove={() => onRemove(item.id)}
                    onDelete={() => onDelete(item.id)}
                    maxProductAllowed={maxProductAllowed}
                    onQuantityChange={(newQuantity) => onQuantityChange(item.id, newQuantity)}
                />
            </Grid>
        );
    };

const CartItems: React.FC<CartItemsProps> = (props) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    // The entire cart from Redux
    const cartItems = useSelector((state: RootState) => state.cart.items);

    // We track which items have been "removed" so that Flipper can animate them out
    const [removedItems, setRemovedItems] = useState<string[]>([]);

    // We also fetch these option lists (platforms, categories, regions) from your RTK queries
    const { data: platforms = [] } = useFetchPlatformsQuery();
    const { data: categories = [] } = useFetchCategoriesQuery();
    const { data: regions = [] } = useFetchRegionsQuery();

    // Handler: Add more quantity of a cart item
    const handleAddMore = (id: string) => {
        const cartItem = cartItems.find((ci: CartItem) => ci.id === id);
        if (cartItem) {
            dispatch(
                addItem({
                    id: cartItem.id,
                    productId: cartItem.productId,
                    platformGroupId: cartItem.platformGroupId,
                    price: cartItem.price,
                    quantity: 1,
                })
            );
        }
    };

    // Handler: remove one quantity from the item
    const handleRemove = (id: string) => {
        dispatch(removeItem({ id }));
    };

    // Handler: completely delete the item from cart
    const handleDelete = (id: string) => {
        // This triggers the Flipper exit animation
        setRemovedItems((prev) => [...prev, id]);

        // After animation ends, remove the item from Redux state
        setTimeout(() => {
            dispatch(deleteItem({ id }));
        }, 500);
    };

    // Handler: explicitly set a new quantity
    const handleQuantityChange = (id: string, newQuantity: number) => {
        dispatch(
            updateItemQuantity({
                id,
                quantity: newQuantity,
            })
        );
    };

    // Calculate total price from cart
    const totalAmount = cartItems.reduce((total: number, item: CartItem) => {
        return total + item.price * item.quantity;
    }, 0);

    return (
        <Flipper flipKey={JSON.stringify(cartItems)}>
            <Grid container spacing={4} sx={{ minHeight: '70vh' }}>
                {/* Left side: List of cart items */}
                <Grid size={{ xs: 12, sm: 12, md: 8 }}>
                    <Grid container spacing={2}>
                        {cartItems.map((item: CartItem) => (
                            <Flipped flipId={item.id} key={item.id} onExit={exitAnimation}>
                                <CartItemRow
                                    item={item}
                                    removed={removedItems.includes(item.id)}
                                    onAddMore={handleAddMore}
                                    onRemove={handleRemove}
                                    onDelete={handleDelete}
                                    onQuantityChange={handleQuantityChange}
                                    categories={categories}
                                    platforms={platforms}
                                    regions={regions}
                                />
                            </Flipped>
                        ))}
                    </Grid>
                </Grid>

                {/* Right side: Summary card */}
                <Grid
                    container
                    size={{ xs: 12, sm: 12, md: 4 }}
                    sx={{
                        position: 'sticky',
                        top: 90,
                        height: 'fit-content',
                        width: '100%',
                    }}
                >
                    <Grid size={12} sx={{ width: '100%' }}>
                        <Card sx={{ padding: 3 }}>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                {t('cartSummary')}
                            </Typography>

                            <Stack direction="column" spacing={1} sx={{ mb: 5 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{t('originalPrice')}: </Typography>
                                    <ProductPriceTag
                                        variant="h6"
                                        productTagPrice={totalAmount.toFixed(2)}
                                        color="text.secondary"
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography> {t('discount')}: </Typography>
                                    <ProductPriceTag
                                        variant="h5"
                                        productTagPrice="0"
                                        color="text.secondary"
                                    />
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>{t('total')}: </Typography>
                                    <ProductPriceTag variant="h5" productTagPrice={totalAmount.toFixed(2)} />
                                </Stack>
                            </Stack>

                            <Box marginTop={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={props.onProcessToPayment}
                                >
                                    {t('goToPayment')}
                                </Button>

                                <Divider sx={{ my: 2 }} />
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<ArrowBackRoundedIcon />}
                                    component={RouterLink}
                                    to="/products"
                                >
                                    {t('checkMoreProducts')}
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Flipper>
    );
};

export default CartItems;