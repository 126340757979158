import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { setVerificationAction, setMainLayoutFade } from 'redux/auth/authSlice';

export function usePurchaseSocket() {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const dispatch = useDispatch<AppDispatch>();

  // Local socket & state
  const [purchaseSocket, setPurchaseSocket] = useState<Socket | null>(null);
  const [orderStatus, setOrderStatus] = useState<string>('processing');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // If user logs out (or not logged in), disconnect the socket & reset
    if (!isLoggedIn) {
      if (purchaseSocket) {
        purchaseSocket.disconnect();
        setPurchaseSocket(null);
      }
      // Also clear verification data
      dispatch(
        setVerificationAction({
          verificationSecret: null,
          verificationError: null,
        })
      );
      return;
    }

    // If we already have a socket, do nothing
    if (purchaseSocket) {
      return;
    }

    // === Otherwise, create the /purchases socket once. ===
    const socket = io('/purchases', {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: false,
    });

    socket.on('connect', () => {
      console.log(`✅ [Purchases] Socket connected: ID=${socket.id}`);
      socket.emit('requestOrderStatus');
    });

    socket.on('orderStatusUpdate', (data: {
      paymentId?: string;
      status: string;
      verificationSecret?: string;
      verificationError?: string;
    }) => {
      console.log('[Purchases] orderStatusUpdate:', data);
      setOrderStatus(data.status);

      // If we do get a real verificationSecret or verificationError
      const newSecret = data.verificationSecret ?? null;
      const newError = data.verificationError ?? null;
      if (newSecret || newError) {
        dispatch(setMainLayoutFade());

        // Delay setting them in Redux
        setTimeout(() => {
          dispatch(
            setVerificationAction({
              verificationSecret: newSecret,
              verificationError: newError,
            })
          );
        }, 2000);
      }
    });

    socket.on('orderError', (err: { message: string }) => {
      console.error('[Purchases] orderError:', err);
      setErrorMessage(err.message);
      setOrderStatus('failed');
    });

    socket.on('authError', (data: any) => {
      console.error('[Purchases] authError:', data.message);
      // Optionally dispatch logout, etc.
    });

    socket.on('disconnect', (reason: string) => {
      console.log(`[Purchases] Socket disconnected: ${reason}`);
    });

    socket.on('error', (err: any) => {
      console.error('[Purchases] Socket error:', err);
    });

    socket.connect();
    setPurchaseSocket(socket);

    // Cleanup when effect unmounts or user logs out
    return () => {
      console.log('[Purchases] Cleaning up socket...');
      socket.emit('leaveRoom', 'purchases');
      socket.off('orderStatusUpdate');
      socket.off('orderError');
      setPurchaseSocket(null);
    };

    // ONLY re-run if 'isLoggedIn' changes or 'dispatch' changes
  }, [isLoggedIn, dispatch]);

  return {
    purchaseSocket,
    orderStatus,
    errorMessage,
    setOrderStatus,
    setErrorMessage,
  };
}