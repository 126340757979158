// src/auth/authSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { googleLogin, authMe, verifyEmail, login, register, logout } from './authThunks';
import { updateUserSettings, updatePassword, fetchUserData } from './updateUserThunks';

import { initialState } from './authTypes';
import { FilterValues } from '../types/ProductsTypes';

// Utility function
function isFilterKey(key: any): key is keyof FilterValues {
  return ['category', 'platform', 'type', 'sort', 'genre', 'maxPrice'].includes(key);
}

export const setSearchQuery = (query: string) => ({
  type: 'SET_SEARCH_QUERY',
  payload: query,
});
interface VerificationPayload {
  verificationSecret: string | null;
  verificationError: string | null;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.isGoogleLoading = false;
      state.hasMissingInfo = false;
      state.isEmailVerified = false;
      state.identityRequireCheck = false;
      state.welcomePageLoading = false;
      state.error = null;
      state.verificationSecret = null;
      state.verificationError = null;
      state.verificationStatus = 'processing_verification';
      state.mainLayoutFade = false;
      state.activePurchase = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    setMainLayoutFade: (state) => {
      state.mainLayoutFade = true;
    },
    setNewVerificationAction: (state, action: PayloadAction<VerificationPayload>) => {
      const { verificationSecret, verificationError } = action.payload;
      state.verificationSecret = verificationSecret;
      state.verificationError = verificationError;
    },
    setVerificationStatus: (state, action: PayloadAction<string>) => {
      state.verificationStatus = action.payload;
    },
    setVerificationAction: (state, action: PayloadAction<VerificationPayload>) => {
      const { verificationSecret, verificationError } = action.payload;
      state.verificationSecret = verificationSecret;
      state.verificationError = verificationError;
      // Additional flags
      state.identityRequireCheck = true;
      state.activePurchase = true;
      state.mainLayoutFade = false;
    },
    setWelcomePageLoading: (state, action: PayloadAction<boolean>) => {
      state.welcomePageLoading = action.payload;
    },
    setUpdateIdentityRequireCheck: (state, action: PayloadAction<boolean>) => {
      state.identityRequireCheck = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setFilter: (state, action: PayloadAction<{ key: keyof FilterValues; value: string }>) => {
      const { key, value } = action.payload;
      if (isFilterKey(key)) {
        state.filter[key] = value;
      }
    },
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload;
    },
    setSelectedCurrency(state, action: PayloadAction<string>) {
      state.selectedCurrency = action.payload;
    },
    setSelectedCurrencySymbol(state, action: PayloadAction<string>) {
      state.selectedCurrencySymbol = action.payload;
    },
    setCurrencyAndRegion(
      state,
      action: PayloadAction<{ currency: string; region: string }>
    ) {
      state.selectedCurrency = action.payload.currency;
      state.selectedRegion = action.payload.region;
      state.selectedCurrencySymbol =
        action.payload.currency === 'EUR' ? '€' :
          action.payload.currency === 'USD' ? '$' :
            action.payload.currency === 'GBP' ? '£' :
              action.payload.currency === 'CHF' ? 'CHF' :
                '€';
                
      const germanSpeaking = ['DE', 'AT', 'CH', 'LI', 'LU'];
      const frenchSpeaking = [ 'FR', 'BE', 'CA'];
      const italianSpeaking = ['IT', 'SM', 'VA'];

      if (germanSpeaking.includes(action.payload.region)) {
        state.language = 'de';
      } else if (frenchSpeaking.includes(action.payload.region)) {
        state.language = 'fr';
      } else if (italianSpeaking.includes(action.payload.region)) {
        state.language = 'it';
      } else {
        state.language = 'en';
      }
    },
    setUserIp(state, action: PayloadAction<string>) {
      state.userIp = action.payload;
    },
    resetFilters: (state) => {
      state.filter = {
        platform: '',
        maxPrice: '',
        type: '',
        category: '',
        genre: '',
        sort: 'A-Z',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // -- login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = user;
        state.error = null;
        state.isEmailVerified = action.payload.emailVerified;
        state.identityRequireCheck = action.payload.identityRequireCheck;
        state.hasMissingInfo = !user.firstName || user.firstName === 'Unknown' ||
          !user.lastName || user.lastName === 'Unknown' ||
          !user.birthday || !user.address ||
          !user.city || !user.zip || !user.country;

        state.welcomePageLoading = !state.isEmailVerified || state.hasMissingInfo || state.identityRequireCheck;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // -- googleLogin
      .addCase(googleLogin.pending, (state) => {
        state.isLoading = true;
        state.isGoogleLoading = false;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isGoogleLoading = true;
        state.isEmailVerified = true;
        state.error = null;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isGoogleLoading = false;
        state.error = action.payload as string;
      })

      // -- authMe
      .addCase(authMe.pending, (state) => {
        state.isGoogleLoading = true;
        state.error = null;
      })
      .addCase(authMe.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isGoogleLoading = false;
        state.isEmailVerified = true;
        state.user = user;
        state.identityRequireCheck = action.payload.identityRequireCheck;
        state.hasMissingInfo = !user.firstName || user.firstName === 'Unknown' ||
          !user.lastName || user.lastName === 'Unknown' ||
          !user.birthday || !user.address ||
          !user.city || !user.zip || !user.country;

        state.welcomePageLoading = !state.isEmailVerified || state.hasMissingInfo || state.identityRequireCheck;
        state.error = null;
      })

      // -- verifyEmail
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.isLoading = false;
        state.isEmailVerified = true;
        state.error = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // -- logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
        state.isLoggedIn = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // -- register
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateUserSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = { ...state.user, ...action.payload };
        state.hasMissingInfo = false;
        state.error = null;
      })
      .addCase(updateUserSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });

  },
});

export const {
  clearAuthState,
  clearError,
  setWelcomePageLoading,
  setUpdateIdentityRequireCheck,
  setMainLayoutFade,
  setVerificationAction,
  setNewVerificationAction,
  setVerificationStatus,
  setOnlineStatus,
  setCurrencyAndRegion,
  setUserIp,
  setLanguage,
  setFilter,
  resetFilters,
} = authSlice.actions;

export default authSlice.reducer;